<template>
  <v-container>

    <!-- view table -->
    <v-card>
      <v-toolbar>
          <v-tabs
            v-model="tab"
            align-with-title
          >
            <v-tab
              v-for="item in tabs"
              :key="item.value"
              :disabled="loading"
            >
              {{ item.label }}
            </v-tab>
          </v-tabs>
      </v-toolbar>
  
    <v-data-table
      :headers="tableHeaders"
      :items="requests"
      :page="page"
      :loading="loading"
      @update:page="pageUpdateFunction"
      @update:items-per-page="itemsPerPageUpdateFunction"
      :server-items-length="requestCount"
      :items-per-page.sync="itemsPerPage"
      class="elevation-2 grey lighten-5 ma-3"
    >
    <template v-slot:top>
          <v-row class="d-flex align-center">
              <v-col :cols="10">
                  <v-text-field v-model="search" placeholder="Search" class="ml-4"></v-text-field>
              </v-col>
              <v-col :cols="2">
                  <v-btn class="mx-0" @click="searchUpdateFunction(1)" color="primary">Search</v-btn>
              </v-col>
          </v-row>
        </template>

      <template v-slot:item.username="{ item }">
        <router-link 
            style="text-decoration:none;font-size:16px;color:#2E86C1"
            :to="'/en/admin/user/' + item.user._id"
        >
           <v-icon color="red" class="mx-3" size="15" v-if="(item.user) ? item.user.suspicious : false">fas fa-exclamation-triangle</v-icon> {{(item.user) ? item.user.fullName : ''}}
        </router-link>
      </template>

      <template v-slot:item.userHash="{ item }">
        <div>{{item.user.addressHash}}</div>
      </template>

       <template v-slot:item.company="{ item }">
        <div>{{(item.shipMethod.company? item.shipMethod.company.name : '')}}</div>
      </template>

      <template v-slot:item.trackNumber="{ item }">
        <div>{{item.trackNumber}}</div>
      </template>

      <template v-slot:item.date="{ item }">
        <div>{{ formatDate(item.createdAt)}}</div>
      </template>

      <template v-slot:item.actions="{ item }">
        <div>
          <v-btn class="ma-2" @click="showPackage(item)" small tile outlined color="primary">
            <v-icon size="15" left>fas fa-eye</v-icon>show
          </v-btn>

          <v-btn
            v-if="user.groupId <= adminId"
            class="ma-2"
            @click="selectShipPackage(item)"
            small
            tile
            outlined
            color="success"
          >
            <v-icon size="15" left>fas fa-shipping-fast</v-icon>ship
          </v-btn>
        </div>
      </template>

      <template v-slot:footer>
            <v-row justify="center">
                <v-col cols="8">
                    <v-container class="max-width">
                        <v-pagination
                        v-model="page"
                        class="my-4"
                        :total-visible="7"
                        :length="pageCount"
                        @input="pageUpdateFunction"
                        ></v-pagination>
                    </v-container>
                </v-col>
            </v-row>
        </template>

    </v-data-table>
    </v-card>

<!-- ship modal -->

    <v-dialog v-model="shipDialog" max-width="600">

        <v-card>
            <v-card-title>Shipping Information</v-card-title>

            <v-card-text>

        <v-text-field
            v-model="trackLink"
            name="Tracking Link"
            label="Tracking Link"
            id="Tracking Link"
            type="text"
            color="indigo"
            required
        ></v-text-field>

        <v-text-field
            v-model="shipTrackNumber"
            name="TrackNumber"
            label="Track Number"
            id="TrackingNumber"
            type="text"
            color="indigo"
            required
        ></v-text-field>

        <v-text-field
            v-model="shipWeight"
            name="shipWeight"
            label="Weight"
            id="weight"
            type="text"
            color="indigo"
            required
        ></v-text-field>

        <v-text-field
            v-model="shipHeight"
            name="height"
            label="Height"
            id="height"
            type="text"
            color="indigo"
            required
        ></v-text-field>

        <v-text-field
            v-model="shipWidth"
            name="width"
            label="Width"
            id="width"
            type="text"
            color="indigo"
            required
        ></v-text-field>

        <v-text-field
            v-model="shipLength"
            name="length"
            label="Length"
            id="length"
            type="text"
            color="indigo"
            required
        ></v-text-field>

        <div v-if="shipLoading" class="d-flex justify-center">
              <v-progress-circular
            :indeterminate="true"
            size="40"
            color="indigo"
          ></v-progress-circular>
            </div>

        <v-btn v-else color="indigo" @click="shipPackage" class="white--text"> <v-icon color="white" left size="20">fas fa-shipping-fast</v-icon> Ship</v-btn>
            </v-card-text>
        </v-card>
    </v-dialog>


    
    <!-- package info modal -->

    <v-dialog v-model="dialog" max-width="600">
      <v-card class="pa-3">
        <v-card-title>Information</v-card-title>
        <v-card-text>
          <div class="ma-1" style="font-size:16px">
            <b>Package Id:</b> <span>{{selectedRequest.packageId}}</span>
          </div>
          <div class="ma-1" style="font-size:16px">
            <b>Track Number:</b> <span>{{selectedRequest.trackNumber}}</span>
          </div>
          <div class="ma-1" style="font-size:16px">
            <b>Weight:</b> <span>{{selectedRequest.weight}} kg</span>
          </div>
          <div class="ma-1" style="font-size:16px">
            <b>dimensions:</b> <span>{{selectedRequest.length}} * {{selectedRequest.width}} * {{selectedRequest.height}} cm</span>
          </div>
          <div class="ma-1" style="font-size:16px">
            <b>Price:</b> <span class="success--text">{{(selectedRequest.shipMethod)? selectedRequest.shipMethod.price : ''}} &euro;</span>
          </div>
          <div class="ma-1" style="font-size:16px">
              <b>Fees:</b> <span>{{selectedRequest.fees ? `${selectedRequest.fees} $` : 'not mentioned'}}</span>
          </div>

          <template v-for="detail in selectedRequest.details" >
            <v-card>
                <v-card-text>
                    <div class="ma-1" style="font-size:16px">
                        <b>Description:</b> <span>{{detail.description}}</span>
                    </div>

                    <div class="ma-1" v-if="detail.code_sh" style="font-size:16px">
                        <b>HS Code:</b> <span>{{detail.code_sh}}</span>
                    </div>

                    <div class="ma-1" style="font-size:16px">
                        <b>Made In:</b> <span>{{detail.madeIn}}</span>
                    </div>

                    <div class="ma-1" style="font-size:16px">
                        <b>Type:</b> <span>{{detail.type}}</span>
                    </div>

                    <div class="ma-1" style="font-size:16px">
                        <b>Quantity:</b> <span>x{{detail.quantity}}</span>
                    </div>

                    <div class="ma-1" style="font-size:16px">
                        <b>price:</b> <span>{{detail.price}}</span>
                    </div>
                </v-card-text>
            </v-card>
          </template>

          <div class="ma-2" style="font-size:20px;font-weight:bold">Images</div>

          <v-row>
            <v-col v-for="img in selectedRequest.images" v-viewer :key="img" sm="4">
              <img height="150" class="ma-2 elevation-3" :src="backendUrl + '/' + img"></img>
            </v-col>
          </v-row>

          <div class="ma-2" style="font-size:20px;font-weight:bold">Shipping Address</div>

          <div v-if="selectedRequest.shippingAddress">

              <div class="ma-1" style="font-size:16px">
            <b>Name:</b> <span>{{selectedRequest.shippingAddress.name}}</span>
          </div>
           <div class="ma-1" style="font-size:16px">
              <b>National ID:</b> <span>{{selectedRequest.shippingAddress.nationalID}}</span>
            </div>
          <div class="ma-1" style="font-size:16px">
            <b>Country:</b> <span>{{selectedRequest.shippingAddress.country}}</span>
          </div>
          <div class="ma-1" style="font-size:16px">
            <b>City:</b> <span>{{selectedRequest.shippingAddress.city}}</span>
          </div>
          <div class="ma-1" style="font-size:16px">
            <b>Address Line One:</b> <span>{{selectedRequest.shippingAddress.addressLineOne}} </span>
          </div>
          <div class="ma-1" style="font-size:16px">
            <b>Address Line Two:</b> <span>{{selectedRequest.shippingAddress.addressLineTwo}}</span>
          </div>

          <div class="ma-1" style="font-size:16px">
            <b>Phone:</b> <span>{{selectedRequest.shippingAddress.phone}}</span>
          </div>

         <div class="ma-1" style="font-size:16px">
            <b>Postal:</b> <span>{{selectedRequest.shippingAddress.zip}}</span>
          </div>

    
          </div>

          <br>
          
          <div class="ma-2" style="font-size:20px;font-weight:bold">Shipping Options</div>

          <div v-if="selectedRequest.shippingOptions">

            <div class="ma-1" style="font-size:16px">
              <b>Does it contain lithium batteries?:</b> <span :style="selectedRequest.shippingOptions.hasLithium ? '' : 'color:red'">{{selectedRequest.shippingOptions.hasLithium ? 'Yes' : 'No'}}</span>
            </div>
            <div class="ma-1" style="font-size:16px">
              <b>Does it contain dangerous substances (such as perfumes)?:</b> <span :style="selectedRequest.shippingOptions.hasDangerous ? '' : 'color:red'">{{selectedRequest.shippingOptions.hasDangerous ? 'Yes' : 'No'}}</span>
            </div>
            <div class="ma-1" style="font-size:16px">
              <b>Remove bills:</b> <span :style="selectedRequest.shippingOptions.removeBills ? '' : 'color:red'">{{selectedRequest.shippingOptions.removeBills ? 'Yes' : 'No'}}</span>
            </div>
            <div class="ma-1" style="font-size:16px">
              <b>Remove shoe boxes:</b> <span :style="selectedRequest.shippingOptions.removeShoeBoxes ? '' : 'color:red'">{{selectedRequest.shippingOptions.removeShoeBoxes ? 'Yes' : 'No'}}</span>
            </div>
            <div class="ma-1" style="font-size:16px">
              <b>Additional notes:</b> <br> 
              <span>{{selectedRequest.shippingOptions.additionalNote}}</span>
            </div>

          </div>

          <div class="mt-5" style="font-size:20px;font-weight:bold">Shipping Company</div>

          <div v-if="selectedRequest.shipMethod">

              <div class="ma-1" style="font-size:16px">
            <b>Name:</b> <span>{{selectedRequest.shipMethod.company.name}}</span>
          </div>
    
          </div>
        </v-card-text>

        <v-card-actions class="d-flex justify-end">
          <v-btn
            v-if="user.groupId <= adminId"
            class="ma-2"
            @click="selectShipPackage(selectedRequest)"
            color="success"
          >
            ship
          </v-btn>
          <v-btn @click="dialog=!dialog" class="ma-2" color="grey white--text">cancel</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

    <v-snackbar left :color="color" v-model="snackbar">{{ message }}</v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import auth from "../helpers/auth";
import { backendUrl } from "../config/config";

export default {
  name: "ShippingRequest",
  mounted() {
    this.getRequests();

     axios.get('/api/users/shipping_companies').then(res=>{
        this.shippingCompanies = res.data.companies.map(item=>{
            return {
                text:item.name,
                value:item._id
            }
        })
    })
  },

  watch: {
    tab(val){
      this.page = 1;
      this.search = ''
      this.getRequests();
    },
    search(val){
      if(!val)
        this.getRequests();
    }
  },

  sockets: {
    loadShippingPackages(shippingPackages) {
      //console.log(data.notifications)
      // Fired when a user pay for the shipping.
      this.requests = shippingPackages
    }
  },

  data() {
    return {
      tab: 1,
      tabs: [
        {label: 'To Be Shipped', value: 1}, {label: 'Black List', value: 2}, 
      ],
      user: auth.admin(),
      adminId: 1,
      supportId: 2,
      tableHeaders: [
        {
          text: "Username",
          value: "username",
          align: "center"
        },
        {
          text: "User Hash",
          value: "userHash",
          align: "center"
        },
          {
          text: "Shipping Company",
          value: "company",
          align: "center"
        },
        {
          text: "Request Date",
          value: "date",
          align: "center"
        },
        {
          text: "Track Number",
          value: "trackNumber",
          align: "center"
        },
        {
          text: "Actions",
          value: "actions",
          align: "center"
        }
      ],
      requests: [],
      requestCount: 10,
      dialog: false,
      snackbar: false,
      message: "",
      color: "",
      loading: false,
      page: 1,
      itemsPerPage: 10,
      pageCount: 1,
      search: "",
      selectedRequest: {},
      backendUrl,
      shipDialog:false,
      shipCompany:'',
      trackLink:'',
      shipLoading:false,
      shipTrackNumber:'',
      shipHeight:'',
      shipWidth:'',
      shipLength:'',
      shipWeight:'',
      shippingCompanies:[],
      companyTooltip:false
    };
  },
  methods: {
    getRequests(){
      if(this.loading)
        return;
      
      this.loading = true;
      console.log(this.tab)
      axios.get(`/api/packages/shipping/get?suspicious=${this.tab == 1}&page=${this.page-1}&perPage=${this.itemsPerPage}`).then(res => {
      if(!res.data.error){
        this.requests = res.data.packages;
        this.pageCount = res.data.pageCount;
        this.requestCount = res.data.requestCount;
      }
    this.loading = false;
    });
    },
    pageUpdateFunction(newPageNumber) {
      if(this.loading)
          return;

      if (this.search == "") {
        this.getRequests()
      }
      else {
        this.searchUpdateFunction(this.page)
      }
    },

    itemsPerPageUpdateFunction(length) {
        if(this.loading)
          return;
        
        this.page= 1
        this.itemsPerPage = length

        this.getRequests()

    },

    searchUpdateFunction(page){
      if(this.loading)
          return;

        this.loading = true;
        const apiURL = `/api/packages/shipping/search?query=${this.search}&suspicious=${this.tab == 1}&page=${this.page-1}&perPage=${this.itemsPerPage}`

        if (this.search) {
            axios.get(apiURL).then(res => {
                this.requestCount = res.data.requestCount
                this.pageCount = res.data.pageCount
                this.requests = res.data.packages
                this.loading = false;
            })
        }
    },

    customFilter(value, queryText, item) {
      const addressHash = item.user.addressHash.toLowerCase();
      const username = item.user.fullName.toLowerCase();
      const trackNumber = item.trackNumber.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        addressHash.indexOf(searchText) > -1 ||
        username.indexOf(searchText) > -1 ||
        trackNumber.indexOf(searchText) > -1
      );
    },

    formatDate(date) {
        var d = new Date(date) ,
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            hour = d.getHours(),
            mins = d.getMinutes();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
        if(mins < 10)
           mins = '0' + mins;
        if(hour < 10)
           hour = '0' + hour;

        return [year, month, day].join('-') + ` ${hour}:${mins}`;
 },
    showPackage(pack) {
      this.dialog = true;
      this.selectedRequest = pack;
    },
    handleMarkAsComplete(id) {
      axios
        .post("/api/packages/shipping/complete", { requestId: id })
        .then(res => {
          if (!res.data.error) {
            this.message = "Updated Successfully";
            this.color = "success";
            this.snackbar = true;

            this.requests = this.requests.filter(item => {
              return item._id != id;
            });
          }
        })
        .catch(err => {
          console.log(err);
          this.loading = false;
          this.message = "An error occurred please try again later";
          this.color = "error";
          this.snackbar = true;
        });
    },
    selectShipPackage(item){
      this.selectedRequest = item;
      this.shipTrackNumber = item.trackNumber
      this.shipHeight = item.height
      this.shipWidth = item.width
      this.shipWeight = item.weight
      this.shipLength = item.length
      this.shipDialog = !this.shipDialog;
    },
    shipPackage(){
      
      this.shipLoading = true;
      axios.post('/api/packages/shipping/ship',{
        packageId:this.selectedRequest._id,
        trackLink:this.trackLink,
        height:this.shipHeight,
        width:this.shipWidth,
        length:this.shipLength,
        trackNumber:this.shipTrackNumber,
        weight:this.shipWeight
        }).then(res=>{
          if(!res.data.error){

            this.message = res.data.message;
            this.color = 'success';
            this.snackbar = true;

            this.requests = this.requests.filter((item)=>item._id != this.selectedRequest._id);

            //empty fields
            this.trackLink = '';
            this.shipCompany = '';
            this.shipDialog = false;
            this.shipTrackNumber = '';
            this.shipHeight = '';
            this.shipWeight = '';
            this.shipWidth = '';
            this.shipLength = '';

          }else{
            this.message ='An error happened please try again';
            this.color = 'error';
            this.snackbar = true;

          }

          this.shipLoading = false;
        }).catch(err=>{
          console.log(err);

          this.message ='An error happened please try again';
            this.color = 'error';
            this.snackbar = true;
            this.shipLoading = false;
        })
    }
  }
};
</script>