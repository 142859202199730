<template>
    <v-container>

        <h3>Alerts</h3>
        <v-data-table
            :headers="tableHeaders"
            :items="alerts"
            class="elevation-2 grey lighten-5 ma-3"
            :page.sync="page"
            :server-items-length="alertCount"
            @update:page="pageUpdateFunction"
            @update:items-per-page="itemsPerPageUpdateFunction"
            :items-per-page.sync="itemsPerPage"
        
        >
            <template v-slot:top class="align-center">
                <v-row class="d-flex align-center">
                    <v-col :cols="10">
                        <v-text-field v-model="query" placeholder="Search" class="mx-4"></v-text-field>
                    </v-col>
                    <v-col :cols="2">
                        <v-btn class="mx-4" @click="searchUpdateFunction" color="primary">Search</v-btn>
                    </v-col>
                </v-row>
            </template>

            <template v-slot:item.cleared="{ item }">
                <div>{{item.cleared ? 'Yes' : 'No'}}</div>
            </template>

            <template v-slot:item.createdAt="{ item }">
                <div>{{moment(item.createdAt).calendar()}}</div>
            </template>

            <template v-slot:item.actions="{ item }">
                <div>
                    <v-btn @click="showAlert(item)" class="ma-2" small tile outlined color="primary">
                        <v-icon size="15" left>fas fa-eye</v-icon> show
                    </v-btn>

                    <v-btn v-if="item.cleared" class="ma-2" @click="deleteAlert(item)"  small tile outlined color="error">
                        <v-icon size="15" left>fas fa-trash</v-icon> delete
                    </v-btn>

                    <v-btn v-if="!item.cleared" class="ma-2" @click="clearAlert(item)"   small tile outlined color="warning">
                        <v-icon size="15" left>fas fa-times-circle</v-icon> cancel
                    </v-btn>

                </div>
            </template>

            

            <template v-slot:footer>
                <v-row justify="center">
                    <v-col cols="8">
                        <v-container class="max-width">
                            <v-pagination
                            v-model="page"
                            class="my-4"
                            :total-visible="7"
                            :length="pageCount"
                            @input="pageUpdateFunction"
                            ></v-pagination>
                        </v-container>
                    </v-col>
                </v-row>
            </template>

        </v-data-table>

        <v-dialog v-model="showDialog" max-width="600">
            <v-card class="pa-3">
                <v-card-title>Information</v-card-title>
                <v-card-text>
                    <div class="ma-1" style="font-size:16px">
                        <b>AlertId:</b> <span>{{selectedAlert.alertId}}</span>
                    </div>
                    <div class="ma-1" style="font-size:16px">
                        <b>Type:</b> <span>{{selectedAlert.type}}</span>
                    </div>
                    <div class="ma-1" style="font-size:16px">
                        <b>Cleared:</b> <span>{{selectedAlert.cleared ? 'Yes' : 'No'}}</span>
                    </div>
                    <br>
                    <div class="ma-1" style="font-size:16px">
                        <b>Alert Text:</b> <br> 
                        <span>{{selectedAlert.text ? selectedAlert.text.en : ''}}</span>
                        <br><br>
                        <span>{{selectedAlert.text ? selectedAlert.text.ar : ''}}</span>
                    </div>
                </v-card-text>
                <v-card-actions class="d-flex justify-end">
                    <v-btn @click="showDialog=!showDialog" class="ma-2" color="grey white--text">cancel</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>

        <v-dialog v-model="clearDialog" max-width="600">

            <v-card>
                <v-card-title class="headline">Please confirm your action</v-card-title>
                <v-spacer></v-spacer>
                <v-card-text>
                <v-alert
                    border="top"
                    colored-border
                    type="warning"
                    elevation="2"
                >
                    Are you sure that you want to clear this alert ?
                </v-alert>
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn depressed @click="handleClearAlert(selectedAlert._id)" color="error">Clear Alert</v-btn>
                <v-btn @click="cancelDialog=!cancelDialog" class="ma-2" color="grey white--text">close</v-btn>
                </v-card-actions>
            </v-card>


        </v-dialog>

        <v-dialog v-model="deleteDialog" max-width="600">

            <v-card>
                <v-card-title class="headline">Please confirm your action</v-card-title>
                <v-spacer></v-spacer>
                <v-card-text>
                <v-alert
                    border="top"
                    colored-border
                    type="error"
                    elevation="2"
                >
                    Are you sure that you want to delete this alert ?
                </v-alert>
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn depressed @click="handleDeleteAlert(selectedAlert._id)" color="error">Delete</v-btn>
                <v-btn @click="deleteDialog=!deleteDialog" class="ma-2" color="grey white--text">close</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>




        <v-snackbar left :color="color" v-model="snackbar">
                {{ message }}
            </v-snackbar>
    </v-container>
</template>

<script>
import axios from 'axios';
import moment from 'moment'
import {backendUrl} from '../config/config';

export default {

    name:'ManageAlerts',
    data(){
        return {
            page: 1,
            pageCount: 5,
            alertCount: 1,
            itemsPerPage: 10,
            tableHeaders:[
                {
                    text:'AlertID',
                    value:'alertId',
                    align:'center'
                },
                {
                    text:'Type',
                    value:'type',
                    align:'center'
                },
                {
                    text:'Date',
                    value:'createdAt',
                    align:'center'
                },
                {
                    text:'Cleared?',
                    value:'cleared',
                    align:'center'
                },
                {
                    text:'Actions',
                    value:'actions',
                    align:'center'
                }
            ],
            alerts:[],
            snackbar:false,
            message:'',
            color:'',
            dialog:false,
            selectedAlert:{},
            selectedAlertGroup: {},
            loading:false,
            showDialog:false,
            clearDialog: false,
            deleteDialog: false,
            imgDialog: false,
            deleteDialog: false,
            displayImg: '',
            backendUrl,
            images:[],
            query: '',
            search:'',
            valid:false
        }
    },

    mounted(){
        const urlParams = new URLSearchParams(window.location.search);
        const alertId = urlParams.get('packageId');
        if(alertId) {
            axios.get('/api/admins/alert/getAlerts?alertId='+packageId).then(res => {
                this.alertCount = res.data.alertCount
                this.pageCount = res.data.pageCount
                this.alerts = res.data.alerts
            })
        } else {

            axios.get('/api/admins/alert/getAlerts').then(res => {
                this.alertCount = res.data.alertCount
                this.pageCount = res.data.pageCount
                this.alerts = res.data.alerts
            })
        }

    },

    methods:{

        moment: function (date) {
            const mom = new Date(date)
            //moment.locale(local(this.$i18n.locale))
            return moment(mom)
        },

        showAlert(alert){
            this.selectedAlert = alert;
            this.showDialog = true;
        },

        clearAlert(alert) {
            this.showDialog = false;
            this.deleteDialog = false; 

            this.selectedAlert = alert;
            this.clearDialog = true; 
        },

        deleteAlert(alert) {
            this.showDialog = false;
            this.clearDialog = false;

            this.selectedAlert = alert;
            this.deleteDialog = true; 
        },

        handleClearAlert(id) {
            axios.post('/api/admins/alert/clear',{alertId:id}).then(res=>{

                if(!res.error){

                    this.message = 'Alert was canceled Successfully';
                    this.color = 'warning';
                    this.snackbar = true;

                    this.alerts.forEach(item=>{
                        if(item._id === id) {
                            item.cleared = true;
                        }
                        
                    })

                    this.clearDialog = false;
                }
            }).catch(err=>{
                console.log(err)
                this.loading = false;
                this.message = 'An error occurred please try again later';
                this.color = 'error';
                this.snackbar = true;
            })
        },

        handleDeleteAlert(id){

            axios.post('/api/admins/alert/delete',{alertId:id}).then(res=>{

                if(!res.error){

                    this.message = 'Deleted Successfully';
                    this.color = 'warning';
                    this.snackbar = true;

                    this.alerts = this.alerts.filter(item=>{
                        
                        return item._id != id
                    })
                    this.deleteDialog = false; 
                }
            }).catch(err=>{
                console.log(err)
                this.loading = false;
                this.message = 'An error occurred please try again later';
                this.color = 'error';
                this.snackbar = true;
            })
        },


      pageUpdateFunction(newPageNumber) {
        axios.get(`/api/packages/get/all?page=${newPageNumber-1}&perPage=${this.itemsPerPage}`).then(res=>{
            this.packageCount = res.data.packageCount
            this.pageCount = res.data.pageCount
            this.packages = res.data.packages

        })

    },
    itemsPerPageUpdateFunction(length) {
        this.page= 1
        this.pageCount = 1
        axios.get(`/api/packages/get/all?page=${this.pageCount -1}&perPage=${length}`).then(res=>{
            //console.log(res.data)
            this.pageCount = res.data.pageCount
            this.packages = res.data.packages
            this.itemsPerPage = length

        })

    },

    searchUpdateFunction(){
        const newQuery = this.query
        if (newQuery) {
            axios.get(`/api/packages/get/all?query=${newQuery}`).then(res => {
               this.packageCount = res.data.packageCount
               this.pageCount = res.data.pageCount
               this.packages = res.data.packages
           })
        }
    },



    },
}
</script>