<template>
    <v-container>
        <v-btn class="mt-5" @click="addZoneDialog=!addZoneDialog" color="success">
            <v-icon size="17" left >fas fa-plus</v-icon>
            Add Zone 
        </v-btn>


        <div class="mt-2">
            <v-card v-for="item in zones" :key="item._id" >
                <v-card-text class="ma-5">
                    <v-row>
                        <v-col class="ma-0 pa-0" cols="4">Name: {{item.name}}</v-col>
                        <v-col class="ma-0 pa-0" cols="4"> No. of countries: {{item.countries.length}}</v-col>
                        <v-col class="ma-0 pa-0" cols="4"><v-btn @click="onEdit(item)" color="success">update</v-btn></v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </div>


        <v-dialog max-width="750" v-model="addZoneDialog">
            <v-card>
                <v-card-title>Add Zone</v-card-title>

                <v-card-text>
                    
                    <v-form ref="form" @submit.prevent="handleAdd" >
                        <v-text-field
                        label="Name"
                        v-model="name"
                        ></v-text-field>


                        <v-row>
                            <v-col v-for="item in countries" :key="item.name" cols="6" class="pb-0" sm="3">
                                <v-checkbox v-model="item.checked" :label="item.name"></v-checkbox>
                            </v-col>

                        </v-row>


                        <div class="d-flex justify-center">
                            
                             <v-progress-circular
                                v-if="loading"
                                :indeterminate="true"
                                size="40"
                                color="indigo"
                            ></v-progress-circular>

                            <v-btn type="submit" v-else color="success">
                                Add
                            </v-btn>
                        </div>
                        
                    </v-form>

                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="editDailog" max-width="750">
            <v-card>
                <v-card-title>{{this.selectedItem.name}}</v-card-title>

                 <v-card-text>
                     <v-row>
                        <v-col v-for="item in editCountries" :key="item.name" cols="6" class="pb-0" sm="3">
                            <v-checkbox v-model="item.checked" :label="item.name"></v-checkbox>
                        </v-col>

                    </v-row>

                    <div class="d-flex justify-center">
                            
                             <v-progress-circular
                                v-if="loading"
                                :indeterminate="true"
                                size="40"
                                color="indigo"
                            ></v-progress-circular>

                            <v-btn @click.stop="handleEdit" v-else color="primary">
                                Edit
                            </v-btn>
                        </div>

                 </v-card-text>

            </v-card>
        </v-dialog>


        <v-snackbar left :color="color" v-model="snackbar">
              {{ message }}
        </v-snackbar>

    </v-container>
</template>

<script>

import {countryList} from '../config/config';
import axios from 'axios';
import auth from '../helpers/auth';

export default {
    name:'Zones',
    mounted(){

        if( this.admin.groupId > this.adminId ) {
            this.$router.push('support/');
        }

        this.countries = countryList.map(item=>{

            let obj = {};

            obj.name = item;
            obj.checked = false;

            return obj;
        })

        axios.get('/api/zones/').then(res=>{
            this.zones= res.data.zones;
        })
    },
    data(){
        return {
            admin: auth.admin(),
            adminId: 1,
            supportId: 2,
            countries:[],
            addZoneDialog:false,
            name:'',
            loading:false,
            snackbar:false,
            color:'',
            message:'',
            selectedItem:{},
            editDailog:false,
            zones:[],
            editCountries:[]
        }
    },
    methods:{
        onEdit(item){
            this.selectedItem = item;
            this.editDailog = true;

            this.editCountries = countryList.map(item=>{

                let obj = {};

                obj.name = item;

                if(this.selectedItem.countries.indexOf(item) >= 0){
                    obj.checked = true;
                }else{
                    obj.checked = false;
                }

                return obj;
            })
        },
        handleAdd(){

        let countries = this.countries.filter(item=>item.checked).map(item=>item.name);

        if(!this.name){
            this.snackbar = true;
            this.color = 'error';
            this.message = 'Please fill all fields';

            return
        }

        this.loading = true;

        axios.post('/api/zones/add',{
            name:this.name,
            countries
        }).then(res=>{
            if(!res.error){
                this.snackbar = true;
                this.color = 'success';
                this.message = 'Added Successfully';

                this.loading = false;

                this.zones.push(res.data.zone);

                this.addZoneDialog = false;
            }

            this.$refs.form.reset();
        }).catch(err=>{
            this.loading = false;
            this.snackbar = true;
            this.color = 'error';
            this.message= 'An error happened please try again'
        })
    },

    handleEdit(){

        let countries = this.editCountries.filter(item=>item.checked).map(item=>item.name);

        this.loading = true;

        axios.post('/api/zones/edit/'+this.selectedItem._id,{
            countries
        }).then(res=>{

            if(!res.error){
                this.snackbar = true;
                this.color = 'success';
                this.message = 'Edited Successfully';

                this.loading = false;

                this.editDailog = false;

                this.selectedItem.countries = res.data.zone.countries;
            }


        }).catch(err=>{
            this.loading = false;
            this.snackbar = true;
            this.color = 'error';
            this.message= 'An error happened please try again'
        })
    }
    }
}
</script>