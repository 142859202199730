<template>
    <v-container>

        <v-data-table
            :headers="tableHeaders"
            :items="users"
            class="elevation-2 grey lighten-5 ma-3"
            :page.sync="page"
            :server-items-length="userCount"
            @update:page="pageUpdateFunction"
            @update:items-per-page="itemsPerPageUpdateFunction"
            :items-per-page.sync="itemsPerPage"
        >

            <template v-slot:top>
                <v-row class="d-flex align-center">
                    <v-col :cols="10">
                        <v-text-field v-model="search" placeholder="Search" class="mx-4"></v-text-field>
                    </v-col>
                    <v-col :cols="2">
                        <v-btn class="mx-4" @click="searchUpdateFunction" color="primary">Search</v-btn>
                    </v-col>
                </v-row>
            </template>

            <template v-slot:item.fullName="{ item }">
                <router-link 
                style="text-decoration:none;font-size:16px;color:#2E86C1"
                :to="'/en/admin/user/' + item.id"
                ><v-icon color="red" class="mx-3" size="15" v-if="item.suspicious">fas fa-exclamation-triangle</v-icon>{{item.fullName}}</router-link>
            </template>
            <template v-slot:item.isConfirmed="{ item }">
                <i-icon
                    size="50px"
                    :style="item.isConfirmed ? 'font-size: 20px;color: hsl(120, 73%, 40%);' : 'font-size: 20px;color: gray'"
                    :class="item.isConfirmed ? 'fa-regular fa-circle-check' : 'fa-regular fa-circle-xmark'">
                </i-icon>
            </template>

            <template v-slot:item.createdAt="{ item }">
                {{formatDate(item.createdAt)}}
            </template>

            <template v-slot:footer>
                <v-row justify="center">
                    <v-col cols="8">
                        <v-container class="max-width">
                            <v-pagination
                            v-model="page"
                            class="my-4"
                            :total-visible="7"
                            :length="pageCount"
                            @input="pageUpdateFunction"
                            ></v-pagination>
                        </v-container>
                    </v-col>
                </v-row>
            </template>

        </v-data-table>


    </v-container>
</template>

<script>

import axios from 'axios';

export default {
    name:'ManageUsers',
    data(){
        return {
            page: 1,
            pageCount: 5,
            userCount: 1,
            itemsPerPage: 10,
            tableHeaders:[
                {
                    text:'Name',
                    value:'fullName'
                },
                {
                    text:'Email',
                    value:'email'
                },
                {
                    text:'Phone',
                    value:'phone'
                },
                {
                    text:'Hash Address',
                    value:'addressHash'
                },
                {
                    text:'Confirmed',
                    value:'isConfirmed'
                },
                {
                    text:'Registration Date',
                    value:'createdAt'
                }
            ],
            users:[],
            search:''
        }
    },
    mounted(){
      axios.get('/api/users/').then((res)=>{
          this.userCount = res.data.userCount
          this.pageCount = res.data.pageCount
          this.users = res.data.users;
      })
    },

    watch: {
        search: function (newSearch) {
            if (newSearch === '') {
                axios.get('/api/users/').then(res => {
                    this.page = 1
                    this.userCount = res.data.userCount
                    this.pageCount = res.data.pageCount
                    this.users = res.data.users;
                })
            }
        }
    },

    methods: {
        formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
        },
        pageUpdateFunction(newPageNumber) {
            axios.get(`/api/users?page=${newPageNumber-1}&perPage=${this.itemsPerPage}`).then(res=>{
                this.page = newPageNumber
                this.userCount = res.data.userCount
                this.pageCount = res.data.pageCount
                this.users = res.data.users

            })

        },
        itemsPerPageUpdateFunction(length) {
            this.page= 1
            this.pageCount = 1
            axios.get(`/api/users?page=${this.pageCount -1}&perPage=${length}`).then(res=>{
                this.userCount = res.data.userCount
                this.pageCount = res.data.pageCount
                this.users = res.data.users

            })

        },

        searchUpdateFunction(){
            const newQuery = this.search
            if (newQuery) {
                axios.get(`/api/users?query=${newQuery}`).then(res => {
                    this.userCount = res.data.userCount
                    this.pageCount = res.data.pageCount
                    this.users = res.data.users
                })
            }
        },
    }
}
</script>

<style scoped>

</style>