<template>
  <v-app>
    <v-content class="white">
      <div v-if="testing" class="warning white--text text-center pa-3">
        Testing Website
      </div>
      <router-view />
    </v-content>
  </v-app>
</template>

<script>
import axios from "axios";
import { backendUrl } from "./config/config";

axios.defaults.baseURL = backendUrl;

export default {
  name: "App",
  metaInfo: {
    title: "Porsay",
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      {
        name: "description",
        content:
          "أحصل على عنوان فرنسي مجاناً، وتسوق بمفردك من المتاجر الأوروبية والفرنسية. نشحنها إلى باب بيتك بأي مكان في العالم.",
      },
      {
        name: "keywords",
        content:
          "عنوان فرنسي للشحن, عنوان اوروبي للشحن, تجميع الشحنات فرنسا, عنوان فرنسي, porsay, بورساي, الحصول على عنوان فرنسي, france forwarding service, french address for online shopping",
      },
    ],
  },

  data: () => ({
    testing: backendUrl == "https://backendtest.porsay.com",
  }),
};
</script>


<style>
* {
  font-family: Helvetica, sans-serif;
  text-transform: none !important;
}

.logo {
  font-size: 25px;
}
.v-btn {
  letter-spacing: 0 !important;
}

.v-text-field--outlined > .v-input__control > .v-input__slot {
  background: white !important;
}

#app {
  overflow-x: hidden;
}

.fa-th-large {
  font-size: 15px !important;
}

.fa-flag {
  font-size: 15px !important;
}

@font-face {
  font-family: Helvetica;
  src: url("./assets/fonts/Helvetica.ttf");
}

@font-face {
  font-family: Frut;
  src: url("./assets/fonts/frut.ttf");
}

@font-face {
  font-family: FrutBold;
  src: url("./assets/fonts/frut-bold.ttf");
}

@font-face {
      font-family: NotoColorEmoji;
      src: url("./assets/fonts/NotoColorEmoji.woff2") format('woff2'),
           url("./assets/fonts/NotoColorEmoji.woff") format('woff'),
           url("./assets/fonts/NotoColorEmoji.ttf") format('truetype');
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: FrutBold;
}
</style>
