<template>
  <div>
    <Navbar v-if="!isAuthenticated" />
    <DashboardNavbar v-if="isAuthenticated" />

    <v-container>
      <v-row :style="searched ? '' : 'justify-content: center'">
        <v-col
          :style="searched ? '' : 'margin: 20px'"
          cols="12"
          sm="6"
          md="5"
          lg="6"
        >
          <v-card style="padding: 10px">
            <!-- <v-card-body> -->
              <v-form class="ma-3" @submit.prevent="handleSubmit" ref="form">
                
                    <v-text-field
                      v-model="height"
                      name="height"
                      :label="$t('height') + ` ${$t('unit')}`"
                      id="height"
                      type="text"
                      inputmode="numeric"
                      pattern="[0-9]+"
                      :rules="[(v) => numRegex.test(v) || '',
                      v => (!v || v > 0) || $t('outOfRange')]"
                      min="0"
                      color="indigo"
                      required
                    ></v-text-field>
                  
                    <v-text-field
                      v-model="width"
                      name="width"
                      :label="$t('width') + ` ${$t('unit')}`"
                      id="width"
                      type="text"
                      inputmode="numeric"
                      pattern="[0-9]+"
                      :rules="[(v) => numRegex.test(v) || '',
                      v => (!v || v > 0) || $t('outOfRange')]"
                      min="0"
                      color="indigo"
                      required
                    ></v-text-field>
                  
                    <v-text-field
                      v-model="length"
                      name="length"
                      :label="$t('length') + ` ${$t('unit')}`"
                      id="length"
                      type="text"
                      inputmode="numeric"
                      pattern="[0-9]+"
                      :rules="[(v) => numRegex.test(v) || '',
                      v => (!v || v > 0) || $t('outOfRange')]"
                      min="0"
                      color="indigo"
                      required
                    ></v-text-field>
                  

                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model="volumetricWeight"
                      name="volumetricWeight"
                      :label="$t('volumetricWeight')"
                      id="volumetricWeight"
                      type="text"
                      min="0"
                      color="indigo"
                      required
                      disabled
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      v-model="weight"
                      name="weight"
                      :label="$t('weight')"
                      id="weight"
                      type="text"
                      inputmode="decimal"
                      pattern="[0-9]*"
                      :rules="[(v) => !!v || $t('required'),
                      (v) => numRegex.test(v) || '',
                      v => v > 0 || $t('outOfRange')]"
                      :error-messages="errorMessages"
                      @keydown="clearErrors"
                      min="0"
                      color="indigo"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-select
                  v-model="warehouse"
                  :items="warehouses"
                  :label="$t('warehouse')"
                  item-text="country"
                item-value="_id"
                color="indigo"
                :rules="[(v) => !!v || $i18n.t('codeRequired')]"
                required
                >
                </v-select>

                <v-menu
                  offset-y
                  transition="scale-transition"
                  nudge-top="18"
                  max-height="240"
                  :nudge-width="2"
                  :close-on-content-click="true"
                  v-model="countryMenu"
                >
                  <template v-slot:activator="{ attrs, on }">
                    <v-text-field
                      :label="$t('destinationCountry')"
                      v-on="on"
                      v-model="country"
                      autocomplete="off"
                      :error-messages="countryErrors"
                      @keyup="filterCountries"
                      @keypress="countryErrors = []"
                      ref="countryList"
                    >
                    </v-text-field>
                  </template>

                  <v-list>
                    <v-list-item
                      @click="setCountry(item)"
                      v-for="item in countryList"
                      v-bind:key="item.name"
                      class="white pa-2"
                    >
                      <v-list-item-content style="direction:ltr;font-family: 'NotoColorEmoji'">{{
                        item.icon + " " + item.name
                      }}</v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-btn
                  v-if="!loading"
                  type="button"
                  @click="calc"
                  class="white--text text-right"
                  color="indigo"
                >
                  {{ $t("calculate") }}
                </v-btn>

                <div class="d-flex justify-center" v-else>
                  <v-progress-circular
                    :indeterminate="true"
                    size="40"
                    color="indigo"
                  ></v-progress-circular>
                </div>
              </v-form>
            <!-- </v-card-body> -->
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="7" lg="6">
          <v-alert v-model="showCost" type="success" dismissible>
            Your shipping cost is : {{ cost }} USD
          </v-alert>

          <v-row>
            <v-col class="ma-0 pa-0" cols="12">
              <v-expansion-panels flat>
                <v-expansion-panel
                  v-for="price in prices"
                  :key="price.name"
                  style="background: #FFFFFF;border-radius: 10px; margin-bottom: 10px"
                >
                  <v-expansion-panel-header style="padding: 15px 20px">
                    <v-row>
                      <v-col cols="12" md="7">
                        <div
                          style="display: flex; align-items: center; height: 1em; line-height: 1em; margin-right: 0.5em;"
                        >
                          <div style="width: 7em;">
                            <v-img :src="backendUrl + '/' + price.logo" />
                          </div>

                          <b
                            :style="
                              $i18n.locale === 'ar'
                                ? 'margin-right: 1em'
                                : 'margin-left: 1em'
                            "
                            >{{
                              $i18n.locale === "ar" && price.nameAR
                                ? price.nameAR
                                : price.name
                            }}</b
                          >
                        </div>
                      </v-col>
                      <v-col cols="12" md="5">
                        <div
                          style="height: 100%; width: 100%; display: flex; justify-content: space-between; flex-direction: row;"
                        >
                          <span class="text-success"
                            ><span class="text-success">{{
                              price.minShipTime && price.maxShipTime
                                ? "⚡ " +
                                  price.minShipTime +
                                  " - " +
                                  price.maxShipTime +
                                  " " +
                                  $t("days")
                                : ""
                            }}</span></span
                          >
                         <div class="d-flex flex-column align-end">
                          <b style="font-size: 16px;">{{ price.price }} &euro;</b>
                          <b class="mt-1 px-1" style="font-size: 15px;color:grey">{{ (price.price * exchangeRate).toFixed(2) }} $</b>
                         </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <!-- <v-expansion-panel-content style="color:#616161">
                            {{price.price}} $
                            </v-expansion-panel-content> -->
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-snackbar :color="color" v-model="snackbar">
        {{ message }}
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { countries, backendUrl } from "../config/config";
import auth from "../helpers/auth";
import Navbar from '../components/Navbar';
import DashboardNavbar from "../components/DashboardNavbar.vue"


const newAxios = axios.create();
newAxios.defaults.headers.common = {};

export default {
  name: "ShippingCalculator",
  components: { Navbar, DashboardNavbar, },
  data() {
    return {
      companies: [],
      searched: false,
      numRegex: /^\d*(\.\d+)?$/,
      weight: 10,
      company: "",
      cost: 0,
      showCost: false,
      loading: false,
      color: "",
      message: "",
      snackbar: false,
      countryList: countries,
      country: "",
      countryErrors: [],
      countryMenu: false,
      errorMessages: [],
      height: 10,
      width: 20,
      length: 25,
      warehouse: "",
      warehouses: [],
      prices: [],
      backendUrl,
      isAuthenticated:false,

      exchangeRate :0

    };
  },
  mounted() {
    if (this.$i18n.locale === "ar") {
      this.$vuetify.rtl = true;
    }

    newAxios.get("/api/addresses/company").then((res) => {
      this.warehouses = res.data.addresses
    });

    this.isAuthenticated = auth.authenticated()

    axios.get('https://ipapi.co/json/').then((res) => {
      const index = countries.map(country => country.iso_2).indexOf(res.data.country)
      if(index != -1)
        this.country = countries[index].name
    });
    //parse exchange rate
    newAxios.get('/api/packages/euro-rate').then((res) => {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(res.data.rateData, "text/xml");
      const rate = xmlDoc.getElementsByTagName("Cube")[0]
        .getElementsByTagName("Cube")[0]
        .getElementsByTagName("Cube")[0]
        .getAttribute("rate");
      this.exchangeRate = parseFloat(rate)

    });
  },
  computed: {
    volumetricWeight(){
      if(!this.height || !this.width || !this.length){
        return ""
      }
      return (parseInt(this.height) * parseInt(this.width) * parseInt(this.length)) / 5000
    }
  },
  methods: {
    clearErrors(){
      if(this.errorMessages.length > 0)
        this.errorMessages = []
    },
    // getCountryFlag() {
    //   const country = this.country;
    //   return `https://countryflagsapi.com/png/${country}`;
    // },
    calc() {
      if(!this.$refs.form.validate())
        return
      const index = countries.map(country => country.name).indexOf(this.country)
      if(index == -1){
        this.countryErrors.push(this.$t('invalidCountry'))
        return  
      }
      else if(this.countryErrors.length > 0)
        this.countryErrors = []
      this.loading = true;
      this.searched = true;
      newAxios
        .post(
          "/api/users/calc_shipping",
          {
            weight: this.weight,
            country: this.country,
            height: this.height,
            width: this.width,
            length: this.length,
            warehouse: this.warehouse,
          }
        )
        .then((res) => {
          this.loading = false;
          this.prices = [];
          if (res.data.error) {
            this.message = this.$i18n.t("outRange");
            this.errorMessages.unshift(this.message)
          } else {
            this.prices = res.data.prices;
            this.clearErrors()
          }
        })
        .catch((err) => {
          console.log(err);

          this.snackbar = true;
          this.color = "error";
          this.message = this.$i18n.t("errorMessage");
          this.loading = false;
        });
    },

    filterCountries() {
      let arr = [];

      if (!this.country) {
        this.countryMenu = false;
        return;
      }

      this.countryMenu = true;

      arr = countries.filter(item => item.name.toLowerCase().includes(this.country.toLowerCase()));

      this.countryList = arr;
    },
    setCountry(item) {
      this.country = item.name;
      this.countryMenu = false;
    },
  },
};
</script>
