<template>
    <div>
    
    <Navbar />
    
    <v-layout style="margin-top:150px;" align-center justify-center>
    <v-flex xs12 sm8 md4>
      <v-card class="elevation-12" style="margin: 0 20px 0 20px">
        <v-toolbar dark color="indigo">
          <v-toolbar-title>{{$t('recoverText')}}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" @submit.prevent="handleSubmit" v-model="valid">
            <v-text-field
              v-model="email"
              name="email"
              :label="$t('email')"
              id="email"
              type="email"
              color="green"
              :rules="emailRules"
              required
            ></v-text-field>

            <v-spacer></v-spacer>
          
            <v-spacer></v-spacer>


            <div v-if="loading" class="d-flex justify-center">
              <v-progress-circular
            :indeterminate="true"
            size="40"
            color="indigo"
          ></v-progress-circular>
            </div>

            <div v-else class="d-flex justify-center" >
              <v-btn class="ma-2 mx-auto"  type="submit"  dark color="indigo" >
                  {{$t('send')}}
              </v-btn>
            </div>
          </v-form>
        </v-card-text>

        <v-card-actions>
          
          
        </v-card-actions>
      </v-card>
    </v-flex>
    <v-snackbar left :color="color" v-model="snackbar">
              {{ message }}
    </v-snackbar>

  </v-layout>
    </div>
</template>

<script>
import Navbar from '../components/Navbar';
import axios from 'axios';

const emailRe = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const newAxios = axios.create()
newAxios.defaults.headers.common = {}

export default {
    name:'RecoverPass',
    components:{Navbar},
    data(){
        return {
            email:'',
            emailRules: [
                v => !!v || 'Email is required',
                v =>emailRe.test(String(v).toLowerCase()) || "Enter a valid Email"

                ],
            snackbar:false,
            message:'',
            color:'',
            loading:false,
            valid:false
        }
    },
    methods:{
        handleSubmit(){

          this.loading = true;

          if(!this.$refs.form.validate()){
          return;
        }
          newAxios.post('/api/users/password/recover',{email:this.email}).then(res=>{

            this.loading = false;
            if(!res.data.error){
                
                if(this.$i18n.locale == 'ar'){
                  this.message = 'تم ارسال رسالة التفعيل الى بريد الالكتروني'
                }else if(this.$i18n.locale == 'en'){
                  this.message = 'An activaton link has been sent to your email'
                }

                this.color = 'success';
                this.snackbar = true;
            }else{
              if(res.data.status == 404){
                if(this.$i18n.locale == 'ar'){
                  this.message = 'البريد الالكتروني غير موجود'
                }else if(this.$i18n.locale == 'en'){
                  this.message = 'Email was not found'
                }

                this.color = 'error';
                this.snackbar = true;
              }else {
                if(this.$i18n.locale == 'ar'){
                  this.message = 'حدث خطأ ما الرحاء المحاولة مرة اخرى'
                }else if(this.$i18n.locale == 'en'){
                  this.message = 'An error happened please try again '
                }

                this.color = 'error';
                this.snackbar = true;
              }
            }


          }).catch(err=>{
            console.log(err)

            this.loading = false;

            if(this.$i18n.locale == 'ar'){
                  this.message = 'حدث خطأ ما الرحاء المحاولة مرة اخرى'
                }else if(this.$i18n.locale == 'en'){
                  this.message = 'An error happened please try again '
                }

                this.color = 'error';
                this.snackbar = true;
          })
        }
    }
}
</script>

