<template>
    <v-layout fill-height column>

        <v-container>
            <v-row class="mb-6">
                <v-col cols="12" md="6">
                    <v-card class="pa-2">
                        <div class="ma-2" style="font-size:22px">{{$t('subject')}}: {{ticket.title}}</div>

                        <div class="ma-2" style="font-size:18px">{{$t('status')}}: <span :class="(ticket.status == 'open') ? 'success--text' : 'error--text'">{{
                                        (ticket.status == 'open' ) ? $t('open') : $t('closed')
                                        }}</span></div>

                        <div class="ma-2" style="font-size:18px">{{$t('date')}}: {{formatDate(ticket.createdAt)}}</div>
                        <div>
                            <v-btn v-if="ticket.status === 'open'" class="ma-2"  small tile @click="markClosed(ticket._id)" :disabled="ticket.status == 'closed'" color="success">
                                <v-icon size="15" left>fa-solid fa-check</v-icon> mark as closed
                            </v-btn>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card>
                        <v-card-title>{{$t('userInfos')}}:</v-card-title>

                        <v-card-text>
                            <div class="ma-1"><span style="font-weight:bold">{{$t('fullName')}}:</span> {{user.fullName}}</div>
                            <div class="ma-1"><span style="font-weight:bold">{{$t('email')}}:</span> {{user.email}}</div>
                            <div class="ma-1"><span style="font-weight:bold">{{$t('phone')}}:</span> {{user.phone}}</div>
                            <div class="ma-1"><span style="font-weight:bold">{{$t('code')}}:</span> {{user.addressHash}}</div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <!-- <div class="ma-2" style="font-size:22px">Subject: {{ticket.title}}</div>

            <div class="ma-2" style="font-size:18px">Status: <span :class="(ticket.status == 'open') ? 'success--text' : 'error--text'">{{
                            (ticket.status == 'open' ) ? 'Open' : 'Closed'
                            }}</span></div>

            <div class="ma-2" style="font-size:18px">Date: {{formatDate(ticket.createdAt)}}</div> -->

            <div class="ma-4">

                <div v-for="msg in ticket.messages" :key="msg.id">
                    <v-card v-if="!msg.isAdmin" class="blue ma-4 darken-1 white--text">
                        <v-card-text class="pa-2">
                            <v-row>
                                    <v-col md="1">
                                        <div class="ma-1"><v-icon size="55" color="white">fa-solid fa-user-circle</v-icon></div>

                                    </v-col>

                                    <v-col md="11" style="font-size:16px" class="pa-4 white--text">
                                        {{msg.message}}
                                    </v-col>
                             </v-row>
                        </v-card-text>

                        <div class="d-flex align-center justify-space-between pa-3">
                            <a v-if="msg.file" class="v-btn v-btn--depressed v-btn--flat v-btn--outlined v-btn--rounded theme--dark v-size--default white--text" target="_blank" :href="backendUrl + '/' + msg.file">{{$t('openFile')}}</a>
                            {{formatDate(msg.createdAt)}}
                        </div>
                    </v-card>


                    <v-card v-else class="green ma-4 darken-1 white--text">
                        <v-card-text class="pa-2">
                            <v-row>
                                    <v-col md="1">
                                        <div class="ma-1"><v-icon size="55" color="white">fa-solid fa-info-circle</v-icon></div>

                                    </v-col>

                                    <v-col md="11" style="font-size:16px" class="pa-4 white--text">
                                        {{msg.message}}
                                    </v-col>
                             </v-row>
                        </v-card-text>

                        <div class="d-flex align-center justify-space-between pa-3">
                            <a v-if="msg.file" class="v-btn v-btn--depressed v-btn--flat v-btn--outlined v-btn--rounded theme--dark v-size--default white--text" target="_blank" :href="backendUrl + '/' + msg.file">{{$t('openFile')}}</a>
                            {{formatDate(msg.createdAt)}}
                        </div>
                </v-card>


                </div>


            </div>

        </v-container>

         <v-layout align-end class="pa-5 ma-5" >
               <v-container>
                    <v-textarea

                    class="ma-1"
                    label="Message"
                    auto-grow
                    dense
                    outlined
                    color="indigo"
                    v-model="content"
            ></v-textarea>

            <v-file-input accept="image/*, application/pdf" @change="this.onFilesPicked" show-size hide-input label="Attach file"></v-file-input>

            <v-btn @click="sendMsg" :disabled="loading" color="indigo"  class="white--text ma-1">Send</v-btn>
               </v-container>
            </v-layout>


    <v-snackbar left :color="color" v-model="snackbar">
                {{ message }}
            </v-snackbar>

    </v-layout>
</template>

<script>
import axios from 'axios';
import { backendUrl } from "../config/config";

export default {
    name:'AdminSupport',
    data(){
        return {
            ticket:{},
            backendUrl,
            user: {},
            content:'',
            file: {},
            message:'',
            color:'',
            snackbar:false,
            loading:false,
        }
    },
    mounted(){
        axios.post('/api/admins/support/fetch',{
            ticketId:this.$route.params.id
        }).then(res=>{
            this.ticket = res.data.ticket
            this.user = this.ticket.user
            //console.log(this.user)
        })
    },
    methods:{
        onFilesPicked(f){
            const type = f.type.split('/')
            const size = parseInt(f.size)
            if (f.type !== "application/pdf" && type[0] !== "image") {
                this.snackbar = true;
                this.color = 'warning';

                if(this.$i18n.locale == 'ar'){
                    this.message = 'يمكنك فقط تحميل ملفات الصور أو pdf'
                }else if(this.$i18n.locale == 'en'){
                    this. message = "you can only upload image or pdf files";
                }
            } else if (size > 10485760) {
                this.snackbar = true;
                this.color = 'warning';

                if(this.$i18n.locale == 'ar'){
                    this.message = 'لا يمكنك تحميل ملف أكبر من 10 ميغا بايت'
                }else if(this.$i18n.locale == 'en'){
                    this. message = "you can't upload file larger than 10MB";
                }
            } else {
                this.file = f
                console.log(this.file)
            }
            //console.log(this.images.size)
        },
        sendMsg(){
            const data = new FormData();

            data.append('message',this.content)
            data.append('file',this.file)
            data.append('ticketId',this.ticket._id)

            axios.post('/api/admins/support/send', data).then(res=>{

                if(!res.data.error){
                    this.ticket.messages.push(res.data.message)
                    this.content = '';
                }else{
                    this.color = 'error';
                    this.message = res.data.message;
                    this.snackbar = true;
                }
            }).catch(err=>{
                    this.color = 'error';
                    this.message = 'An error happend please try again';
                    this.snackbar = true;
            })
        },
        markClosed(id){

            axios.get('/api/admins/support/close/' + id).then(res=>{
                if(!res.data.error){
                    this.message = 'updated successfully';
                    this.color = 'success';
                    this.snackbar = true;
                    axios.post('/api/admins/support/fetch',{
                        ticketId:this.$route.params.id
                    }).then(res=>{
                        this.ticket = res.data.ticket
                        this.user = this.ticket.user
                    })
                }
            }).catch(err=>{
                this.message = 'An error happened please try again';
                    this.color = 'error';
                    this.snackbar = true;
            })
        },
        formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear(),
        hours = (d.getHours().toString().length ==1) ? '0' +d.getHours() : d.getHours(),
        mins = (d.getMinutes().toString().length ==1) ? '0' +d.getMinutes() : d.getMinutes()

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-") + ` ${hours}:${mins}`;
    }
    }
}
</script>