<template>
    <v-container>
        <v-data-table
            :headers="tableHeaders"
            :items="transactions"
            :items-per-page="10"
            :search="search"
            :custom-filter="customFilter"
            class="elevation-2 grey lighten-5 ma-3"
        >

            <template v-slot:top>
                <v-text-field v-model="search" placeholder="Search" class="mx-4"></v-text-field>
            </template>

            <template v-slot:item.email="{ item }">
                <div>{{item.user.email}}</div>
            </template>

            <template v-slot:item.userHash="{ item }">
                <router-link 
                    style="text-decoration:none;font-size:16px;color:#2E86C1"
                    :to="'/en/admin/user/' + item.user.id"
                >
                    <v-icon color="red" class="mx-3" size="15" v-if="(item.user) ? item.user.suspicious : false">fas fa-exclamation-triangle</v-icon> {{(item.user) ? item.user.addressHash : ''}}
                </router-link>
            </template>

            <template v-slot:item.amount="{ item }">
                <div>{{item.amount}}</div>
            </template>

            <template v-slot:item.date="{ item }">
                <div>{{ formatDate(item.createdAt)}}</div>
            </template>

            <template v-slot:item.actions="{ item }">
                <div>
                    <v-btn v-if="user.groupId <= adminId" @click.stop="confirm(item)" class="ma-2" small tile outlined :disabled="loading" color="success">
                        <v-icon size="15" left>fas fa-check-circle</v-icon> Confirm
                    </v-btn>

                    <v-btn v-if="!item.complete && user.groupId <= adminId" class="ma-2" @click="cancelRequest(item)"   small tile outlined color="warning">
                        <v-icon size="15" left>fas fa-times-circle</v-icon> cancel
                    </v-btn>

                </div>
            </template>
        </v-data-table>

        <v-dialog v-model="cancelDialog" max-width="600">

            <v-card>
                <v-card-title class="headline">Please confirm your action</v-card-title>
                <v-spacer></v-spacer>
                <v-card-text>
                <v-alert
                    border="top"
                    colored-border
                    type="warning"
                    elevation="2"
                >
                    Are you sure that you want to cancel this transaction ?
                </v-alert>
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn depressed @click="handleCancelRequest(selectedRequest._id)" color="error">Cancel Transaction</v-btn>
                <v-btn @click="cancelDialog=!cancelDialog" class="ma-2" color="grey white--text">close</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>

        <v-dialog v-model="dialog" max-width="600">

            <v-card>
                <v-card-title class="headline">Please confirm your action</v-card-title>
                <v-spacer></v-spacer>
                <v-card-text>
                <v-alert
                    border="top"
                    colored-border
                    type="warning"
                    elevation="2"
                >
                    Are you sure that you want to accept this payment ?
                </v-alert>
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn depressed @click="handleConfirm(selectedRequest._id)" color="success">Confirm</v-btn>
                <v-btn @click="dialog=!dialog" class="ma-2" color="grey white--text">close</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>

        <v-snackbar left :color="color" v-model="snackbar">{{ message }}</v-snackbar>
    </v-container>
</template>

<script>
import axios from 'axios';
import auth from '../helpers/auth';


export default {
    name:'BitcoinPayment',
    data(){
        return {
            user: auth.admin(),
            adminId: 1,
            supportId: 2,
            requests:[],
            tableHeaders:[
                {
                    text:'Email',
                    value:'email',
                    align:'center'
                },
                {
                    text:'User Hash',
                    value:'userHash',
                    align:'center'
                },
                {
                    text:'Transaction Date',
                    value:'date',
                    align:'center'
                },
                {
                    text:'Amount (USD)',
                    value:'amount',
                    align:'center'
                },
                {
                    text:'Actions',
                    value:'actions',
                    align:'center'
                }
            ],
            search:'',
            dialog:false,
            cancelDialog: false,
            transactions:[],
            selectedRequest:'',
            loading:false,
            snackbar:false,
            color:'',
            message:'',
            requestId:''


        }
    },
    mounted(){
        axios.get('/api/admins/transactions/bitcoin').then(resp=>{
            this.transactions = resp.data.transactions;
        })
    },

    sockets: {
    loadBitcoinPayments(transactions) {
        //console.log(data.notifications)
        // Fired when a approval requests got updated.
        this.transactions = transactions
        }
    },

    methods:{
    formatDate(date) {
        var d = new Date(date) ,
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            hour = d.getHours(),
            mins = d.getMinutes();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
        if(mins < 10)
           mins = '0' + mins;
        if(hour < 10)
           hour = '0' + hour;

        return [year, month, day].join('-') + ` ${hour}:${mins}`;
    },


    handleConfirm(id){
        this.loading = true;

         axios.get('/api/admins/bitcoin/confirm_payment/'+id).then((res)=>{
             if(!res.data.error){
                 this.loading = false;
                 this.message = 'Confirmed Successfully';
                 this.color = 'success';
                 this.snackbar = true;

                 this.transactions = this.transactions.filter(item=> item._id != id)
                 this.dialog = false
             }
         }).catch(err=>{
             console.log(err)
             this.loading = false;
             this.message = 'An error occurred please try again later';
             this.color = 'error';
             this.snackbar = true;
         })
    },

    confirm(request) {
        this.cancelDialog = false
        this.selectedRequest = request;
        this.dialog = true; 
    },

    cancelRequest(item) {
        this.dialog = false
        this.selectedRequest = item;
        this.cancelDialog = true; 
    },

    handleCancelRequest(id) {
        axios.post('/api/admins/transactions/bitcoin/cancel',{
            transactionId: this.selectedRequest._id,
            userId: this.selectedRequest.user.id,
            packageId: this.selectedRequest.package
        }).then(res=>{

            if(!res.error){

                this.message = 'Request was canceled Successfully';
                 this.color = 'warning';
                 this.snackbar = true;

                 this.transactions = this.transactions.filter(item=>{
                    
                    return item._id != id
                 })
                 
                 this.cancelDialog = false;
            }
        }).catch(err=>{
            console.log(err)
             this.loading = false;
             this.message = 'An error occurred please try again later';
             this.color = 'error';
             this.snackbar = true;
        })
    },

   
    customFilter (value, queryText, item) {
        
        const trackNumber = item.package.trackNumber.toLowerCase()
        const searchText = queryText.toLowerCase()

        return trackNumber.indexOf(searchText) > -1
      }

    }
}
</script>