<template>
    <v-container>
        <!-- view table -->
    <v-data-table
      :headers="tableHeaders"
      :items="packages"
      :page.sync="page"
      :server-items-length="requestCount"
      :loading="loading"
      @update:page="pageUpdateFunction"
      @update:items-per-page="itemsPerPageUpdateFunction"
      :items-per-page.sync="itemsPerPage"
      class="elevation-2 grey lighten-5 ma-3"
    >
      <template v-slot:top>
        <v-row class="d-flex align-center">
            <v-col :cols="10">
                <v-text-field v-model="search" placeholder="Search" class="mx-4"></v-text-field>
            </v-col>
            <v-col :cols="2">
                <v-btn class="mx-4" @click="searchUpdateFunction" color="primary">Search</v-btn>
            </v-col>
        </v-row>
      </template>

      <template v-slot:item.username="{ item }">
        <router-link 
            style="text-decoration:none;font-size:16px;color:#2E86C1"
            :to="'/en/admin/user/' + item.user.id"
        >
            <v-icon color="red" class="mx-3" size="15" v-if="(item.user) ? item.user.suspicious : false">fas fa-exclamation-triangle</v-icon> {{(item.user) ? item.user.fullName : ''}}
        </router-link>
      </template>

      <template v-slot:item.userHash="{ item }">
        <div>{{item.user.addressHash}}</div>
      </template>

      <template v-slot:item.trackNumber="{ item }">
        <div>{{item.shippedTrackNumber}}</div>
      </template>

       <template v-slot:item.rcvTrackNumber="{ item }">
        <div>{{item.trackNumber}}</div>
      </template>

      <template v-slot:item.packageId="{ item }">
        <div>{{item.packageId}}</div>
      </template>

      <template v-slot:item.date="{ item }">
        <div>{{ formatDate(item.createdAt)}}</div>
      </template>

      <template v-slot:item.shipDate="{ item }">
        <div>{{ formatDate(item.shipDate)}}</div>
      </template>

      <template v-slot:item.actions="{ item }">
        <div>
          <v-btn class="ma-2" @click="showPackage(item)" small tile outlined color="primary">
            <v-icon size="15" left>fas fa-eye</v-icon>show
          </v-btn>

          <v-btn v-if="user.groupId === adminId" class="ma-2"  small tile outlined @click="setEdit(item)" color="success">
              <v-icon size="15" left>fas fa-edit</v-icon> Edit
          </v-btn>
        </div>
      </template>

      <template v-slot:footer>
          <v-row justify="center">
              <v-col cols="8">
                  <v-container class="max-width">
                      <v-pagination
                      v-model="page"
                      class="my-4"
                      :total-visible="7"
                      :length="pageCount"
                      @input="pageUpdateFunction"
                      ></v-pagination>
                  </v-container>
              </v-col>
          </v-row>
      </template>

    </v-data-table>

     <!-- package info modal -->

    <v-dialog v-model="dialog" max-width="600">
      <v-card class="pa-3">
        <v-card-title>Information</v-card-title>
        <v-card-text>
          <div class="ma-1" style="font-size:16px">
            <b>Package Id:</b> <span>{{selectedRequest.packageId}}</span>
          </div>
          <div class="ma-1" style="font-size:16px">
            <b>Track Number:</b> <span>{{selectedRequest.trackNumber}}</span>
          </div>
          <div class="ma-1" style="font-size:16px">
            <b>Weight:</b> <span>{{selectedRequest.weight}} kg</span>
          </div>
          <div class="ma-1" style="font-size:16px">
            <b>dimensions:</b> <span>{{selectedRequest.length}} * {{selectedRequest.width}} * {{selectedRequest.height}} cm</span>
          </div>
          <div class="ma-1" style="font-size:16px">
            <b>Shipping Company:</b> <span>{{(selectedRequest.shipMethod)? selectedRequest.shipMethod.company.name : ''}}</span>
          </div>

          <div class="ma-1" style="font-size:16px">
            <b>Tracking Link:</b> <span>{{selectedRequest.trackLink}}</span>
          </div>

          <div class="ma-1" style="font-size:16px">
            <b>Price:</b> <span class="success--text">{{(selectedRequest.shipMethod)? selectedRequest.shipMethod.price : ''}} $</span>
          </div>

          <div class="ma-1" style="font-size:16px">
              <b>Fees:</b> <span>{{selectedRequest.fees ? `${selectedRequest.fees} $` : 'not mentioned'}}</span>
          </div>
          


          <template v-for="detail in selectedRequest.details" >
            <v-card>
                <v-card-text>
                    <div class="ma-1" style="font-size:16px">
                        <b>Description:</b> <span>{{detail.description}}</span>
                    </div>

                    <div class="ma-1" v-if="detail.code_sh" style="font-size:16px">
                        <b>HS Code:</b> <span>{{detail.code_sh}}</span>
                    </div>

                    <div class="ma-1" style="font-size:16px">
                        <b>Made In:</b> <span>{{detail.madeIn}}</span>
                    </div>

                    <div class="ma-1" style="font-size:16px">
                        <b>Type:</b> <span>{{detail.type}}</span>
                    </div>

                    <div class="ma-1" style="font-size:16px">
                        <b>Quantity:</b> <span>x{{detail.quantity}}</span>
                    </div>

                    <div class="ma-1" style="font-size:16px">
                        <b>price:</b> <span>{{detail.price}}</span>
                    </div>
                </v-card-text>
            </v-card>
          </template>

          <div class="ma-2" style="font-size:20px;font-weight:bold">Images</div>

          <v-row>
            <v-col v-for="img in selectedRequest.images" v-viewer :key="img" sm="4">
              <img height="150" class="ma-2 elevation-3" :src="backendUrl + '/' + img"></img>
            </v-col>
          </v-row>

          <div class="ma-2" style="font-size:20px;font-weight:bold">Shipping Address</div>

          <div v-if="selectedRequest.shippingAddress">

              <div class="ma-1" style="font-size:16px">
            <b>Name:</b> <span>{{selectedRequest.shippingAddress.name}}</span>
          </div>
           <div class="ma-1" style="font-size:16px">
              <b>National ID:</b> <span>{{selectedRequest.shippingAddress.nationalID}}</span>
            </div>
          <div class="ma-1" style="font-size:16px">
            <b>Country:</b> <span>{{selectedRequest.shippingAddress.country}}</span>
          </div>
          <div class="ma-1" style="font-size:16px">
            <b>City:</b> <span>{{selectedRequest.shippingAddress.city}}</span>
          </div>
          <div class="ma-1" style="font-size:16px">
            <b>Address Line One:</b> <span>{{selectedRequest.shippingAddress.addressLineOne}}</span>
          </div>
          <div class="ma-1" style="font-size:16px">
            <b>Address Line Two:</b> <span>{{selectedRequest.shippingAddress.addressLineTwo}}</span>
          </div>

          <div class="ma-1" style="font-size:16px">
            <b>Phone:</b> <span>{{selectedRequest.shippingAddress.phone}}</span>
          </div>

         <div class="ma-1" style="font-size:16px">
            <b>Postal:</b> <span>{{selectedRequest.shippingAddress.zip}}</span>
          </div>

    
          </div>

          <br>
          
          <div class="ma-2" style="font-size:20px;font-weight:bold">Shipping Options</div>

          <div v-if="selectedRequest.shippingOptions">

            <div class="ma-1" style="font-size:16px">
              <b>Does it contain lithium batteries?:</b> <span>{{selectedRequest.shippingOptions.hasLithium ? 'Yes' : 'No'}}</span>
            </div>
            <div class="ma-1" style="font-size:16px">
              <b>Does it contain dangerous substances (such as perfumes)?:</b> <span>{{selectedRequest.shippingOptions.hasDangerous ? 'Yes' : 'No'}}</span>
            </div>
            <div class="ma-1" style="font-size:16px">
              <b>Remove bills:</b> <span>{{selectedRequest.shippingOptions.removeBills ? 'Yes' : 'No'}}</span>
            </div>
            <div class="ma-1" style="font-size:16px">
              <b>Remove shoe boxes:</b> <span>{{selectedRequest.shippingOptions.removeShoeBoxes ? 'Yes' : 'No'}}</span>
            </div>
            <div class="ma-1" style="font-size:16px">
              <b>Additional notes:</b> <br> 
              <span>{{selectedRequest.shippingOptions.additionalNote}}</span>
            </div>

          </div>
        </v-card-text>

        <v-card-actions class="d-flex justify-end">
          <v-btn @click="dialog=!dialog" class="ma-2" color="grey white--text">cancel</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

    <v-dialog v-model="editDialog" width="600">
      <v-card>
        <v-card-title>Update Package Tracking Number</v-card-title>
        <v-card-text>
          <v-form ref="form" @submit.prevent="handleSubmit">
            <v-text-field
                v-model="selectedRequest.shippedTrackNumber"
                name="trackNumber"
                label="Track Number"
                id="trackNumber"
                type="text"
                color="indigo"
                required
                :rules="[(v) => !!v || $i18n.t('codeRequired')]"
            ></v-text-field>


            <div v-if="loading" class="d-flex justify-center">
                <v-progress-circular :indeterminate="true" size="40" color="indigo"></v-progress-circular>
            </div>

            <div v-else class="d-flex justify-center">
                <v-btn class="ma-2 mx-auto" type="submit" dark color="indigo">Update</v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :color="color"> {{message}} </v-snackbar>

    </v-container>
</template>

<script>
import { backendUrl } from "../config/config";
import axios from 'axios';
import auth from "../helpers/auth"

export default {
    name:'ShippedPackages',
    data(){
        return {
          user: auth.admin(),
          page: 1,
          adminId: 1,
          pageCount: 5,
          requestCount: 1,
          itemsPerPage: 10,
          tableHeaders: [
        {
          text: "Username",
          value: "username",
          align: "center"
        },
        {
          text: "User Hash",
          value: "userHash",
          align: "center"
        },
        {
          text: "Request Date",
          value: "date",
          align: "center"
        },
        {
            text: "Shipping Date",
            value:'shipDate',
            align:'center'
        },
        {
          text: "Rcv. Track Number",
          value: "rcvTrackNumber",
          align: "center"
        },
        {
          text: "Track Number",
          value: "trackNumber",
          align: "center"
        },
        {
          text: "Package Id",
          value: "packageId",
          align: "center"
        },
        {
          text: "Actions",
          value: "actions",
          align: "center"
        }
      ],
      packages:[],
      dialog: false,
      loading: true,
      imgDialog: false,
      editDialog: false,
      snackbar: false,
      message: '',
      color: '',
      displayImg: '',
      backendUrl,
      search:'',
      selectedRequest:{}
        }
    },

    watch: {
      search: function (newSearch) {
          if (newSearch === '') {
              axios.get('/api/packages/shipped/get').then(res => {
                  this.page = 1
                  this.requestCount = res.data.requestCount
                  this.pageCount = res.data.pageCount
                  this.packages = res.data.packages;
              })
          }
      }
    },

    methods:{
      setEdit(item){
        this.selectedRequest = item;
        this.editDialog = true;
      },

      handleSubmit(){
        if (!this.$refs.form.validate()) {
        return;
        }

        this.loading = true;

        axios.post('/api/packages/update/tracknumber/'+this.selectedRequest._id, {trackNumber: this.selectedRequest.shippedTrackNumber}).then((res)=>{
             if(!res.data.error){
                 this.loading = false;
                 this.message = 'Updated Successfully';
                 this.color = 'indigo';
                 this.snackbar = true;
                 this.editDialog = false;
             }else{
                 this.loading = false;
                 this.message = res.data.message;
                 this.snackbar = true;
                 this.color = 'error';
             }
         }).catch(err=>{
             console.log(err)
             this.loading = false;
             this.message = 'An error occurred please try again later';
             this.color = 'error';
             this.snackbar = true;
         })
      },

      pageUpdateFunction(newPageNumber) {
        if(this.loading)
          return;

        this.loading = true;
        axios.get(`/api/packages/shipped/get?page=${newPageNumber-1}&perPage=${this.itemsPerPage}`).then(res=>{
            this.page = newPageNumber
            this.pageCount = res.data.pageCount
            this.packages = res.data.packages
            this.loading = false;
        })

    },
    itemsPerPageUpdateFunction(length) {
        if(this.loading)
          return;

        this.loading = true;
        this.page= 1
        this.pageCount = 1
        axios.get(`/api/packages/shipped/get?page=${this.pageCount -1}&perPage=${length}`).then(res=>{
            this.requestCount = res.data.requestCount
            this.pageCount = res.data.pageCount
            this.packages = res.data.packages
            this.loading = false;
        })

    },

    searchUpdateFunction(){
        const newQuery = this.search
        if (newQuery) {
            axios.get(`/api/packages/shipped/get?query=${newQuery}`).then(res => {
                this.requestCount = res.data.requestCount
                this.pageCount = res.data.pageCount
                this.packages = res.data.packages
            })
        }
    },
    //     customFilter(value, queryText, item) {
    //   const addressHash = item.user.addressHash.toLowerCase();
    //   const username = item.user.fullName.toLowerCase();
    //   const trackNumber = item.trackNumber.toLowerCase();
    //   const searchText = queryText.toLowerCase();

    //   return (
    //     addressHash.indexOf(searchText) > -1 ||
    //     username.indexOf(searchText) > -1 ||
    //     trackNumber.indexOf(searchText) > -1
    //   );
    // },

  formatDate(date) {
            if (!date) return 'xx-xx-xx'
            var d = new Date(date) ,
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear(),
                hour = d.getHours(),
                mins = d.getMinutes();

            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;
            if(mins < 10)
            mins = '0' + mins
            if(hour < 10)
            hour = '0' + hour

            return [year, month, day].join('-') + ` ${hour}:${mins}`;
        },

    showPackage(pack) {
      this.dialog = true;
      this.selectedRequest = pack;
    },
    },
    mounted(){
        axios.get("/api/packages/shipped/get").then(res => {
          this.requestCount = res.data.requestCount
          this.pageCount = res.data.pageCount
          this.packages = res.data.packages;
          this.loading = false;
    });
    }
}
</script>