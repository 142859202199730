<template>
  <v-container>
    <v-row>
      <v-col md="5" sm="12">
        <v-card height="120" elevation="1" style="border-radius: 10px">
          <v-row>
            <v-col class="d-flex justify-center px-0" cols="3">
              <div
                class="d-flex justify-center icon-frame"
                style="background: #3F51B5;"
              >
                <v-icon color="white" size="30">fas fa-users</v-icon>
              </div>
            </v-col>
            <v-col class="px-0" cols="6">
              <p class="card-title py-0 my-0">Total Logins</p>
              <p class="card-stat py-0 my-0">{{ totalLogins }}</p>
              <v-chip label x-small color="#E2E7FF" class="indigo--text"
                >This Month</v-chip
              >
            </v-col>
            <v-col> </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col md="5" xs="12" sm="6">
        <v-card height="120" elevation="1" style="border-radius: 10px">
          <v-row>
            <v-col class="d-flex justify-center px-0" cols="3">
              <div
                class="d-flex justify-center text-center icon-frame"
                style="background: #723FB5;"
              >
                <v-icon color="white" size="30">fas fa-box-open</v-icon>
              </div>
            </v-col>
            <v-col class="px-0" cols="6">
              <p class="card-title py-0 my-0">New Packages</p>
              <p class="card-stat py-0 my-0">{{ totalPackages }}</p>
              <v-chip label x-small color="#EADAFF" style="color: #723FB5;"
                >This Month</v-chip
              >
            </v-col>
            <v-col>
              <!-- <a href="#" style="color:darkslategray;">Details&RightArrow;</a> -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" size="100%" class="float-right mr-2"
                    >fa fa-question-circle</v-icon
                  >
                </template>
                <span
                  >Total number of packages arrived at warehouse since
                  {{ moment(minDate).format("D MMM YY") }}</span
                >
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col md="2" sm="6" xs="12">
        <v-card height="120" elevation="1" style="border-radius: 10px">
          <v-col class="text-center">
            <p class="card-title py-0 my-0">New Users</p>
            <p class="card-stat py-0 my-0">{{ newUsers }}</p>
            <v-chip label x-small color="#EADAFF" class="primary--text"
              >This Month</v-chip
            >
          </v-col>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="8" sm="12">
        <v-card height="360" elevation="1" style="border-radius: 10px">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" size="100%" class="float-right ma-2"
                >fa fa-question-circle</v-icon
              >
            </template>
            <span>Total number of packages delivered.</span>
          </v-tooltip>

          <v-col class="text-center" style="width: 100%;">
            <p class="text-start card-title">Shipped Packages by Location</p>
            <div style="width: 100%; height:280px">
              <canvas class="" ref="myBarChart"></canvas>
            </div>
            <v-menu
              v-model="byCountryChart.menu"
              top
              classs="d-flex justify-center pa-6"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ attrs, on }">
                <v-chip
                  v-on="on"
                  label
                  x-small
                  color="#EADAFF"
                  class="primary--text float-left chip-dropdown"
                  >{{ byCountryChart.chipText }}
                  <v-icon size="100%" class="ma-1 mb-2"
                    >fa-solid fa-caret-down</v-icon
                  ></v-chip
                >
              </template>

              <v-card width="200px" classs="">
                <div class="text-center pa-2">
                  <b class="mb-2">Set Range</b>
                  <p for="from" class="pb-0 mb-0 primary--text">From</p>
                  <input
                    v-model="byCountryChart.minDate"
                    type="date"
                    class="date-range-input"
                    name="from"
                  />
                  <p for="to" class="pb-0 mb-0 primary--text">To</p>
                  <input
                    v-model="byCountryChart.maxDate"
                    type="date"
                    class="date-range-input"
                    name="to"
                  />
                  <br />
                  <v-btn icon @click="setLocationGraphRange()" color="primary"
                    ><v-icon
                      color="primary"
                      size="30"
                      class="fas fa-check-circle"
                    ></v-icon
                  ></v-btn>
                </div>
              </v-card>
            </v-menu>
            <p class="card-title">Country</p>
          </v-col>
        </v-card>
      </v-col>

      <v-col cols="4" class="hidden-sm-and-down">
        <v-card height="360" elevation="1" style="border-radius: 10px">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" size="100%" class="float-right ma-2"
                >fa fa-question-circle</v-icon
              >
            </template>
            <span>Total number of packages delivered.</span>
          </v-tooltip>

          <v-col class="text-center" style="width: 90%">
            <p class="text-start card-title ma-0">Packages by Status</p>
            <canvas ref="doughnutChart" class="my-1"></canvas>
            <v-menu
              v-model="byCompanyChart.menu"
              top
              classs="d-flex justify-center pa-6"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ attrs, on }">
                <v-chip
                  v-on="on"
                  label
                  x-small
                  color="#EADAFF"
                  class="primary--text float-left chip-dropdown"
                  >{{ byCompanyChart.chipText }}
                  <v-icon size="100%" class="ma-1 mb-2"
                    >fa-solid fa-caret-down</v-icon
                  ></v-chip
                >
              </template>

              <v-card width="200px" classs="">
                <div class="text-center pa-2">
                  <b class="mb-2">Set Range</b>
                  <p for="from" class="pb-0 mb-0 primary--text">From</p>
                  <input
                    v-model="byCompanyChart.minDate"
                    type="date"
                    class="date-range-input"
                    name="from"
                  />
                  <p for="to" class="pb-0 mb-0 primary--text">To</p>
                  <input
                    v-model="byCompanyChart.maxDate"
                    type="date"
                    class="date-range-input"
                    name="to"
                  />
                  <br />
                  <v-btn icon @click="setCompaniesGraphRange()" color="primary"
                    ><v-icon
                      color="primary"
                      size="30"
                      class="fas fa-check-circle"
                    ></v-icon
                  ></v-btn>
                </div>
              </v-card>
            </v-menu>
          </v-col>
        </v-card>
      </v-col>
    </v-row>

    <!-- Third row -->
    <v-row>
      <v-col md="10" xs="12">
        <v-card height="360" elevation="1" style="border-radius: 10px">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" size="100%" class="float-right ma-2"
                >fa fa-question-circle</v-icon
              >
            </template>
            <span>Total number of packages delivered.</span>
          </v-tooltip>

          <v-col class="text-center" style="width: 100%;">
            <p class="text-start card-title">Shipped Packages by Month</p>
            <div style="width: 100%; height:280px">
              <canvas class="" ref="yearPackagesBarChart"></canvas>
            </div>
            <v-menu
              v-model="byMonthChart.menu"
              top
              classs="d-flex justify-center pa-6"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ attrs, on }">
                <v-chip
                  v-on="on"
                  label
                  x-small
                  color="#EADAFF"
                  class="primary--text float-left chip-dropdown"
                  >{{ byMonthChart.chipText }}
                  <v-icon size="100%" class="ma-1 mb-2"
                    >fa-solid fa-caret-down</v-icon
                  ></v-chip
                >
              </template>

              <v-card width="200px" classs="">
                <div class="text-center pa-2">
                  <b class="mb-2">Set Range</b>
                  <p for="from" class="pb-0 mb-0 primary--text">From</p>
                  <input
                    v-model="byMonthChart.minDate"
                    type="date"
                    class="date-range-input"
                    name="from"
                  />
                  <p for="to" class="pb-0 mb-0 primary--text">To</p>
                  <input
                    v-model="byMonthChart.maxDate"
                    type="date"
                    class="date-range-input"
                    name="to"
                  />
                  <br />
                  <v-btn icon @click="setMonthGraphRange()" color="primary"
                    ><v-icon
                      color="primary"
                      size="30"
                      class="fas fa-check-circle"
                    ></v-icon
                  ></v-btn>
                </div>
              </v-card>
            </v-menu>
          </v-col>
        </v-card>
      </v-col>

      <v-col md="2" xs="6">
        <v-card
          height="160"
          elevation="1"
          class="d-flex justify-center text-center align-center"
          style="border-radius: 10px;margin-bottom: 40px"
        >
          <div class="justify-center">
            <p class="card-title py-0 my-0">Transactions</p>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <p v-on="on" class="card-stat py-0 my-0">
                  {{ totalTransactions.num }}
                </p>
              </template>
              <span
                >Completed transactions.<br />
                Includes bank transfer and Stripe payment.</span
              >
            </v-tooltip>
            <v-chip label x-small color="#EADAFF" style="color: #723FB5;"
              >This Month</v-chip
            >
          </div>
        </v-card>

        <v-card
          height="160"
          elevation="1"
          class="d-flex justify-center text-center align-center"
          style="border-radius: 10px;margin-bottom: 40px"
        >
          <div class="justify-center">
            <p class="card-title py-0 my-0">Transactions Volume</p>
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <p v-on="on" class="trans-vol py-0 my-0">
                  &euro;{{ totalTransactions.volume }}
                </p>
              </template>
              <span>
                &euro;{{ this.totalTransactions.volumeInEUR }} <br />
                ${{ this.totalTransactions.volumeInUSD }}
              </span>
            </v-tooltip>

            <v-chip label x-small color="#EADAFF" style="color: #723FB5;"
              >This Month</v-chip
            >
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Chart from "chart.js/auto";
import axios from "axios";
import auth from "../helpers/auth";
import moment from "moment";

axios.defaults.headers.common["Authorization"] = `bearer ${auth.adminToken()}`;

export default {
  data() {
    return {
      menu: false,
      maxDate: "",
      minDate: "",
      byCountryChart: {
        chart: null,
        menu: false,
        chipText: "This Month",
        minDate: "",
        maxDate: "",
      },
      byCompanyChart: {
        chart: null,
        menu: false,
        chipText: "This Month",
        minDate: "",
        maxDate: "",
      },
      byMonthChart: {
        chart: null,
        menu: false,
        chipText: "This Year",
        minDate: "",
        maxDate: "",
      },
      totalLogins: 0,
      totalTransactions: {
        num: 0,
        volume: 0,
        volumeInUSD: 0,
        volumeInEUR: 0,
        minDate: "",
        maxDate: "",
      },
      newUsers: 0,
      totalPackages: 0,
      countries: [],
      countByCountry: [],
      inStorageCount: 0,
      shippedCount: 0,
      doughnutData: [],
      months: [],
      packagesByMonthsCount: [],
      moment
    };
  },
  mounted() {
    const today = new Date();
    this.maxDate = moment().format("yyyy-MM-DD");
    this.minDate = moment(
      new Date(today.getFullYear(), today.getMonth() - 1, today.getDate())
    ).format("yyyy-MM-DD");

    this.byCountryChart.minDate = this.minDate;
    this.byCountryChart.maxDate = this.maxDate;

    this.totalTransactions.minDate = this.minDate;
    this.totalTransactions.maxDate = this.maxDate;

    this.byCompanyChart.minDate = this.minDate;
    this.byCompanyChart.maxDate = this.maxDate;

    this.byMonthChart.minDate = moment(
      new Date(today.getFullYear() - 1, 0, 1)
    ).format("yyyy-MM-DD");
    this.byMonthChart.maxDate = this.maxDate;

    this.fetchByLocation();

    this.fetchGeneralStats(this.minDate, this.maxDate);

    this.fetchByCompany();

    this.fetchByMonth();
  },
  methods: {
    setLocationGraphRange() {
      this.byCountryChart.menu = false;
      this.byCountryChart.chipText = "Custom Range";
      this.fetchByLocation();
    },

    setMonthGraphRange() {
      this.byMonthChart.menu = false;
      this.byMonthChart.chipText = "Custom Range";
      this.fetchByMonth();
    },

    setCompaniesGraphRange() {
      this.byCompanyChart.menu = false;
      this.byCompanyChart.chipText = "Custom Range";
      this.fetchByCompany();
    },

    fetchByLocation() {
      axios
        .get(
          `/api/admins/statistics/packages-location?from=${this.byCountryChart.minDate}&to=${this.byCountryChart.maxDate}`
        )
        .then((res) => {
          this.countByCountry = res.data.packagesByCountry.map(
            (item) => item.count
          );

          this.countries = res.data.packagesByCountry.map(
            (item) => item.country
          );

          this.renderBarChart();
        })
        .catch((error) => {
          console.error(error.message);
        });
    },

    fetchByCompany() {
      axios
        .get(
          `/api/admins/statistics/packages-company?from=${this.byCompanyChart.minDate}&to=${this.byCompanyChart.maxDate}`
        )
        .then((res) => {
          const packagesByCompany = res.data.packagesByCompany;

          const companies = packagesByCompany.map((company) => company._id);
          const packagesCount = packagesByCompany.map(
            (company) => company.packagesCount
          );

          this.renderDoughnutChart(companies, packagesCount);
        })
        .catch((error) => {
          console.error(error.message);
        });
    },

    fetchByMonth() {
      axios
        .get(
          `/api/admins/statistics/packages-month?from=${this.byMonthChart.minDate}&to=${this.byMonthChart.maxDate}`
        )
        .then((res) => {
          const packagesByMonth = res.data.count;
          packagesByMonth.sort((a, b) => a._id - b._id);
          this.months = packagesByMonth.map((item) =>
            moment()
              .month(item._id - 1)
              .format("MMMM YY")
          );
          this.packagesByMonthsCount = packagesByMonth.map(
            (item) => item.shippedCount
          );

          this.renderYearPackagesBarChart();
        })
        .catch((error) => {
          console.error(error.message);
        });
    },

    fetchGeneralStats(minDate, maxDate) {
      axios
        .get(
          `/api/admins/statistics/total-logins?from=${minDate}&to=${maxDate}`
        )
        .then((res) => {
          this.totalLogins = res.data.num;
        })
        .catch((error) => {
          console.error(error.message);
        });

      axios
        .get(
          `/api/admins/statistics/total-packages?from=${minDate}&to=${maxDate}`
        )
        .then((res) => {
          this.totalPackages = res.data.num;
        })
        .catch((error) => {
          console.error(error.message);
        });

      axios
        .get(
          `/api/admins/statistics/total-transactions?from=${this.totalTransactions.minDate}&to=${this.totalTransactions.maxDate}`
        )
        .then((res) => {
          this.totalTransactions.num = res.data.transactionsNum;
          res.data.transactionsVolume.map((currency) => {
            if (currency._id === "USD") {
              this.totalTransactions.volumeInUSD = currency.sum;
            } else if (currency._id === "EUR") {
              this.totalTransactions.volumeInEUR = currency.sum;
            }
          });
          this.totalTransactions.volume =
            res.data.transactionsVolume[0]._id == "EUR"
              ? res.data.transactionsVolume[0].sum
              : res.data.transactionsVolume[1].sum;
          // Converting USD to EUR
          this.totalTransactions.volume +=
            res.data.transactionsVolume[0]._id == "USD"
              ? res.data.transactionsVolume[0].sum * 0.93
              : res.data.transactionsVolume[1].sum * 0.93;
          this.totalTransactions.volume = this.totalTransactions.volume.toFixed(
            2
          );
        })
        .catch((error) => {
          console.error(error.message);
        });

      axios
        .get(`/api/admins/statistics/new-users?from=${minDate}&to=${maxDate}`)
        .then((res) => {
          this.newUsers = res.data.num;
        })
        .catch((error) => {
          console.error(error.message);
        });
    },

    renderBarChart() {
      const ctx = this.$refs.myBarChart.getContext("2d");

      const data = {
        labels: this.countries,
        datasets: [
          {
            label: "No. of Packages",
            backgroundColor: "#3F51B5",
            borderWidth: 1,
            data: this.countByCountry,
          },
        ],
      };

      if (!this.byCountryChart.chart)
        this.byCountryChart.chart = new Chart(ctx, {
          type: "bar",
          data: data,
          options: {
            maintainAspectRatio: false,
            scales: {
              y: {
                title: "Number of Packages",
                beginAtZero: true,
                ticks: {
                  stepSize: 5,
                },
              },
            },
          },
        });
      else {
        this.byCountryChart.chart.data = data;
        this.byCountryChart.chart.update();
      }
    },
    renderYearPackagesBarChart() {
      const ctx = this.$refs.yearPackagesBarChart.getContext("2d");

      const data = {
        labels: this.months,
        datasets: [
          {
            label: "No. of Packages",
            backgroundColor: "#4285F4",
            borderWidth: 1,
            data: this.packagesByMonthsCount,
          },
        ],
      };

      if (!this.byMonthChart.chart)
        this.byMonthChart.chart = new Chart(ctx, {
          type: "bar",
          data: data,
          options: {
            maintainAspectRatio: false,
            scales: {
              y: {
                title: "Number of Packages",
                beginAtZero: true,
                ticks: {
                  stepSize: 10,
                },
              },
            },
          },
        });
      else {
        this.byMonthChart.chart.data = data;
        this.byMonthChart.chart.update();
      }
    },
    renderDoughnutChart(companies, packagesCount) {
      const ctx = this.$refs.doughnutChart.getContext("2d");

      const data = {
        labels: companies,
        datasets: [
          {
            data: packagesCount,
            backgroundColor: [
              "rgba(255, 99, 132, 0.8)",
              "rgba(54, 162, 235, 0.8)",
            ],
            hoverOffset: 4,
          },
        ],
      };

      if (!this.byCompanyChart.chart)
        this.byCompanyChart.chart = new Chart(ctx, {
          type: "doughnut",
          data: data,
          options: {
            responsive: true,
            legend: {
              display: false,
            },
            borderSkipped: true,
          },
        });
      else {
        this.byCompanyChart.chart.data = data;
        this.byCompanyChart.chart.update();
      }
    },
  },
};
</script>

<style scoped>
.icon-frame {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}
.card-title {
  font-size: 16px;
  font-weight: 500;
  color: gray;
}
.card-stat {
  font-size: 32px;
  font-weight: bolder;
}
.chip-dropdown {
  cursor: pointer;
}
.date-range-input {
  border: 1px solid black;
  border-radius: 8px;
  padding: 1px 4px;
}
.trans-vol {
  font-weight: bold;
  font-size: 1.2em;
}
</style>
