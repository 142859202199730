<template>
  <div>
    <DashboardNavbar />

    <v-container>
      <v-dialog v-model="dialog" width="650">
        <template v-slot:activator="{ on }">
          <v-btn
            style="text-transform:none"
            v-on="on"
            color="indigo"
            class="white--text"
            >{{ $t("add") }}</v-btn
          >
        </template>

        <v-card class="pa-1">
          <v-card-text class="pa-3 blue--text" style="font-size:18px">
            {{ $t("addAddressText") }}
          </v-card-text>

          <v-divider></v-divider>

          <v-card-text>
            <v-form @submit.prevent="handleSubmit" ref="form">
              <v-text-field
                v-model="title"
                name="title"
                :label="$t('title')"
                id="title"
                type="text"
                color="indigo"
                :placeholder="$t('titlePlaceholder')"
                required
              ></v-text-field>

              <v-text-field
                v-model="firstName"
                name="name"
                :label="$t('firstName')"
                id="name"
                type="text"
                color="indigo"
                required
                :rules="[(v) => !!v || $i18n.t('codeRequired')]"
                @keyup="checkArabic"
              ></v-text-field>

              <v-text-field
                v-model="lastName"
                name="name"
                :label="$t('lastName')"
                id="name"
                type="text"
                color="indigo"
                required
                :rules="[(v) => !!v || $i18n.t('codeRequired')]"
                @keyup="checkArabic"
              ></v-text-field>

            <v-select
              :label="$t('country')"
              v-model="country"
              :items="countryList"
              :rules="[(v) => !!v || $i18n.t('codeRequired')]"
            ></v-select>

               <v-text-field
                v-model="nationalID"
                v-if="country == 'Algeria'"
                name="nationalID"
                :label="$t('nationalID')"
                id="nationalID"
                type="text"
                color="indigo"
                 :rules="[
                (text)=> text.length > 8 || $t('nationalIDValid')
              ]"
                required
                @keyup="checkArabic"
              ></v-text-field>

              <v-text-field
                v-model="addressFirst"
                name="addressFirst"
                :label="$t('addressFirst')"
                id="address1"
                type="text"
                color="indigo"
                required
                :rules="[(v) => !!v || $i18n.t('codeRequired')]"
                @keyup="checkArabic"
              ></v-text-field>

              <v-text-field
                v-model="addressSecond"
                name="addressFirst"
                :label="$t('addressSecond')"
                id="address2"
                type="text"
                color="indigo"
                required
                :rules="[(v) => !!v || $i18n.t('codeRequired')]"
                @keyup="checkArabic"
              ></v-text-field>

              <v-text-field
                v-model="city"
                name="city"
                :label="$t('city')"
                id="city"
                type="text"
                color="indigo"
                required
                :rules="[(v) => !!v || $i18n.t('codeRequired')]"
                @keyup="checkArabic"
              ></v-text-field>

              <v-text-field
                v-model="postal"
                name="postal"
                :label="$t('postal')"
                id="postal"
                type="text"
                color="indigo"
                required
                :rules="[(v) => !!v || $i18n.t('codeRequired')]"
                @keyup="checkArabic"
              ></v-text-field>

              <v-text-field
                v-model="phone"
                name="phone"
                :label="$t('phone')"
                id="phone"
                type="text"
                color="indigo"
                @keyup="checkNumber"
                required
                :rules="[(v) => !!v || $i18n.t('codeRequired')]"
              ></v-text-field>

              <v-spacer></v-spacer>

              <v-spacer></v-spacer>

              <div v-if="loading" class="d-flex justify-center">
                <v-progress-circular
                  :indeterminate="true"
                  size="40"
                  color="indigo"
                ></v-progress-circular>
              </div>

              <div v-else class="d-flex justify-center">
                <v-btn class="ma-2" type="submit" dark color="indigo">
                  {{ $t("save") }}
                </v-btn>
                <v-btn
                  @click="dialog = !dialog"
                  class="ma-2"
                  color="grey white--text"
                  >{{ $t("cancel") }}</v-btn
                >
              </div>
            </v-form>
          </v-card-text>

          <v-divider></v-divider>
        </v-card>
      </v-dialog>

      <v-row>
        <AddressCard
          v-for="address in userAddresses"
          :key="address.id"
          :address="address"
        />
      </v-row>

      <v-snackbar left :color="color" v-model="snackbar">{{
        message
      }}</v-snackbar>
    </v-container>
  </div>
</template>

<script>
import DashboardNavbar from "../components/DashboardNavbar";
import AddressCard from "../components/AddressCard";
import { countryList } from "../config/config";
import axios from "axios";
import auth from "../helpers/auth";

const newAxios = axios.create();
newAxios.defaults.headers.common = {};

const isArabic = /[\u0621-\u064A]+/g;

export default {
  name: "AddressBook",
  components: { DashboardNavbar, AddressCard },
  metaInfo: {
    title: 'Address Book | Porsay',
  },
  data() {
    return {
      dialog: false,
      firstName: "",
      lastName: "",
      title: "",
      addressFirst: "",
      addressSecond: "",
      phone: "",
      postal: "",
      country: "",
      city: "",
      countryList: countryList,
      userAddresses: [],
      loading: false,
      snackbar: false,
      message: "",
      color: "",
      countryMenu: false,
      nationalID:'',
      //eslint-disable-next-line
      phoneRe: /^\d+$/i,
    };
  },
  mounted() {
    if (this.$i18n.locale === "ar") {
      this.$vuetify.rtl = true;
    }

    newAxios
      .get("/api/users/addresses/get", {
        headers: {
          Authorization: `bearer ${auth.token()}`,
        },
      })
      .then((res) => {
        this.userAddresses = res.data.addresses;
        this.title = this.titleDefaults(this.userAddresses.length) 
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    handleSubmit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if(this.country == 'Algeria' && this.nationalID == ''){
        this.snackbar = true;
        this.message = this.$i18n.t("nationalIDRequired");
        this.color = 'error';
        return;
      }
      this.loading = true;

      this.$recaptcha("addAddress").then((token) => {
        //console.log(token) // Will print the token

        newAxios
          .post(
            "/api/users/addresses/add",
            {
              name: this.firstName + " " + this.lastName,
              title: this.title,
              country: this.country,
              city: this.city,
              zip: this.postal,
              addressLineOne: this.addressFirst,
              addressLineTwo: this.addressSecond,
              phone: this.phone,
              captcha: token,
              nationalID: this.country == 'Algeria' ? this.nationalID:''
            },
            {
              headers: {
                authorization: `bearer ${auth.token()}`,
              },
            }
          )
          .then((res) => {
            if (!res.data.error) {
              this.loading = false;
              this.message = this.$i18n.t("addressAddSuccessful");
              this.color = "success";
              this.snackbar = true;

              this.userAddresses.push(res.data.address);

              this.dialog = false;
              this.$refs.form.reset();
              this.title = this.titleDefaults(this.userAddresses.length)
            } else {
              this.loading = false;
              this.message = this.$i18n.t("errorMessage");
              this.color = "error";
              this.snackbar = true;
            }
          })
          .catch((err) => {
            console.log(err);

            this.loading = false;
            this.message = this.$i18n.t("errorMessage");
            this.color = "error";
            this.snackbar = true;
          });
      });
    },
    titleDefaults(cardinal) {
      const ordinals = [ 'First', 'Second', 'Third', 'Fourth', 'Fifth', 'Sixth',
      'Seventh', 'Eighth', 'Ninth', 'Tenth', 'Eleventh', 'Twelfth', 'Thirteenth', 'Fourteenth', 'Fifteenth'];
      if(cardinal >= ordinals.length) return "Address no. " + (cardinal + 1)
      return "My " + ordinals[cardinal] + " Address";
    },
    checkArabic(e) {
      if (isArabic.test(e.target.value)) {
        e.target.value = "";
      }
    },

    checkNumber(e) {
      if (!this.phoneRe.test(e.target.value)) {
        this[e.target.name] = "";
      }
    },

    filterCountries() {
      if (!this.country) {
        this.countryMenu = false;
        return;
      }

      this.countryMenu = true;
      let arr = [];

      countryList.forEach((item) => {
        if (item.toLowerCase().indexOf(this.country.toLowerCase()) >= 0) {
          arr.push(item);
        }
      });

      this.countryList = arr;
    },
    setCountry(item) {
      this.country = item;
      this.countryMenu = false;
    },
  },
};
</script>
