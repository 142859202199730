export const backendUrl = "https://backend.porsay.com";
// export const backendUrl = "http://localhost:8888";
// export const backendUrl = "https://backendtest.porsay.com";

export const countryList = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia (Plurinational State of)",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo (the Democratic Republic of the)",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czechia",
  "Côte d'Ivoire",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland Islands [Malvinas]",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran (Islamic Republic of)",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea (the Democratic People's Republic of)",
  "Korea (the Republic of)",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia (Federated States of)",
  "Moldova (the Republic of)",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine, State of",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic of North Macedonia",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Réunion",
  "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (French part)",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Dutch part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan (Province of China)",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom of Great Britain and Northern Ireland",
  "United States Minor Outlying Islands",
  "United States of America",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela (Bolivarian Republic of)",
  "Viet Nam",
  "Virgin Islands (British)",
  "Virgin Islands (U.S.)",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
  "Åland Islands",
];

export const codes = [
  "972",
  "93",
  "355",
  "213",
  "1684",
  "376",
  "244",
  "1264",
  "672",
  "1268",
  "54",
  "374",
  "297",
  "61",
  "43",
  "994",
  "1242",
  "973",
  "880",
  "1246",
  "375",
  "32",
  "501",
  "229",
  "1441",
  "975",
  "591",
  "387",
  "267",
  "55",
  "246",
  "673",
  "359",
  "226",
  "257",
  "855",
  "237",
  "238",
  "1345",
  "236",
  "235",
  "56",
  "86",
  "61",
  "61",
  "57",
  "269",
  "242",
  "243",
  "682",
  "506",
  "225",
  "385",
  "53",
  "357",
  "420",
  "45",
  "253",
  "1767",
  "18",
  "593",
  "20",
  "503",
  "240",
  "291",
  "372",
  "251",
  "500",
  "298",
  "679",
  "358",
  "33",
  "594",
  "689",
  "0",
  "241",
  "220",
  "995",
  "49",
  "233",
  "350",
  "30",
  "299",
  "1473",
  "590",
  "1671",
  "502",
  "224",
  "245",
  "592",
  "509",
  "0",
  "379",
  "504",
  "852",
  "36",
  "354",
  "91",
  "62",
  "98",
  "964",
  "353",
  "39",
  "1876",
  "81",
  "962",
  "254",
  "686",
  "850",
  "82",
  "965",
  "996",
  "856",
  "371",
  "961",
  "266",
  "231",
  "218",
  "423",
  "370",
  "352",
  "853",
  "389",
  "261",
  "265",
  "60",
  "960",
  "223",
  "356",
  "692",
  "596",
  "222",
  "230",
  "262",
  "52",
  "691",
  "373",
  "377",
  "976",
  "1664",
  "212",
  "258",
  "95",
  "264",
  "674",
  "977",
  "31",
  "599",
  "687",
  "64",
  "505",
  "227",
  "234",
  "683",
  "6723",
  "1670",
  "47",
  "968",
  "92",
  "680",
  "970",
  "507",
  "675",
  "595",
  "51",
  "63",
  "870",
  "48",
  "351",
  "1787",
  "974",
  "262",
  "40",
  "7",
  "250",
  "290",
  "1869",
  "1758",
  "508",
  "1784",
  "685",
  "378",
  "239",
  "966",
  "221",
  "381",
  "382",
  "248",
  "232",
  "65",
  "421",
  "386",
  "677",
  "252",
  "27",
  "0",
  "34",
  "94",
  "249",
  "597",
  "268",
  "46",
  "41",
  "963",
  "886",
  "992",
  "255",
  "66",
  "670",
  "228",
  "690",
  "676",
  "1868",
  "216",
  "90",
  "993",
  "1649",
  "688",
  "256",
  "380",
  "971",
  "44",
  "1",
  "598",
  "998",
  "678",
  "58",
  "84",
  "1284",
  "1340",
  "681",
  "967",
  "260",
  "263",
];

export const countries = [
  {
    name: "Afghanistan",
    iso_2: "AF",
    iso_3: "AFG",
    icon: "🇦🇫",
  },
  {
    name: "Albania",
    iso_2: "AL",
    iso_3: "ALB",
    icon: "🇦🇱",
  },
  {
    name: "Algeria",
    iso_2: "DZ",
    iso_3: "DZA",
    icon: "🇩🇿",
  },
  {
    name: "American Samoa",
    iso_2: "AS",
    iso_3: "ASM",
    icon: "🇦🇸",
  },
  {
    name: "Andorra",
    iso_2: "AD",
    iso_3: "AND",
    icon: "🇦🇩",
  },
  {
    name: "Angola",
    iso_2: "AO",
    iso_3: "AGO",
    icon: "🇦🇴",
  },
  {
    name: "Anguilla",
    iso_2: "AI",
    iso_3: "AIA",
    icon: "🇦🇮",
  },
  {
    name: "Antarctica",
    iso_2: "AQ",
    iso_3: "ATA",
    icon: "🇦🇶",
  },
  {
    name: "Antigua and Barbuda",
    iso_2: "AG",
    iso_3: "ATG",
    icon: "🇦🇬",
  },
  {
    name: "Argentina",
    iso_2: "AR",
    iso_3: "ARG",
    icon: "🇦🇷",
  },
  {
    name: "Armenia",
    iso_2: "AM",
    iso_3: "ARM",
    icon: "🇦🇲",
  },
  {
    name: "Aruba",
    iso_2: "AW",
    iso_3: "ABW",
    icon: "🇦🇼",
  },
  {
    name: "Australia",
    iso_2: "AU",
    iso_3: "AUS",
    icon: "🇦🇺",
  },
  {
    name: "Austria",
    iso_2: "AT",
    iso_3: "AUT",
    icon: "🇦🇹",
  },
  {
    name: "Azerbaijan",
    iso_2: "AZ",
    iso_3: "AZE",
    icon: "🇦🇿",
  },
  {
    name: "Bahamas",
    iso_2: "BS",
    iso_3: "BHS",
    icon: "🇧🇸",
  },
  {
    name: "Bahrain",
    iso_2: "BH",
    iso_3: "BHR",
    icon: "🇧🇭",
  },
  {
    name: "Bangladesh",
    iso_2: "BD",
    iso_3: "BGD",
    icon: "🇧🇩",
  },
  {
    name: "Barbados",
    iso_2: "BB",
    iso_3: "BRB",
    icon: "🇧🇧",
  },
  {
    name: "Belarus",
    iso_2: "BY",
    iso_3: "BLR",
    icon: "🇧🇾",
  },
  {
    name: "Belgium",
    iso_2: "BE",
    iso_3: "BEL",
    icon: "🇧🇪",
  },
  {
    name: "Belize",
    iso_2: "BZ",
    iso_3: "BLZ",
    icon: "🇧🇿",
  },
  {
    name: "Benin",
    iso_2: "BJ",
    iso_3: "BEN",
    icon: "🇧🇯",
  },
  {
    name: "Bermuda",
    iso_2: "BM",
    iso_3: "BMU",
    icon: "🇧🇲",
  },
  {
    name: "Bhutan",
    iso_2: "BT",
    iso_3: "BTN",
    icon: "🇧🇹",
  },
  {
    name: "Bolivia (Plurinational State of)",
    iso_2: "BO",
    iso_3: "BOL",
    icon: "🇧🇴",
  },
  {
    name: "Bonaire, Sint Eustatius and Saba",
    iso_2: "NL",
    iso_3: "NLD",
    icon: "🇧🇦",
  },
  {
    name: "Bosnia and Herzegovina",
    iso_2: "BA",
    iso_3: "BIH",
    icon: "🇧🇼",
  },
  {
    name: "Botswana",
    iso_2: "BW",
    iso_3: "BWA",
    icon: "🇧🇼",
  },
  {
    name: "Bouvet Island",
    iso_2: "BV",
    iso_3: "BVT",
    icon: "🇧🇻",
  },
  {
    name: "Brazil",
    iso_2: "BR",
    iso_3: "BRA",
    icon: "🇧🇷",
  },
  {
    name: "British Indian Ocean Territory",
    iso_2: "IO",
    iso_3: "IOT",
    icon: "🇮🇴",
  },
  {
    name: "Brunei Darussalam",
    iso_2: "BN",
    iso_3: "BRN",
    icon: "🇧🇬",
  },
  {
    name: "Bulgaria",
    iso_2: "BG",
    iso_3: "BGR",
    icon: "🇧🇬",
  },
  {
    name: "Burkina Faso",
    iso_2: "BF",
    iso_3: "BFA",
    icon: "🇧🇫",
  },
  {
    name: "Burundi",
    iso_2: "BI",
    iso_3: "BDI",
    icon: "🇧🇮",
  },
  {
    name: "Cabo Verde",
    iso_2: "CV",
    iso_3: "CPV",
    icon: "🇨🇻",
  },
  {
    name: "Cambodia",
    iso_2: "KH",
    iso_3: "KHM",
    icon: "🇰🇭",
  },
  {
    name: "Cameroon",
    iso_2: "CM",
    iso_3: "CMR",
    icon: "🇨🇲",
  },
  {
    name: "Canada",
    iso_2: "CA",
    iso_3: "CAN",
    icon: "🇨🇦",
  },
  {
    name: "Cayman Islands",
    iso_2: "KY",
    iso_3: "CYM",
    icon: "🇰🇾",
  },
  {
    name: "Central African Republic",
    iso_2: "CF",
    iso_3: "CAF",
    icon: "🇨🇫",
  },
  {
    name: "Chad",
    iso_2: "TD",
    iso_3: "TCD",
    icon: "🇹🇩",
  },
  {
    name: "Chile",
    iso_2: "CL",
    iso_3: "CHL",
    icon: "🇨🇱",
  },
  {
    name: "China",
    iso_2: "CN",
    iso_3: "CHN",
    icon: "🇨🇳",
  },
  {
    name: "Christmas Island",
    iso_2: "CX",
    iso_3: "CXR",
    icon: "🇨🇽",
  },
  {
    name: "Cocos (Keeling) Islands",
    iso_2: "CC",
    iso_3: "CCK",
    icon: "🇨🇨",
  },
  {
    name: "Colombia",
    iso_2: "CO",
    iso_3: "COL",
    icon: "🇨🇴",
  },
  {
    name: "Comoros",
    iso_2: "KM",
    iso_3: "COM",
    icon: "🇰🇲",
  },
  {
    name: "Congo (the Democratic Republic of the)",
    iso_2: "CD",
    iso_3: "COD",
    icon: "🇨🇩",
  },
  {
    name: "Congo [Republic]",
    iso_2: "CG",
    iso_3: "COG",
    icon: "🇨🇬",
  },
  {
    name: "Cook Islands",
    iso_2: "CK",
    iso_3: "COK",
    icon: "🇨🇰",
  },
  {
    name: "Costa Rica",
    iso_2: "CR",
    iso_3: "CRI",
    icon: "🇨🇷",
  },
  {
    name: "Croatia",
    iso_2: "HR",
    iso_3: "HRV",
    icon: "🇭🇷",
  },
  {
    name: "Cuba",
    iso_2: "CU",
    iso_3: "CUB",
    icon: "🇨🇺",
  },
  {
    name: "Curaçao",
    iso_2: "CUW",
    iso_3: "CUW",
    icon: "🇨🇼",
  },
  {
    name: "Cyprus",
    iso_2: "CY",
    iso_3: "CYP",
    icon: "🇨🇾",
  },
  {
    name: "Czechia",
    iso_2: "CZ",
    iso_3: "CZE",
    icon: "🇨🇿",
  },
  {
    name: "Côte d'Ivoire",
    iso_2: "CI",
    iso_3: "CIV",
    icon: "🇨🇮",
  },
  {
    name: "Denmark",
    iso_2: "DK",
    iso_3: "DNK",
    icon: "🇩🇰",
  },
  {
    name: "Djibouti",
    iso_2: "DJ",
    iso_3: "DJI",
    icon: "🇩🇯",
  },
  {
    name: "Dominica",
    iso_2: "DM",
    iso_3: "DMA",
    icon: "🇩🇲",
  },
  {
    name: "Dominican Republic",
    iso_2: "DO",
    iso_3: "DOM",
    icon: "🇩🇴",
  },
  {
    name: "Ecuador",
    iso_2: "EC",
    iso_3: "ECU",
    icon: "🇪🇨",
  },
  {
    name: "Egypt",
    iso_2: "EG",
    iso_3: "EGY",
    icon: "🇪🇬",
  },
  {
    name: "El Salvador",
    iso_2: "SV",
    iso_3: "SLV",
    icon: "🇸🇻",
  },
  {
    name: "Equatorial Guinea",
    iso_2: "GQ",
    iso_3: "GNQ",
    icon: "🇬🇶",
  },
  {
    name: "Eritrea",
    iso_2: "ER",
    iso_3: "ERI",
    icon: "🇪🇷",
  },
  {
    name: "Estonia",
    iso_2: "EE",
    iso_3: "EST",
    icon: "🇪🇪",
  },
  {
    name: "Eswatini",
    iso_2: "SWZ",
    iso_3: "SWZ",
    icon: "🇸🇿",
  },
  {
    name: "Ethiopia",
    iso_2: "ET",
    iso_3: "ETH",
    icon: "🇪🇹",
  },
  {
    name: "Falkland Islands [Malvinas]",
    iso_2: "FLK",
    iso_3: "FLK",
    icon: "🇫🇰",
  },
  {
    name: "Faroe Islands",
    iso_2: "FO",
    iso_3: "FRO",
    icon: "🇫🇴",
  },
  {
    name: "Fiji",
    iso_2: "FJ",
    iso_3: "FJI",
    icon: "🇫🇯",
  },
  {
    name: "Finland",
    iso_2: "FI",
    iso_3: "FIN",
    icon: "🇫🇮",
  },
  {
    name: "France",
    iso_2: "FR",
    iso_3: "FRA",
    icon: "🇫🇷",
  },
  {
    name: "French Guiana",
    iso_2: "GF",
    iso_3: "GUF",
    icon: "🇬🇫",
  },
  {
    name: "French Polynesia",
    iso_2: "PF",
    iso_3: "PYF",
    icon: "🇵🇫",
  },
  {
    name: "French Southern Territories",
    iso_2: "TF",
    iso_3: "ATF",
    icon: "🇹🇫",
  },
  {
    name: "Gabon",
    iso_2: "GA",
    iso_3: "GAB",
    icon: "🇬🇦",
  },
  {
    name: "Gambia",
    iso_2: "GM",
    iso_3: "GMB",
    icon: "🇬🇲",
  },
  {
    name: "Georgia",
    iso_2: "GE",
    iso_3: "GEO",
    icon: "🇬🇪",
  },
  {
    name: "Germany",
    iso_2: "DE",
    iso_3: "DEU",
    icon: "🇩🇪",
  },
  {
    name: "Ghana",
    iso_2: "GH",
    iso_3: "GHA",
    icon: "🇬🇭",
  },
  {
    name: "Gibraltar",
    iso_2: "GI",
    iso_3: "GIB",
    icon: "🇬🇮",
  },
  {
    name: "Greece",
    iso_2: "GR",
    iso_3: "GRC",
    icon: "🇬🇷",
  },
  {
    name: "Greenland",
    iso_2: "GL",
    iso_3: "GRL",
    icon: "🇬🇱",
  },
  {
    name: "Grenada",
    iso_2: "GD",
    iso_3: "GRD",
    icon: "🇬🇩",
  },
  {
    name: "Guadeloupe",
    iso_2: "GP",
    iso_3: "GLP",
    icon: "🇬🇵",
  },
  {
    name: "Guam",
    iso_2: "GU",
    iso_3: "GUM",
    icon: "🇬🇺",
  },
  {
    name: "Guatemala",
    iso_2: "GT",
    iso_3: "GTM",
    icon: "🇬🇹",
  },
  {
    name: "Guernsey",
    iso_2: "GG",
    iso_3: "GGY",
    icon: "🇬🇬",
  },
  {
    name: "Guinea",
    iso_2: "GN",
    iso_3: "GIN",
    icon: "🇬🇳",
  },
  {
    name: "Guinea-Bissau",
    iso_2: "GW",
    iso_3: "GNB",
    icon: "🇬🇼",
  },
  {
    name: "Guyana",
    iso_2: "GY",
    iso_3: "GUY",
    icon: "🇬🇾",
  },
  {
    name: "Haiti",
    iso_2: "HT",
    iso_3: "HTI",
    icon: "🇭🇹",
  },
  {
    name: "Heard Island and McDonald Islands",
    iso_2: "HM",
    iso_3: "HMD",
    icon: "🇭🇲",
  },
  {
    name: "Holy See",
    iso_2: "VA",
    iso_3: "VAT",
    icon: "🇻🇦",
  },
  {
    name: "Honduras",
    iso_2: "HN",
    iso_3: "HND",
    icon: "🇭🇳",
  },
  {
    name: "Hong Kong",
    iso_2: "HK",
    iso_3: "HKG",
    icon: "🇭🇰",
  },
  {
    name: "Hungary",
    iso_2: "HU",
    iso_3: "HUN",
    icon: "🇭🇺",
  },
  {
    name: "Iceland",
    iso_2: "IS",
    iso_3: "ISL",
    icon: "🇮🇸",
  },
  {
    name: "India",
    iso_2: "IN",
    iso_3: "IND",
    icon: "🇮🇳",
  },
  {
    name: "Indonesia",
    iso_2: "ID",
    iso_3: "IDN",
    icon: "🇮🇩",
  },
  {
    name: "Iran (Islamic Republic of)",
    iso_2: "IR",
    iso_3: "IRN",
    icon: "🇮🇷",
  },
  {
    name: "Iraq",
    iso_2: "IQ",
    iso_3: "IRQ",
    icon: "🇮🇶",
  },
  {
    name: "Ireland",
    iso_2: "IE",
    iso_3: "IRL",
    icon: "🇮🇪",
  },
  {
    name: "Isle of Man",
    iso_2: "IM",
    iso_3: "IMN",
    icon: "🇮🇲",
  },
  {
    name: "Israel",
    iso_2: "IL",
    iso_3: "ISR",
    icon: "🇮🇱",
  },
  {
    name: "Italy",
    iso_2: "IT",
    iso_3: "ITA",
    icon: "🇮🇹",
  },
  {
    name: "Jamaica",
    iso_2: "JM",
    iso_3: "JAM",
    icon: "🇯🇲",
  },
  {
    name: "Japan",
    iso_2: "JP",
    iso_3: "JPN",
    icon: "🇯🇵",
  },
  {
    name: "Jersey",
    iso_2: "JE",
    iso_3: "JEY",
    icon: "🇯🇪",
  },
  {
    name: "Jordan",
    iso_2: "JO",
    iso_3: "JOR",
    icon: "🇯🇴",
  },
  {
    name: "Kazakhstan",
    iso_2: "KZ",
    iso_3: "KAZ",
    icon: "🇰🇿",
  },
  {
    name: "Kenya",
    iso_2: "KE",
    iso_3: "KEN",
    icon: "🇰🇪",
  },
  {
    name: "Kiribati",
    iso_2: "KI",
    iso_3: "KIR",
    icon: "🇰🇮",
  },
  {
    name: "Korea (the Democratic People's Republic of)",
    iso_2: "KP",
    iso_3: "PRK",
    icon: "🇰🇵",
  },
  {
    name: "Korea (the Republic of)",
    iso_2: "KR",
    iso_3: "KOR",
    icon: "🇰🇷",
  },
  {
    name: "Kuwait",
    iso_2: "KW",
    iso_3: "KWT",
    icon: "🇰🇼",
  },
  {
    name: "Kyrgyzstan",
    iso_2: "KG",
    iso_3: "KGZ",
    icon: "🇰🇬",
  },
  {
    name: "Laos",
    iso_2: "LA",
    iso_3: "LAO",
    icon: "🇱🇦",
  },
  {
    name: "Latvia",
    iso_2: "LV",
    iso_3: "LVA",
    icon: "🇱🇻",
  },
  {
    name: "Lebanon",
    iso_2: "LB",
    iso_3: "LBN",
    icon: "🇱🇧",
  },
  {
    name: "Lesotho",
    iso_2: "LS",
    iso_3: "LSO",
    icon: "🇱🇸",
  },
  {
    name: "Liberia",
    iso_2: "LR",
    iso_3: "LBR",
    icon: "🇱🇷",
  },
  {
    name: "Libya",
    iso_2: "LY",
    iso_3: "LBY",
    icon: "🇱🇾",
  },
  {
    name: "Liechtenstein",
    iso_2: "LI",
    iso_3: "LIE",
    icon: "🇱🇮",
  },
  {
    name: "Lithuania",
    iso_2: "LT",
    iso_3: "LTU",
    icon: "🇱🇹",
  },
  {
    name: "Luxembourg",
    iso_2: "LU",
    iso_3: "LUX",
    icon: "🇱🇺",
  },
  {
    name: "Macao",
    iso_2: "MAC",
    iso_3: "MAC",
    icon: "🇲🇴",
  },
  {
    name: "Madagascar",
    iso_2: "MG",
    iso_3: "MDG",
    icon: "🇲🇬",
  },
  {
    name: "Malawi",
    iso_2: "MW",
    iso_3: "MWI",
    icon: "🇲🇼",
  },
  {
    name: "Malaysia",
    iso_2: "MY",
    iso_3: "MYS",
    icon: "🇲🇾",
  },
  {
    name: "Maldives",
    iso_2: "MV",
    iso_3: "MDV",
    icon: "🇲🇻",
  },
  {
    name: "Mali",
    iso_2: "ML",
    iso_3: "MLI",
    icon: "🇲🇱",
  },
  {
    name: "Malta",
    iso_2: "MT",
    iso_3: "MLT",
    icon: "🇲🇹",
  },
  {
    name: "Marshall Islands",
    iso_2: "MH",
    iso_3: "MHL",
    icon: "🇲🇭",
  },
  {
    name: "Martinique",
    iso_2: "MQ",
    iso_3: "MTQ",
    icon: "🇲🇶",
  },
  {
    name: "Mauritania",
    iso_2: "MR",
    iso_3: "MRT",
    icon: "🇲🇷",
  },
  {
    name: "Mauritius",
    iso_2: "MU",
    iso_3: "MUS",
    icon: "🇲🇺",
  },
  {
    name: "Mayotte",
    iso_2: "YT",
    iso_3: "MYT",
    icon: "🇾🇹",
  },
  {
    name: "Mexico",
    iso_2: "MX",
    iso_3: "MEX",
    icon: "🇲🇽",
  },
  {
    name: "Micronesia (Federated States of)",
    iso_2: "FM",
    iso_3: "FSM",
    icon: "🇫🇲",
  },
  {
    name: "Moldova (the Republic of)",
    iso_2: "MD",
    iso_3: "MDA",
    icon: "🇲🇩",
  },
  {
    name: "Monaco",
    iso_2: "MC",
    iso_3: "MCO",
    icon: "🇲🇨",
  },
  {
    name: "Mongolia",
    iso_2: "MN",
    iso_3: "MNG",
    icon: "🇲🇳",
  },
  {
    name: "Montenegro",
    iso_2: "ME",
    iso_3: "MNE",
    icon: "🇲🇪",
  },
  {
    name: "Montserrat",
    iso_2: "MS",
    iso_3: "MSR",
    icon: "🇲🇸",
  },
  {
    name: "Morocco",
    iso_2: "MA",
    iso_3: "MAR",
    icon: "🇲🇦",
  },
  {
    name: "Mozambique",
    iso_2: "MZ",
    iso_3: "MOZ",
    icon: "🇲🇿",
  },
  {
    name: "Myanmar",
    iso_2: "MM",
    iso_3: "MMR",
    icon: "🇲🇲",
  },
  {
    name: "Namibia",
    iso_2: "NA",
    iso_3: "NAM",
    icon: "🇳🇦",
  },
  {
    name: "Nauru",
    iso_2: "NR",
    iso_3: "NRU",
    icon: "🇳🇷",
  },
  {
    name: "Nepal",
    iso_2: "NP",
    iso_3: "NPL",
    icon: "🇳🇵",
  },
  {
    name: "Netherlands",
    iso_2: "NL",
    iso_3: "NLD",
    icon: "🇳🇱",
  },
  {
    name: "New Caledonia",
    iso_2: "NC",
    iso_3: "NCL",
    icon: "🇳🇨",
  },
  {
    name: "New Zealand",
    iso_2: "NZ",
    iso_3: "NZL",
    icon: "🇳🇿",
  },
  {
    name: "Nicaragua",
    iso_2: "NI",
    iso_3: "NIC",
    icon: "🇳🇮",
  },
  {
    name: "Niger",
    iso_2: "NE",
    iso_3: "NER",
    icon: "🇳🇪",
  },
  {
    name: "Nigeria",
    iso_2: "NG",
    iso_3: "NGA",
    icon: "🇳🇬",
  },
  {
    name: "Niue",
    iso_2: "NU",
    iso_3: "NIU",
    icon: "🇳🇺",
  },
  {
    name: "Norfolk Island",
    iso_2: "NF",
    iso_3: "NFK",
    icon: "🇳🇫",
  },
  {
    name: "Northern Mariana Islands",
    iso_2: "MP",
    iso_3: "MNP",
    icon: "🇲🇵",
  },
  {
    name: "Norway",
    iso_2: "NO",
    iso_3: "NOR",
    icon: "🇳🇴",
  },
  {
    name: "Oman",
    iso_2: "OM",
    iso_3: "OMN",
    icon: "🇴🇲",
  },
  {
    name: "Pakistan",
    iso_2: "PK",
    iso_3: "PAK",
    icon: "🇵🇰",
  },
  {
    name: "Palau",
    iso_2: "PW",
    iso_3: "PLW",
    icon: "🇵🇼",
  },
  {
    name: "Palestinian Territories",
    iso_2: "PS",
    iso_3: "PSE",
    icon: "🇵🇸",
  },
  {
    name: "Panama",
    iso_2: "PA",
    iso_3: "PAN",
    icon: "🇵🇦",
  },
  {
    name: "Papua New Guinea",
    iso_2: "PG",
    iso_3: "PNG",
    icon: "🇵🇬",
  },
  {
    name: "Paraguay",
    iso_2: "PY",
    iso_3: "PRY",
    icon: "🇵🇾",
  },
  {
    name: "Peru",
    iso_2: "PE",
    iso_3: "PER",
    icon: "🇵🇪",
  },
  {
    name: "Philippines",
    iso_2: "PH",
    iso_3: "PHL",
    icon: "🇵🇭",
  },
  {
    name: "Pitcairn",
    iso_2: "PCN",
    iso_3: "PCN",
    icon: "🇵🇳",
  },
  {
    name: "Poland",
    iso_2: "PL",
    iso_3: "POL",
    icon: "🇵🇱",
  },
  {
    name: "Portugal",
    iso_2: "PT",
    iso_3: "PRT",
    icon: "🇵🇹",
  },
  {
    name: "Puerto Rico",
    iso_2: "PR",
    iso_3: "PRI",
    icon: "🇵🇷",
  },
  {
    name: "Qatar",
    iso_2: "QA",
    iso_3: "QAT",
    icon: "🇶🇦",
  },
  {
    name: "Republic of North Macedonia",
    iso_2: "MK",
    iso_3: "MKD",
    icon: "🇲🇰",
  },
  {
    name: "Romania",
    iso_2: "RO",
    iso_3: "ROU",
    icon: "🇷🇴",
  },
  {
    name: "Russian Federation",
    iso_2: "RU",
    iso_3: "RUS",
    icon: "🇷🇺",
  },
  {
    name: "Rwanda",
    iso_2: "RW",
    iso_3: "RWA",
    icon: "🇷🇼",
  },
  {
    name: "Réunion",
    iso_2: "RE",
    iso_3: "REU",
    icon: "🇷🇪",
  },
  {
    name: "Saint Barthélemy",
    iso_2: "BL",
    iso_3: "BLM",
    icon: "🇧🇱",
  },
  {
    name: "Saint Helena, Ascension and Tristan da Cunha",
    iso_2: "SHN",
    iso_3: "SHN",
    icon: "🇸🇭",
  },
  {
    name: "Saint Kitts and Nevis",
    iso_2: "KN",
    iso_3: "KNA",
    icon: "🇰🇳",
  },
  {
    name: "Saint Lucia",
    iso_2: "LC",
    iso_3: "LCA",
    icon: "🇱🇨",
  },
  {
    name: "Saint Martin (French part)",
    iso_2: "MAF",
    iso_3: "MAF",
    icon: "🇲🇫",
  },
  {
    name: "Saint Pierre and Miquelon",
    iso_2: "PM",
    iso_3: "SPM",
    icon: "🇵🇲",
  },
  {
    name: "Saint Vincent and the Grenadines",
    iso_2: "VC",
    iso_3: "VCT",
    icon: "🇻🇨",
  },
  {
    name: "Samoa",
    iso_2: "WS",
    iso_3: "WSM",
    icon: "🇼🇸",
  },
  {
    name: "San Marino",
    iso_2: "SM",
    iso_3: "SMR",
    icon: "🇸🇲",
  },
  {
    name: "Sao Tome and Principe",
    iso_2: "STP",
    iso_3: "STP",
    icon: "🇸🇹",
  },
  {
    name: "Saudi Arabia",
    iso_2: "SA",
    iso_3: "SAU",
    icon: "🇸🇦",
  },
  {
    name: "Senegal",
    iso_2: "SN",
    iso_3: "SEN",
    icon: "🇸🇳",
  },
  {
    name: "Serbia",
    iso_2: "RS",
    iso_3: "SRB",
    icon: "🇷🇸",
  },
  {
    name: "Seychelles",
    iso_2: "SC",
    iso_3: "SYC",
    icon: "🇸🇨",
  },
  {
    name: "Sierra Leone",
    iso_2: "SL",
    iso_3: "SLE",
    icon: "🇸🇱",
  },
  {
    name: "Singapore",
    iso_2: "SG",
    iso_3: "SGP",
    icon: "🇸🇬",
  },
  {
    name: "Sint Maarten (Dutch part)",
    iso_2: "SXM",
    iso_3: "SXM",
    icon: "🇸🇽",
  },
  {
    name: "Slovakia",
    iso_2: "SK",
    iso_3: "SVK",
    icon: "🇸🇰",
  },
  {
    name: "Slovenia",
    iso_2: "SI",
    iso_3: "SVN",
    icon: "🇸🇮",
  },
  {
    name: "Solomon Islands",
    iso_2: "SB",
    iso_3: "SLB",
    icon: "🇸🇧",
  },
  {
    name: "Somalia",
    iso_2: "SO",
    iso_3: "SOM",
    icon: "🇸🇴",
  },
  {
    name: "South Africa",
    iso_2: "ZA",
    iso_3: "ZAF",
    icon: "🇿🇦",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    iso_2: "GS",
    iso_3: "SGS",
    icon: "🇬🇸",
  },
  {
    name: "South Sudan",
    iso_2: "SS",
    iso_3: "SSD",
    icon: "🇸🇸",
  },
  {
    name: "Spain",
    iso_2: "ES",
    iso_3: "ESP",
    icon: "🇪🇸",
  },
  {
    name: "Sri Lanka",
    iso_2: "LK",
    iso_3: "LKA",
    icon: "🇱🇰",
  },
  {
    name: "Sudan",
    iso_2: "SD",
    iso_3: "SDN",
    icon: "🇸🇩",
  },
  {
    name: "Suriname",
    iso_2: "SR",
    iso_3: "SUR",
    icon: "🇸🇷",
  },
  {
    name: "Svalbard and Jan Mayen",
    iso_2: "SJ",
    iso_3: "SJM",
    icon: "🇸🇯",
  },
  {
    name: "Sweden",
    iso_2: "SE",
    iso_3: "SWE",
    icon: "🇸🇪",
  },
  {
    name: "Switzerland",
    iso_2: "CH",
    iso_3: "CHE",
    icon: "🇨🇭",
  },
  {
    name: "Syrian Arab Republic",
    iso_2: "SY",
    iso_3: "SYR",
    icon: "🇸🇾",
  },
  {
    name: "Taiwan",
    iso_2: "TW",
    iso_3: "TWN",
    icon: "🇹🇼",
  },
  {
    name: "Tajikistan",
    iso_2: "TJ",
    iso_3: "TJK",
    icon: "🇹🇯",
  },
  {
    name: "Tanzania",
    iso_2: "TZ",
    iso_3: "TZA",
    icon: "🇹🇿",
  },
  {
    name: "Thailand",
    iso_2: "TH",
    iso_3: "THA",
    icon: "🇹🇭",
  },
  {
    name: "Timor-Leste",
    iso_2: "TL",
    iso_3: "TLS",
    icon: "🇹🇱",
  },
  {
    name: "Togo",
    iso_2: "TG",
    iso_3: "TGO",
    icon: "🇹🇬",
  },
  {
    name: "Tokelau",
    iso_2: "TK",
    iso_3: "TKL",
    icon: "🇹🇰",
  },
  {
    name: "Tonga",
    iso_2: "TO",
    iso_3: "TON",
    icon: "🇹🇴",
  },
  {
    name: "Trinidad and Tobago",
    iso_2: "TT",
    iso_3: "TTO",
    icon: "🇹🇹",
  },
  {
    name: "Tunisia",
    iso_2: "TN",
    iso_3: "TUN",
    icon: "🇹🇳",
  },
  {
    name: "Turkey",
    iso_2: "TR",
    iso_3: "TUR",
    icon: "🇹🇷",
  },
  {
    name: "Turkmenistan",
    iso_2: "TM",
    iso_3: "TKM",
    icon: "🇹🇲",
  },
  {
    name: "Turks and Caicos Islands",
    iso_2: "TC",
    iso_3: "TCA",
    icon: "🇹🇨",
  },
  {
    name: "Tuvalu",
    iso_2: "TV",
    iso_3: "TUV",
    icon: "🇹🇻",
  },
  {
    name: "Uganda",
    iso_2: "UG",
    iso_3: "UGA",
    icon: "🇺🇬",
  },
  {
    name: "Ukraine",
    iso_2: "UA",
    iso_3: "UKR",
    icon: "🇺🇦",
  },
  {
    name: "United Arab Emirates",
    iso_2: "AE",
    iso_3: "ARE",
    icon: "🇦🇪",
  },
  {
    name: "United Kingdom of Great Britain and Northern Ireland",
    iso_2: "GB",
    iso_3: "GBR",
    icon: "🇬🇧",
  },
  {
    name: "United States Minor Outlying Islands",
    iso_2: "UM",
    iso_3: "UMI",
    icon: "🇺🇲",
  },
  {
    name: "United States of America",
    iso_2: "US",
    iso_3: "USA",
    icon: "🇺🇸",
  },
  {
    name: "Uruguay",
    iso_2: "UY",
    iso_3: "URY",
    icon: "🇺🇾",
  },
  {
    name: "Uzbekistan",
    iso_2: "UZ",
    iso_3: "UZB",
    icon: "🇺🇿",
  },
  {
    name: "Vanuatu",
    iso_2: "VU",
    iso_3: "VUT",
    icon: "🇻🇺",
  },
  {
    name: "Venezuela",
    iso_2: "VE",
    iso_3: "VEN",
    icon: "🇻🇪",
  },
  {
    name: "Viet Nam",
    iso_2: "VN",
    iso_3: "VNM",
    icon: "🇻🇳",
  },
  {
    name: "Virgin Islands (British)",
    iso_2: "VG",
    iso_3: "VGB",
    icon: "🇻🇬",
  },
  {
    name: "Virgin Islands (U.S.)",
    iso_2: "VIR",
    iso_3: "VIR",
    icon: "🇻🇮",
  },
  {
    name: "Wallis and Futuna",
    iso_2: "WF",
    iso_3: "WLF",
    icon: "🇼🇫",
  },
  {
    name: "Western Sahara",
    iso_2: "EH",
    iso_3: "ESH",
    icon: "🇪🇭",
  },
  {
    name: "Yemen",
    iso_2: "YE",
    iso_3: "YEM",
    icon: "🇾🇪",
  },
  {
    name: "Zambia",
    iso_2: "ZM",
    iso_3: "ZMB",
    icon: "🇿🇲",
  },
  {
    name: "Zimbabwe",
    iso_2: "ZW",
    iso_3: "ZWE",
    icon: "🇿🇼",
  },
  {
    name: "Åland Islands",
    iso_2: "AX",
    iso_3: "ALA",
    icon: "🇦🇽",
  },
];

export const item_description = [
  {
    article: "Clothing",
    subcategories: [
      "Jeans for women", "Jeans for girl", "Scarf", "Dress", "Pants", "Tshirt", "Polo", "Sweet Shirt", "Vest", "Shorts",
      "Jeans for kids", "Socks", "Blouse", "Tunic", "Jacket", "Coat", "Trousers"
    ],
    code_sh: "61113090"
  },
  {
    article: "Computer and Electronic Accessories",
    subcategories: [
      "Computer paddle", "Keyboard", "Mouse", "Charger"
    ],
    code_sh: "84733080"
  },
  {
    article: "Smartphone",
    code_sh: "85171300"
  },
  {
    article: "Laptop",
    code_sh: "84713010"
  },
  {
    article: "Make Up",
    subcategories: [
      "Lipstick", "Concealer", "Eyeshadow", "Highlighter", "Mascara", "Gel Eye Liner", "Foundation",
      "Color Pencil", "Liquid Lipstick"
    ],
    code_sh: "33042000"
  },
  {
    article: "Cosmetics",
    subcategories: [
      "Body Cream", "Shampoo", "Shower Gel","Hand Cream", "Face Mask", "Body Scrubb", "Face Serum",
      "Hair Oil", "Hair Serum"
    ],
    code_sh: "33042000"
  },
  {
    article: "Shoes",
    subcategories: [
      "Men Shoes", "Women Shoes", "Leather Shoes", "Kids Shoes", "Baskets", "Ballet Flats", "Boots", "Sandals"
    ],
    code_sh: "64051000"
  },
  {
    article: "Bags and Belts",
    subcategories: [
      "Belt", "Leather Belt", "Hand Bag", "Pouch"
    ],
    code_sh: "42022210"
  },
  {
    article: "Jewelry and Accessories",
    subcategories: [
      "Necklace", "Bracelet", "Ring"
    ],
    code_sh: "71179000"
  },
  {
    article: "Food and Beverage",
    subcategories: [
      "Chocolate", "Candys", "Cookies", "Dry Cakes", "Jam"
    ],
    code_sh: "84223000"
  },
  {
    article: "Hair Tools",
    subcategories: [
      "Hair Shaver", "Hair Straightener", "Hair Curler", "Hair Dryer"
    ],
    code_sh: "84193900"
  },
  {
    article: "Spices",
    subcategories: [
      "Herbal Tea", "Coffee"
    ],
    code_sh: "09012100"
  },
  {
    article: "Watches and Clocks",
    code_sh: "91112000"
  },
  {
    article: "Audio Peripherals",
    subcategories: [
      "Earphones", "Headset"
    ],
    code_sh: "85183000"
  },
  {
    article: "House Decoration",
    subcategories: [
      "Sheet", "Comforter", "Blanket", "Fitted Sheet", "Pillowcase", "Pillow", "Mattress",
      "Carpet", "Curtain", "Curtain Cover"
    ],
    code_sh: "63023100"
  },
  {
    article: "Vitamin and Dietary Supplement",
    code_sh: "29362900"
  },
  {
    article: "Medicines",
    code_sh: "30039000"
  },
  {
    article: "Nuts",
    subcategories: [
      "Almond", "Peanut", "Nut", "Hazelnut", "Jam"
    ],
    code_sh: "08021290"
  },
]