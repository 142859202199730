<template>
  <div>
    <v-toolbar class="indigo">
      <v-toolbar-title>
        <a href="https://www.porsay.com"
          ><v-img src="../assets/logo-white.png" width="140"
        /></a>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn v-if="loggedIn" :href="'/' + $i18n.locale + '/dashboard'" text class="nav-link">
          <v-icon left size="15">fa-solid fa-door-closed</v-icon
          >{{ $t("locker") }}
        </v-btn>

        <v-btn href="https://porsay.com/stores/" text class="nav-link" target="_blank">
          <v-icon left size="15">fa-solid fa-shopping-cart</v-icon
          >{{ $t("shop") }}
        </v-btn>

        <!-- <v-btn text href="https://porsay.com/feature/" class="nav-link">
          <v-icon left size="15">fas fa-money-bill-wave</v-icon
          >{{ $t("pricing") }}
        </v-btn> -->
        
        <v-btn
          text
          :to="'/' + this.$i18n.locale + '/calculator'"
          class="nav-link"
          target="_blank"

        >
          <v-icon left size="15">fas fa-calculator</v-icon
          >{{ $t("pricingCalc") }}
        </v-btn>
        <!-- <v-btn text class="nav-link">
          <v-icon left size="15">fas fa-heart</v-icon>{{$t('review')}}
        </v-btn>
        <v-btn text class="nav-link">
          <v-icon left size="15">fas fa-question-circle</v-icon>{{$t('about')}}
        </v-btn> -->
        <v-btn
          v-if="!loggedIn"
          :to="`/${this.$i18n.locale}/login`"
          text
          class="nav-link"
        >
          <v-icon left size="15">fas fa-sign-in-alt</v-icon>{{ $t("login") }}
        </v-btn>

        <v-btn
          v-if="!loggedIn"
          :to="`/${this.$i18n.locale}/signup`"
          text
          class="nav-link"
        >
          <v-icon left size="15">fas fa-plus-circle</v-icon>{{ $t("signup") }}
        </v-btn>

        <div text class="nav-link">
          <v-list-item-content>
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn text color="white" v-on="on">
                  <v-icon
                    size="20"
                    class="mx-3"
                    :right="$route.params.lang == 'ar' ? true : false"
                    :left="$route.params.lang != 'ar' ? true : false"
                    >fas fa-language</v-icon
                  >
                  {{ $i18n.locale.toUpperCase() }}
                </v-btn>
              </template>
              <v-list>
                    <v-list-item
                      @click="setLang('en')"
                      :href="$route.path.split('/')[2]?'/en/' + $route.path.split('/')[2]: '/en/dashboard'"
                    >
                      <v-list-item-title>EN</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="setLang('fr')"
                      :href="$route.path.split('/')[2]?'/fr/' + $route.path.split('/')[2]: '/fr/dashboard'"
                    >
                      <v-list-item-title>FR</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="setLang('ar')"
                      :href="$route.path.split('/')[2]?'/ar/' + $route.path.split('/')[2]: '/ar/dashboard'"
                    >
                      <v-list-item-title>AR</v-list-item-title>
                    </v-list-item>
                  </v-list>
            </v-menu>
          </v-list-item-content>
        </div>
      </v-toolbar-items>

      <v-toolbar-items class="hidden-md-and-up">
        <v-btn text @click="drawerMenu = !drawerMenu"
          ><v-icon color="white" size="25">fas fa-bars</v-icon></v-btn
        >
      </v-toolbar-items>
    </v-toolbar>

    <v-navigation-drawer
      v-model="drawerMenu"
      temporary
      app
      hide-overlay
      :right="this.$i18n.locale == 'ar' ? false : true"
      :left="this.$i18n.locale != 'ar' ? false : true"
      width="300"
      id="drawer"
    >
      <v-list nav>
        <v-list-item v-if="loggedIn" href="/en/dashboard" text class="nav-link">
          <v-icon
            size="20"
            :right="this.$i18n.locale == 'ar' ? true : false"
            :left="this.$i18n.locale != 'ar' ? true : false"
            >fas fa-door-closed</v-icon
          >{{ $t("locker") }}
        </v-list-item>

        <v-list-item href="https://porsay.com/stores/" target="_blank">
          <div>
            <v-icon
              size="20"
              :right="this.$i18n.locale == 'ar' ? true : false"
              :left="this.$i18n.locale != 'ar' ? true : false"
              >fas fa-shopping-cart</v-icon
            >
          </div>

          <v-list-item-content>
            <v-list-item-title class="nav-title">{{
              $t("shop")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item href="https://porsay.com/feature/">
          <div>
            <v-icon
              size="20"
              :right="this.$i18n.locale == 'ar' ? true : false"
              :left="this.$i18n.locale != 'ar' ? true : false"
              >fas fa-money-bill-wave</v-icon
            >
          </div>

          <v-list-item-content>
            <v-list-item-title class="nav-title">{{
              $t("pricing")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- <v-list-item >

       <div>
            <v-icon size="20" :right="(this.$i18n.locale =='ar')? true: false" 
            :left="(this.$i18n.locale != 'ar')? true : false" >fas fa-heart</v-icon>
          </div>

          <v-list-item-content>
            <v-list-item-title class="nav-title">{{$t('review')}}</v-list-item-title>
          </v-list-item-content>

      </v-list-item>

      
      <v-list-item  >

       <div>
            <v-icon size="20" :right="(this.$i18n.locale =='ar')? true: false" 
            :left="(this.$i18n.locale != 'ar')? true : false" >fas fa-question-circle</v-icon>
          </div>

          <v-list-item-content>
            <v-list-item-title class="nav-title">{{$t('about')}}</v-list-item-title>
          </v-list-item-content>

      </v-list-item> -->

        <v-list-item v-if="!loggedIn" :to="'/' + this.$i18n.locale + '/login'">
          <div>
            <v-icon
              size="20"
              :right="this.$i18n.locale == 'ar' ? true : false"
              :left="this.$i18n.locale != 'ar' ? true : false"
              >fas fa-sign-in-alt</v-icon
            >
          </div>

          <v-list-item-content>
            <v-list-item-title class="nav-title">{{
              $t("login")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="!loggedIn" :to="'/' + this.$i18n.locale + '/signup'">
          <div>
            <v-icon
              size="20"
              :right="this.$i18n.locale == 'ar' ? true : false"
              :left="this.$i18n.locale != 'ar' ? true : false"
              >fas fa-plus-circle</v-icon
            >
          </div>

          <v-list-item-content>
            <v-list-item-title class="nav-title">{{
              $t("signup")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item color="white">
          <div>
            <v-icon
              size="20"
              :right="this.$i18n.locale == 'ar' ? true : false"
              :left="this.$i18n.locale != 'ar' ? true : false"
              >fas fa-language</v-icon
            >
          </div>

          <v-list-item-content>
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-list-item-title
                  style="cursor:pointer"
                  class="navbar-title"
                  v-on="on"
                  text
                >
                  {{ $i18n.locale.toUpperCase() }}
                </v-list-item-title>
              </template>
              <v-list>
                    <v-list-item
                      @click="setLang('en')"
                      :href="$route.path.split('/')[2]?'/en/' + $route.path.split('/')[2]: '/en/dashboard'"
                    >
                      <v-list-item-title>EN</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="setLang('fr')"
                      :href="$route.path.split('/')[2]?'/fr/' + $route.path.split('/')[2]: '/fr/dashboard'"
                    >
                      <v-list-item-title>FR</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="setLang('ar')"
                      :href="$route.path.split('/')[2]?'/ar/' + $route.path.split('/')[2]: '/ar/dashboard'"
                    >
                      <v-list-item-title>AR</v-list-item-title>
                    </v-list-item>
                  </v-list>
            </v-menu>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import auth from "../helpers/auth";
export default {
  name: "Navbar",

  mounted(){
    this.loggedIn = auth.authenticated()
  },

   data() {
    return {
      drawerMenu: false,
      incomePackages: [],
      loggedIn:  false,
    };
  },
  methods: {
    setLang(item) {
      localStorage.setItem("lang", item);
    },
  },
};
</script>

<style scoped>
* {
  text-transform: none !important;
}

.nav-link {
  color: white !important;
}

.nav-link:hover {
  background: rgba(255, 255, 255, 0.061);
}
</style>
