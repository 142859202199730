<template>
    <v-container>

        <v-btn @click.stop="addShopDialog=!addShopDialog" dark color="indigo">
            <v-icon size="18" left>fas fa-store</v-icon>

            Add Shop
        </v-btn>


          <h4 class="mt-5">Shops</h4>
          <v-simple-table>
            <template v-slot:default>
            <thead>
                <tr>
                <th class="text-left">Logo</th>
                <th class="text-left">Name</th>
                <th class="text-left">Category</th>
                <th class="text-left">Country</th>
                <th class="text-left">Options</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in shops" :key="item._id" >
                <td><img class="ma-2" :src="backendUrl + '/' + item.image" width="50" height="50" /></td>
                <td>{{item.name}}</td>
                <td>{{(item.category) ?item.category.name : ''}}</td>
                <td>{{item.country}}</td>
                <td>
                    <v-btn @click.stop="setEditShop(item)" outlined small color="success" class="ma-2">edit</v-btn>
                    <v-btn @click.stop="deleteShop(item)" outlined small color="error" class="ma-2">delete</v-btn>
                </td>
                </tr>
            </tbody>
            </template>
        </v-simple-table>

        <v-btn class="mt-10" @click.stop="addCatDialog=!addCatDialog" dark color="primary">
            Add Category
        </v-btn>

         <h4 class="mt-5">Categories</h4>
          <v-simple-table>
            <template v-slot:default>
            <thead>
                <tr>
                <th class="text-center">Name</th>
                <th class="text-center">Options</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in categories" :key="item._id" >
                <td class="text-center">{{item.name}}</td>
                <td class="d-flex justify-center align-center">
                    <v-btn @click.stop="setEditCat(item)" outlined small color="success" class="ma-2">edit</v-btn>
                    <v-btn @click.stop="deleteCat(item)" outlined small color="error" class="ma-2">delete</v-btn>
                </td>
                </tr>
            </tbody>
            </template>
        </v-simple-table>
        
        <v-dialog max-width="650" v-model="addShopDialog">
            <v-card>

                <v-card-title>Add New Shop</v-card-title>
                <v-card-text>
                    <v-form v-model="valid" ref="form">
                    <v-text-field
                    label="Name"
                    v-model="name"
                    ></v-text-field>

                     <v-text-field
                    label="Link"
                    v-model="link"
                    ></v-text-field>

                    <v-select 
                        label="Category"
                        v-model="category"
                        :items="categories.map(item=>{
                            return {
                                text:item.name,
                                value:item._id
                            }
                        })"
                    />

                    <v-select 
                        label="Country"
                        v-model="country"
                        :items="countryList"
                    />

                    <v-file-input
                    accept="image/*"
                    chips
                    label="Logo Image"
                    prepend-icon="fas fa-paperclip"
                    @change="this.onFilesPicked"
                    ></v-file-input>

                    <v-color-picker v-model="incolor"></v-color-picker>

                     <div class="d-flex justify-center mt-5">
                        <v-progress-circular v-if="loading" :indeterminate="true" size="40" color="indigo"></v-progress-circular>

                        <v-btn @click.stop="createShop" v-else  dark color="indigo">Add</v-btn>
                    </div>
                </v-form>
                </v-card-text>


            </v-card>
        </v-dialog>

        <v-dialog max-width="650" v-model="editShopDialog">
            <v-card>

                <v-card-title>Edit Shop</v-card-title>
                <v-card-text>
                    <v-form v-model="valid" ref="form">
                    <v-text-field
                    label="Name"
                    v-model="name"
                    ></v-text-field>

                    <v-text-field
                    label="Link"
                    v-model="link"
                    ></v-text-field>

                    <v-select 
                        label="Category"
                        v-model="category"
                        :items="categories.map(item=>{
                            return {
                                text:item.name,
                                value:item._id
                            }
                        })"
                    />

                    <v-select 
                        label="Country"
                        v-model="country"
                        :items="countryList"
                    />

                    <v-file-input
                    accept="image/*"
                    chips
                    label="Logo Image"
                    prepend-icon="fas fa-paperclip"
                    @change="this.onFilesPicked"
                    ></v-file-input>

                    <v-color-picker v-model="incolor"></v-color-picker>

                     <div class="d-flex justify-center mt-5">
                        <v-progress-circular v-if="loading" :indeterminate="true" size="40" color="indigo"></v-progress-circular>

                        <v-btn @click.stop="editShop" v-else  dark color="indigo">Update</v-btn>
                    </div>
                </v-form>
                </v-card-text>


            </v-card>
        </v-dialog>

        <v-dialog max-width="650" v-model="addCatDialog">
                    <v-card>

                        <v-card-title>Add New Category</v-card-title>
                        <v-card-text>
                            <v-form v-model="catForm" ref="addCatForm">
                                <v-text-field
                                    label="Name"
                                    v-model="catName"
                                    :rules="[
                                        v => !!v || 'Name is required'
                                    ]"
                                ></v-text-field>

                                <div class="d-flex justify-center mt-5">
                        <v-progress-circular v-if="loading" :indeterminate="true" size="40" color="indigo"></v-progress-circular>

                        <v-btn v-else @click.stop="createCat"  dark color="indigo">Add</v-btn>
                    </div>
                            </v-form>
                        </v-card-text>

                        
                    </v-card>
                </v-dialog>

        <v-dialog max-width="650" v-model="editCategoryDialog">
                    <v-card>

                        <v-card-title>Edit Category</v-card-title>
                        <v-card-text>
                            <v-form v-model="catForm" ref="addCatForm">
                                <v-text-field
                                    label="Name"
                                    v-model="catName"
                                    :rules="[
                                        v => !!v || 'Name is required'
                                    ]"
                                ></v-text-field>

                                <div class="d-flex justify-center mt-5">
                        <v-progress-circular v-if="loading" :indeterminate="true" size="40" color="indigo"></v-progress-circular>

                        <v-btn v-else @click.stop="editCat"  dark color="indigo">Update</v-btn>
                    </div>
                            </v-form>
                        </v-card-text>

                        
                    </v-card>
                </v-dialog>

        <v-snackbar left v-model="snackbar" :color="color">
            {{message}}
        </v-snackbar>
    </v-container>
</template>

<script>
import axios from 'axios';
import auth from '../helpers/auth';

import {countryList,backendUrl} from '../config/config'

export default {
    name:'ManageShops',
    mounted(){

        if( this.admin.groupId <= this.adminId ) {
            this.$router.push('support/');
        }

        axios.get('/api/shops/category/get').then(res=>{
            this.categories = res.data.categories;
        }).catch(err=>{
            this.snackbar = true;
            this.color = 'error';
            this.message = 'an error happend please try again'
        })

        axios.get('/api/shops/shop/get').then(res=>{
            this.shops = res.data.shops;

        }).catch(err=>{
            this.snackbar = true;
            this.color = 'error';
            this.message = 'an error happend please try again'
        })

    },
    data(){
        return {
            admin: auth.admin(),
            adminId: 1,
            supportId: 2,
            name:'',
            category:'',
            country:'',
            incolor:'',
            addShopDialog:false,
            loading:false,
            addCatDialog:false,
            valid:false,
            catForm:false,
            catName:'',
            snackbar:false,
            color:'',
            message:'',
            categories:[],
            countryList,
            image:'',
            shops:[],
            backendUrl,
            editShopDialog:false,
            selectedShop:{},
            editCategoryDialog:false,
            selectedCat:{},
            link:''
        }
    },
    methods:{
        createCat(){
            if(!this.catName){
                this.snackbar = true;
                this.color = 'error';
                this.message = 'Category name is required';

                return;
            }

            this.loading = true;
            
            axios.post('/api/shops/category/create',{
                name:this.catName
            }).then(res=>{
                this.loading = false;

                if(res.data.error){
                    this.snackbar = true;
                    this.color = 'error';
                    this.message = res.data.message
                }else{
                    this.snackbar = true;
                    this.color = 'success';
                    this.message = res.data.message

                    this.categories = [...this.categories,res.data.category]

                    this.$refs.addCatForm.reset();
                }
            }).catch(err=>{
                this.loading = false;
                this.snackbar = true;
                this.color = 'error';
                this.message = 'an error happened please try again'
            })
        },
         onFilesPicked(f){
          this.image = f;
      },
      createShop(){

          if(!this.name || !this.category || !this.country || !this.incolor || !this.image || !this.link ){
              this.snackbar = true;
              this.color = 'error';
              this.message = 'please fill all fields and try again';

              return;
          }

          this.loading = true;

          let data = new FormData();

          data.append('name',this.name);
          data.append('category',this.category);
          data.append('country',this.country);
          data.append('color',this.incolor);
          data.append('image',this.image);
        data.append('link',this.link);


          axios.post('/api/shops/shop/create',data).then(res=>{

              this.loading = false;

              if(res.data.error){
                  this.snackbar = true;
                  this.color = 'error';
                  this.message = res.data.message;
              }else{
                  this.snackbar = true;
                  this.color = 'success';
                  this.message = res.data.message;

                  res.data.shop.category = res.data.category;

                  this.shops = [...this.shops,res.data.shop]

                  this.$refs.form.reset();
              }
              
          }).catch(err=>{
              this.snackbar = true;
              this.color = 'error';
              this.message = 'an error happened please try again';
          })
      },
      setEditShop(item){
          this.selectedShop = item;
          this.editShopDialog = true;

          this.country = '';
          this.category = ''

          this.name = item.name;
          this.category = item.category._id;
          this.country = item.country;
          this.incolor = item.color;
          this.link= item.link
      },
      editShop(){

          if(!this.name || !this.category || !this.country || !this.incolor || !this.link ){
              this.snackbar = true;
              this.color = 'error';
              this.message = 'please fill all fields and try again';

              return;
          }

          this.loading = true;

          let data = new FormData();

          data.append('name',this.name);
          data.append('category',this.category);
          data.append('country',this.country);
          data.append('color',this.incolor);
          data.append('image',this.image);
        data.append('link',this.link);
          data.append('shopId',this.selectedShop._id);

          axios.post('/api/shops/shop/edit',data).then(res=>{

              this.loading = false;

              if(res.data.error){
                  this.snackbar = true;
                  this.color = 'error';
                  this.message = res.data.message;
              }else{
                  this.snackbar = true;
                  this.color = 'success';
                  this.message = res.data.message;

                  res.data.shop.category = res.data.category;

                  this.shops = this.shops.map(item=>{

                      if(item._id == res.data.shop._id){
                          let shop = res.data.shop;
                          return shop
                      }

                      return item;
                  });
              }
              
          }).catch(err=>{
              this.snackbar = true;
              this.color = 'error';
              this.message = 'an error happened please try again';
          })
      },
      setEditCat(item){
          this.selectedCat= item;
        
          this.catName = item.name;
          this.editCategoryDialog = true;
      },
      editCat(){
            if(!this.catName){
                this.snackbar = true;
                this.color = 'error';
                this.message = 'Category name is required';

                return;
            }

            this.loading = true;
            
            axios.post('/api/shops/category/edit',{
                name:this.catName,
                categoryId:this.selectedCat._id
            }).then(res=>{
                this.loading = false;

                if(res.data.error){
                    this.snackbar = true;
                    this.color = 'error';
                    this.message = res.data.message
                }else{
                    this.snackbar = true;
                    this.color = 'success';
                    this.message = res.data.message

                    this.categories = this.categories.map(item=>{

                        if(item._id == res.data.category._id){
                            return res.data.category;
                        }

                        return item;
                    })

                }
            }).catch(err=>{
                this.loading = false;
                this.snackbar = true;
                this.color = 'error';
                this.message = 'an error happened please try again'
            })
        },

        deleteShop(item){

        axios.delete('/api/shops/shop/delete/'+item._id).then(res=>{

            if(res.data.error){
                this.snackbar = true;
                this.color = 'error';
                this.message ='An error happened please try again later';
            }else{
                this.snackbar = true;
                this.color = 'warning';
                this.message = 'Deleted Successfully';

                this.shops = this.shops.filter(shop=>shop._id != item._id )
            }
        }).catch(err=>{
            console.log(err)
            this.snackbar = true;
            this.color = 'error';
            this.message ='An error happened please try again later';
        })
    },
    deleteCat(item){

        axios.delete('/api/shops/category/delete/'+item._id).then(res=>{

            if(res.data.error){
                this.snackbar = true;
                this.color = 'error';
                this.message ='An error happened please try again later';
            }else{
                this.snackbar = true;
                this.color = 'warning';
                this.message = 'Deleted Successfully';

                this.categories = this.categories.filter(cat=>cat._id != item._id )
            }
        }).catch(err=>{
            console.log(err)
            this.snackbar = true;
            this.color = 'error';
            this.message ='An error happened please try again later';
        })
    }
    },
    
}
</script>