var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Navbar'),_c('v-layout',{staticStyle:{"margin-top":"60px","margin-bottom":"60px"},attrs:{"align-center":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm8":"","md4":""}},[_c('v-card',{staticClass:"elevation-12",staticStyle:{"margin":"0 20px 0 20px"}},[_c('v-toolbar',{attrs:{"dark":"","color":"indigo"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('signup')))])],1),_c('v-card-text',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"sm":"6"}},[_c('v-text-field',{attrs:{"name":"firstName","label":_vm.$t('firstName'),"id":"firstName","type":"text","color":"indigo","rules":[
                    function (v) { return !!v || _vm.$t('firstNameRequired'); },
                    function (v) { return (!!v && _vm.checkName(v)) || _vm.$t('invalidFirstName'); } ],"required":""},on:{"keyup":_vm.checkArabic},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"name":"lastName","label":_vm.$t('lastName'),"id":"lastName","type":"text","color":"indigo","rules":[
                    function (v) { return !!v || _vm.$t('lastNameRequired'); },
                    function (v) { return (!!v && _vm.checkName(v)) || _vm.$t('invalidLastName'); } ],"required":""},on:{"keyup":_vm.checkArabic},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})],1)],1),_c('v-row',{staticStyle:{"direction":"ltr","margin-left":"0px"}},[_c('v-col',{staticClass:"mx-0 px-0",attrs:{"cols":"4"}},[_c('v-menu',{attrs:{"offset-y":"","transition":"scale-transition","nudge-top":"18","max-height":"240","close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var attrs = ref.attrs;
                  var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"name":"codeCount","label":_vm.$t('countryCode'),"autocomplete":"off","rules":[
                        function (v) { return !!v || _vm.$t('codeRequired'); },
                        function (v) { return _vm.checkCountryCode(v) || 'Invalid code'; } ]},on:{"keyup":_vm.filterCodes},model:{value:(_vm.countryCode),callback:function ($$v) {_vm.countryCode=$$v},expression:"countryCode"}},on))]}}]),model:{value:(_vm.codeMenu),callback:function ($$v) {_vm.codeMenu=$$v},expression:"codeMenu"}},_vm._l((_vm.countryCodes),function(item){return _c('v-list-item',{key:item.id,staticClass:"white pa-2",on:{"click":function($event){$event.stopPropagation();return _vm.setCode(item)}}},[_c('v-list-item-content',{staticStyle:{"direction":"ltr"}},[_vm._v(_vm._s(item))])],1)}),1)],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{staticStyle:{"direction":"ltr"},attrs:{"name":"phone","label":_vm.$t('phone'),"id":"phone","type":"text","color":"indigo","rules":[
                    function (v) { return !!v || _vm.$t('phoneRequired'); },
                    function (v) { return _vm.phoneRe.test(v) || _vm.$t('invalidPhoneNumber'); }
                  ],"required":""},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1)],1),_c('v-text-field',{attrs:{"name":"email","label":_vm.$t('email'),"id":"email","type":"email","color":"indigo","rules":[
                function (v) { return !!v || _vm.$t('emailRequired'); },
                function (v) { return this$1.emailRe.test(String(v).toLowerCase()) ||
                  _vm.$t('validEmail'); } ],"required":""},on:{"keyup":_vm.checkArabic},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-text-field',{attrs:{"name":"confirmEmail","label":_vm.$t('confirmEmail'),"id":"confirmEmail","type":"email","color":"indigo","rules":[
            function (v) { return !!v || _vm.$i18n.t('emailConfirmationRequired'); },
            function (v) { return v == _vm.email || _vm.$i18n.t('matchEmail'); }],"required":""}}),_c('v-text-field',{ref:"password",attrs:{"name":"password","label":_vm.$t('password'),"id":"password","type":_vm.showPassword ? 'text' : 'password',"append-icon":_vm.showPassword ? 'fa-eye' : 'fa-eye-slash',"color":"indigo","rules":[
                function (v) { return !!v || _vm.$t('passwordRequired'); },
                function (v) { return v.length >= 8 || _vm.$t('minPassword'); } ],"required":""},on:{"click:append":function (){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-spacer'),_c('v-spacer'),(_vm.loading)?_c('div',{staticClass:"d-flex justify-center"},[_c('v-progress-circular',{attrs:{"indeterminate":true,"size":"40","color":"indigo"}})],1):_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"ma-2 mx-auto",attrs:{"type":"submit","dark":"","color":"indigo"}},[_vm._v(" "+_vm._s(_vm.$t('create'))+" ")])],1)],1)],1),_c('v-card-actions')],1)],1),_c('v-snackbar',{attrs:{"left":"","color":_vm.color},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.message)+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }