<template>
    <v-container>
        edit user
    </v-container>
</template>

<script>
export default {
    name:'EditUser'
}
</script>

<style scoped>

</style>