<template>
    <div>
    
        <v-container fluid class="header">

                <LandingNavbar />

                <div style="height:100%" class="d-flex flex-column justify-center align-center">
                    <h2 style="color:#2564FF;font-size:34px">معذرة</h2>
                </div>
        </v-container>

        <v-layout style="height:600px" class="mt-10 pa-10" fill-height justify-center align-center>

            <img src="../assets/404.svg" />
        </v-layout>

        <Footer class="mt-10" />
    
    </div>
</template>

<script>
import Footer from '../components/Footer';
import LandingNavbar from '../components/LandingNavbar';

export default {
    name:'404',
    components:{Footer,LandingNavbar},
}
</script>


<style scoped>

.v-btn:before {
  display: none;
}

.navbar-link {
  color: grey !important;
  font-size: 16px!important;
}

.navbar-link:hover {
  color:black!important
}

.v-btn--active{
    color:black!important;
}

.header{
    background:white;
    background-image:url('../assets/test.svg');
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    height:300px
}

</style>

