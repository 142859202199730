<template>
  <div>
    <DashboardNavbar />

    <v-container>
      <v-form ref="form" @submit.prevent="handleSubmit" v-model="valid">
        <v-text-field
          v-model="firstName"
          name="firstName"
          :label="$t('firstName')"
          id="firstName"
          type="text"
          color="indigo"
          :rules="[
            (v) => !!v || $t('firstNameRequired'),
            (v) => (!!v && checkName(v)) || $t('invalidFirstName'),
          ]"
          required
        ></v-text-field>

        <v-text-field
          v-model="lastName"
          name="laastName"
          :label="$t('lastName')"
          id="lastName"
          type="text"
          color="indigo"
          :rules="[
            (v) => !!v || $t('lastNameRequired'),
            (v) => (!!v && checkName(v)) || $t('invalidLastName'),
          ]"
          required
        ></v-text-field>

        <v-text-field
          v-model="phone"
          name="phone"
          :label="$t('phone')"
          id="phone"
          type="text"
          color="indigo"
          style="direction:ltr"
          :rules="[
            (v) => !!v || $t('phoneRequired'),
            (v) =>
              this.phoneRe.test(String(v).toLowerCase()) || $t('phoneValid'),
          ]"
          disabled
        ></v-text-field>

        <v-text-field
          v-model="email"
          name="email"
          :label="$t('email')"
          id="email"
          type="email"
          color="indigo"
          :rules="[
            (v) => !!v || $t('emailRequired'),
            (v) =>
              this.emailRe.test(String(v).toLowerCase()) || $t('validEmail'),
          ]"
          disabled
        ></v-text-field>

        <v-spacer></v-spacer>

        <v-spacer></v-spacer>

        <div v-if="loading" class="d-flex justify-center">
          <v-progress-circular
            :indeterminate="true"
            size="40"
            color="indigo"
          ></v-progress-circular>
        </div>

        <div v-else class="d-flex justify-center">
          <v-btn class="ma-2 mx-auto" type="submit" dark color="indigo">
            {{ $t('save') }}
          </v-btn>
        </div>
      </v-form>

      <v-dialog width="600" v-model="dialog">
        <v-card>
          <v-card-title>{{ $t('changePass') }}</v-card-title>

          <v-card-text>
            <v-form
              @submit.prevent="updatePass"
              ref="passForm"
              v-model="passValid"
            >
              <v-text-field
                v-model="curPassword"
                name="password"
                :label="$t('currentPassword')"
                id="password"
                type="password"
                color="indigo"
                :rules="[
                  (v) => !!v || $t('passwordRequired'),
                  (v) => v.length >= 8 || $t('minPassword'),
                ]"
                required
              ></v-text-field>

              <v-text-field
                v-model="password"
                name="password"
                :label="$t('newPass')"
                id="password"
                type="password"
                color="indigo"
                :rules="[
                  (v) => !!v || $t('passwordRequired'),
                  (v) => v.length >= 8 || $t('minPassword'),
                ]"
                required
              ></v-text-field>

              <v-text-field
                v-model="confirmPassword"
                name="confirm-password"
                :label="$t('confirmPassword')"
                id="confirmPassword"
                type="password"
                color="indigo"
                :rules="
                  [
                    (v) => !!v || $t('passwordRequired'),
                    (v) => v.length >= 8 || $t('minPassword'),
                  ].concat([
                    (v) => v === this.password || $t('indenticalPassword'),
                  ])
                "
                required
              ></v-text-field>

              <v-spacer></v-spacer>

              <v-spacer></v-spacer>

              <div v-if="passwordLoading" class="d-flex justify-center">
                <v-progress-circular
                  :indeterminate="true"
                  size="40"
                  color="indigo"
                ></v-progress-circular>
              </div>

              <div v-else class="d-flex justify-center">
                <v-btn class="ma-2 mx-auto" type="submit" dark color="indigo">
                  {{ $t('save') }}
                </v-btn>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>

      <div class="ma-4">
        <v-btn
          class="white--text"
          @click="dialog = !dialog"
          :disabled="loading"
          color="success"
          >{{ $t('changePass') }}</v-btn
        >
      </div>

      <v-snackbar left :color="color" v-model="snackbar">
        {{ message }}
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import DashboardNavbar from '../components/DashboardNavbar';

import axios from 'axios';
import auth from '../helpers/auth';

const newAxios = axios.create();
newAxios.defaults.headers.common = {};

export default {
  name: 'EditProfile',
  components: { DashboardNavbar },
  metaInfo: {
    title: 'Settings | Porsay',
  },
  data() {
    return {
      valid: false,
      passValid: false,

      message: '',

      loading: false,

      email: '',
      //eslint-disable-next-line
      emailRe: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      //eslint-disable-next-line
      phoneRe: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
      emailRules: [
        (v) => !!v || 'Email is required',
        (v) => v || 'Enter a valid Email',
      ],

      phone: '',
      firstName: '',

      lastName: '',

      curPassword: '',
      password: '',
      confirmPassword: '',
      snackbar: false,
      color: '',
      dialog: false,
      passwordLoading: false,
      userId: '',
    };
  },
  mounted() {
    if (this.$i18n.locale === 'ar') {
      this.$vuetify.rtl = true;
    }

    newAxios
      .get('/api/users/getuser', {
        headers: {
          Authorization: `bearer ${auth.token()}`,
        },
      })
      .then((res) => {
        let user = res.data.user;

        this.firstName = user.firstName;
        this.lastName = user.lastName;
        this.phone = user.phone;
        this.email = user.email;
        this.userId = user.id;
      });
  },
  methods: {
    checkName(text) {
      const restrectedChar = ['@', '&', '<', '>', '=', '/', '!', '?'];
      let passed = true;
      restrectedChar.forEach((el) => {
        if (text.includes(el)) {
          passed = false;
        }
      });
      if (passed) {
        const str = text[0].toLowerCase();
        if (!str.match(/[a-z]/i)) passed = false;
      }

      return passed;
    },
    confirmRule(val, text) {
      if (val != this.password) {
        return text;
      } else {
        return true;
      }
    },
    handleSubmit() {
      this.loading = true;
      newAxios
        .put(
          '/api/users/updateuser',
          {
            firstName: this.firstName,
            lastName: this.lastName,
          },
          {
            headers: {
              Authorization: `bearer ${auth.token()}`,
            },
          }
        )
        .then((res) => {
          if (!res.data.error) {
            if (this.$i18n.locale == 'ar') {
              this.message = 'تم التعديل بنجاح';
            } else if (this.$i18n.locale == 'en') {
              this.message = 'Updated Successfully.';
            } else {
              this.message = 'Updated Successfully.';
            }

            this.snackbar = true;
            this.color = 'success';
            this.loading = false;

            localStorage.setItem('token', res.data.token);
          } else {
            if (this.$i18n.locale == 'ar') {
              this.message = 'حصل خطا ما الرجاء المحالة مجدداً';
            } else if (this.$i18n.locale == 'en') {
              this.message = 'An error happened please try again';
            } else {
              this.message = 'An error happened please try again';
            }

            this.snackbar = true;
            this.color = 'error';
            this.loading = false;
          }
        })
        .catch((err) => {
          if (this.$i18n.locale == 'ar') {
            this.message = 'حصل خطا ما الرجاء المحالة مجدداً';
          } else if (this.$i18n.locale == 'en') {
            this.message = 'An error happened please try again';
          } else {
            this.message = 'An error happened please try again';
          }
          this.snackbar = true;
          this.color = 'error';
          this.loading = false;
          console.log(err);
        });
    },

    updatePass() {
      if (!this.$refs.passForm.validate()) {
        return;
      }

      this.passwordLoading = true;

      newAxios
        .post(
          'api/users/update_password',
          {
            curPassword: this.curPassword,
            newPassword: this.password,
          },
          {
            headers: {
              Authorization: `bearer ${auth.token()}`,
            },
          }
        )
        .then((res) => {
          if (!res.data.error) {
            if (this.$i18n.locale == 'ar') {
              this.message = 'تم التعديل بنجاح';
            } else if (this.$i18n.locale == 'en') {
              this.message = 'Updated Successfully.';
            } else {
              this.message = 'Updated Successfully.';
            }

            this.snackbar = true;
            this.color = 'success';
            this.passwordLoading = false;

            this.$refs.passForm.reset();
          } else {
            if (res.data.message == 'Incorrect Password') {
              if (this.$i18n.locale == 'ar') {
                this.message = 'كلمة سر خاطئة';
              } else if (this.$i18n.locale == 'en') {
                this.message = 'Incorrect Password';
              } else {
                this.message = 'Incorrect Password';
              }
            }

            this.snackbar = true;
            this.color = 'error';
            this.passwordLoading = false;
          }
        })
        .catch((err) => {
          if (this.$i18n.locale == 'ar') {
            this.message = 'حصل خطا ما الرجاء المحالة مجدداً';
          } else if (this.$i18n.locale == 'en') {
            this.message = 'An error happened please try again';
          } else {
            this.message = 'An error happened please try again';
          }

          this.snackbar = true;
          this.color = 'error';
          this.passwordLoading = false;

          console.log(err);
        });
    },
  },
};
</script>
