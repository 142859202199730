<template>
  <div>
    
    <Navbar />
    
    <v-layout style="margin-top:150px;" align-center justify-center>
    <v-flex xs12 sm8 md4>
      <v-card class="elevation-12" style="margin: 0 20px 0 20px">
        <v-toolbar dark color="indigo">
          <v-toolbar-title>Admin Login</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" @submit.prevent="login" v-model="valid">
            <v-text-field
              v-model="username"
              name="username"
              label="Username"
              id="username"
              type="text"
              color="indigo"
              :rules="usernameRules"
              required
            ></v-text-field>
            <v-text-field
              v-model="password"
              name="password"
              label="Password"
              id="password"
              type="password"
              color="indigo"
              :rules="passwordRules"
              required
            ></v-text-field>

            <v-spacer></v-spacer>
          
            <v-spacer></v-spacer>


            <div v-if="loading" class="d-flex justify-center">
              <v-progress-circular
            :indeterminate="true"
            size="40"
            color="indigo"
          ></v-progress-circular>
            </div>

            <div v-else class="d-flex justify-center" >
              <v-btn class="ma-2 mx-auto"  type="submit"  dark color="indigo" >
                  Login
              </v-btn>
            </div>
          </v-form>
        </v-card-text>

        <v-card-actions>
          
          
        </v-card-actions>
      </v-card>
    </v-flex>
    <v-snackbar left :color="color" v-model="snackbar">
              {{ message }}
    </v-snackbar>

  </v-layout>
  </div>
</template>


<script>
// @ is an alias to /src
import Navbar from '../components/Navbar';

import auth from '../helpers/auth';

export default {
  name: 'Login',
  components:{Navbar},

  data: ()=> ({
    valid: false,

    message: '',

    loading:false,

    username: '',

    password: "",
    passwordRules: [
      v => !!v ||'Password is Required',
    ],
    usernameRules: [
      v => !!v ||'Username is Required',
    ],
    snackbar:false,
    color:''
  }),
  methods:{
    login(){
      this.loading = true;
      auth.adminLogin(this.username,this.password,(status,msg)=>{
         this.loading = false;
          if(!status){
            this.loading = false;
            this.snackbar = true;
            this.message = msg;
            this.color = 'error';
          }else{
            window.location.href = '/en/admin/'
          }
      })
    }
  },
  mounted(){

  }
}
</script>

<style scoped>

</style>



