<template>
  <div>
    <DashboardNavbar />
    <DashboardToolbar />
    <v-container>
        <loading
            :active.sync="isLoading"
            :can-cancel="false"
            loader="bars"
            color="indigo"
            :is-full-page="true"
        ></loading>
        <v-container>
          <div class="align-center">
              <v-row class="d-flex align-center">
                    <v-col :cols="12">
                      <v-text-field v-model="query" :placeholder="$t('search')" class="mx-4" clearable></v-text-field>
                    </v-col>
              </v-row>
          </div>
        </v-container>
        <div
            class="text-center"
            v-if="this.filteredPackages.length == 0 && 
            this.filteredAssembleRequests.length == 0 && 
            this.filteredIncomingPackages.length == 0 && 
            this.filteredShippedPackages.length == 0"
        >
            {{ $t('noPackages') }}
        </div>
        <v-row v-else>
            <Package
            v-for="userPackage in filteredPackages"
            :packages="packages"
            :flagImage="userPackage.warehouse.flagImage"
            :userPackage="userPackage"
            @updateData="updateData"
            :key="userPackage._id"
          />

          <AssemblePackage
            v-for="request in filteredAssembleRequests"
            :flagImage="userPackage.warehouse.flagImage"
            :request="request"
            @updateData="updateData"
            :key="request._id"
          />

          <IncomePackage
          v-for="userPackage in filteredIncomingPackages"
          :flagImage="userPackage.warehouse.flagImage"
          :userPackage="userPackage"
          @updateData="updateData"
          :key="userPackage._id"
          />

          <IncomePackage
          v-for="userPackage in filteredShippedPackages"
          :userPackage="userPackage"
          @updateData="updateData"
          :key="userPackage._id"
          />
        </v-row>
    </v-container>
  </div>
</template>

<script>
import DashboardToolbar from "../components/DashboardToolbar";
import Package from "../components/Package";
import DashboardNavbar from "../components/DashboardNavbar";
import AssemblePackage from "../components/AssemblePackage.vue";
import IncomePackage from "../components/IncomePackage.vue";

import axios from "axios";
import auth from "../helpers/auth";

// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

const newAxios = axios.create();
newAxios.defaults.headers.common = {};

export default {
    components:{
        DashboardToolbar,
        Package,
        DashboardNavbar,
        AssemblePackage, 
        IncomePackage,
        Loading
    },
    watch:{
        query(val){
            if(val == null || val == ''){
                this.filteredPackages = this.packages
                this.filteredAssembleRequests = this.assembleRequests
                this.filteredIncomingPackages = this.incomingPackages
                this.filteredShippedPackages = this.shippedPackages
                this.query = ''
            }
            else{
                this.filteredPackages = this.packages.filter(item => {
                return (item.trackNumber != null && item.trackNumber.toLowerCase().indexOf(this.query.toLowerCase()) != -1) || (item.packageId.toLowerCase().indexOf(this.query.toLowerCase()) != -1);
                })
                this.filteredAssembleRequests = this.assembleRequests.filter(item => {
                return (item.packageId != null && item.packageId.toLowerCase().indexOf(this.query.toLowerCase()) != -1);
                })
                this.filteredIncomingPackages = this.incomingPackages.filter(item => {
                return (item.trackNumber != null && item.trackNumber.toLowerCase().indexOf(this.query.toLowerCase()) != -1) || (item.packageId.toLowerCase().indexOf(this.query.toLowerCase()) != -1);
                })
                this.filteredShippedPackages = this.shippedPackages.filter(item => {
                return (item.trackNumber != null && item.trackNumber.toLowerCase().indexOf(this.query.toLowerCase()) != -1) || (item.packageId.toLowerCase().indexOf(this.query.toLowerCase()) != -1);
                })
            }
        }
    },
    data(){
        return{
            isLoading: false,
            packages: [],
            assembleRequests: [],
            incomingPackages: [],
            shippedPackages: [],
            query: "",
            filteredPackages: [],
            filteredAssembleRequests: [],
            filteredIncomingPackages: [],
            filteredShippedPackages: [],
        }
    },
    mounted(){
      this.isLoading = true
        newAxios
      .get("/api/users/packages/get", {
        headers: {
          Authorization: `bearer ${auth.token()}`,
        },
      })
      .then((res) => {
        this.packages = res.data.packages;
        this.filteredPackages = res.data.packages
      })
      .catch((err) => {
        this.isLoading = false;
      });

    newAxios
      .get("/api/packages/group/user", {
        headers: {
          Authorization: `bearer ${auth.token()}`,
        },
      })
      .then((res) => {
        this.assembleRequests = res.data.packagesGroups;
        this.filteredAssembleRequests = res.data.packagesGroups;
      })
      .catch((err) => {
        console.log(err);
      });

    newAxios
      .get("/api/packages/shipped/user", {
        headers: {
          Authorization: `bearer ${auth.token()}`,
        },
      })
      .then((res) => {
        this.shippedPackages = res.data.packages;
        this.filteredShippedPackages = res.data.packages;
      })
      .catch((err) => {
        console.log(err)
      });

      newAxios
      .get('/api/packages/awaiting-shipping/user', {
        headers: {
          Authorization: `bearer ${auth.token()}`,
        },
      })
      .then((res) => {
        this.incomingPackages = res.data.packages;
        this.felteredIncomingPackages = res.data.packages;
        this.isLoading = false;
      })
      .catch((err) => {
        this.isLoading = false;
      });
    },
    methods:{
      updateData() {
      this.isLoading = true
        newAxios
      .get("/api/users/packages/get", {
        headers: {
          Authorization: `bearer ${auth.token()}`,
        },
      })
      .then((res) => {
        this.packages = res.data.packages;
        this.filteredPackages = res.data.packages
      })
      .catch((err) => {
        this.isLoading = false;
      });

    newAxios
      .get("/api/packages/group/user", {
        headers: {
          Authorization: `bearer ${auth.token()}`,
        },
      })
      .then((res) => {
        this.assembleRequests = res.data.packagesGroups;
        this.filteredAssembleRequests = res.data.packagesGroups;
      })
      .catch((err) => {
        console.log(err);
      });

    newAxios
      .get("/api/packages/shipped/user", {
        headers: {
          Authorization: `bearer ${auth.token()}`,
        },
      })
      .then((res) => {
        this.shippedPackages = res.data.packages;
        this.filteredShippedPackages = res.data.packages;
      })
      .catch((err) => {
        console.log(err)
      });

      newAxios
      .get('/api/packages/awaiting-shipping/user', {
        headers: {
          Authorization: `bearer ${auth.token()}`,
        },
      })
      .then((res) => {
        this.incomingPackages = res.data.packages;
        this.felteredIncomingPackages = res.data.packages;
        this.isLoading = false;
      })
      .catch((err) => {
        this.isLoading = false;
      });
    }}
}
</script>

<style>

</style>