<template>
  <v-col :style="hide ? 'display: none' : ''" sm="12" md="6">
    <v-card>
      <div class="pa-3 blue--text" style="font-size:22px;">
        {{ this.address.title }}
      </div>

      <v-divider></v-divider>

      <v-card-text>
        <div style="font-size:17px;" class="d-flex indigo--text  ma-2">
          <div
            :class="
              this.$i18n.locale == 'ar'
                ? 'ml-4 indigo--text text--lighten-1'
                : 'mr-4 indigo--text text--lighten-1'
            "
          >
            <b>{{ $t("name") }}:</b>
          </div>
          <div class="black--text">{{ this.addrs.name }}</div>
        </div>

        <div style="font-size:17px;" class="d-flex indigo--text  ma-2">
          <div
            :class="
              this.$i18n.locale == 'ar'
                ? 'ml-4 indigo--text text--lighten-1'
                : 'mr-4 indigo--text text--lighten-1'
            "
          >
            <b>{{ $t("addressFirst") }}:</b>
          </div>
          <div class="black--text">{{ this.addrs.addressLineOne }}</div>
        </div>

        <div style="font-size:17px;" class="d-flex indigo--text  ma-2">
          <div
            :class="
              this.$i18n.locale == 'ar'
                ? 'ml-4 indigo--text text--lighten-1'
                : 'mr-4 indigo--text text--lighten-1'
            "
          >
            <b>{{ $t("addressSecond") }}:</b>
          </div>
          <div class="black--text">{{ this.addrs.addressLineTwo }}</div>
        </div>

        <div style="font-size:17px;" class="d-flex indigo--text  ma-2">
          <div
            :class="
              this.$i18n.locale == 'ar'
                ? 'ml-4 indigo--text text--lighten-1'
                : 'mr-4 indigo--text text--lighten-1'
            "
          >
            <b>{{ $t("city") }}:</b>
          </div>
          <div class="black--text">{{ this.addrs.city }}</div>
        </div>

        <div style="font-size:17px;" class="d-flex indigo--text  ma-2">
          <div
            :class="
              this.$i18n.locale == 'ar'
                ? 'ml-4 indigo--text text--lighten-1'
                : 'mr-4 indigo--text text--lighten-1'
            "
          >
            <b>{{ $t("postal") }}:</b>
          </div>
          <div class="black--text">{{ this.addrs.zip }}</div>
        </div>

        <div style="font-size:17px;" class="d-flex indigo--text  ma-2">
          <div
            :class="
              this.$i18n.locale == 'ar'
                ? 'ml-4 indigo--text text--lighten-1'
                : 'mr-4 indigo--text text--lighten-1'
            "
          >
            <b>{{ $t("country") }}:</b>
          </div>
          <div class="black--text">{{ this.addrs.country }}</div>
        </div>

        <div style="font-size:17px;" class="d-flex indigo--text  ma-2">
          <div
            :class="
              this.$i18n.locale == 'ar'
                ? 'ml-4 indigo--text text--lighten-1'
                : 'mr-4 indigo--text text--lighten-1'
            "
          >
            <b>{{ $t("phone") }}:</b>
          </div>
          <div class="black--text">{{ this.addrs.phone }}</div>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="grey lighten-4">
        <v-btn
          @click="dialog = !dialog"
          color="success"
          class="white--text ma-2"
          >{{ $t("edit") }}</v-btn
        >
        <v-btn @click="deleteRequest" color="error" class="white--text ma-2">{{
          $t("delete")
        }}</v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="dialog" width="650">
      <v-card class="pa-1">
        <v-card-text class="pa-3 blue--text" style="font-size:18px">
          {{ $t("editAddressText") }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text>
          <v-form ref="form" @submit.prevent="handleUpdate" v-model="valid">
            <v-text-field
              v-model="title"
              name="title"
              :label="$t('title')"
              :placeholder="$t('titlePlaceholder')"
              id="title"
              type="text"
              color="indigo"
              required
            ></v-text-field>

            <v-text-field
              v-model="name"
              name="name"
              :label="$t('name')"
              id="name"
              type="text"
              color="indigo"
              @keyup="checkArabic"
              required
              :rules="[(v) => !!v || $i18n.t('codeRequired')]"
            ></v-text-field>

            <v-select
              :label="$t('country')"
              v-model="country"
              :items="countryList"
              :rules="[(v) => !!v || $i18n.t('codeRequired')]"
            ></v-select>

              <v-text-field
              v-model="nationalID"
              v-if="country == 'Algeria'"
              :rules="[
                (text)=> text.length > 8 || $t('nationalIDValid')
              ]"
              name="nationalID"
              :label="$t('nationalID')"
              id="nationalID"
              type="text"
              color="indigo"
              @keyup="checkArabic"
              min="10"
              required
            ></v-text-field>

            <v-text-field
              v-model="addressFirst"
              name="addressFirst"
              :label="$t('addressFirst')"
              id="address1"
              type="text"
              color="indigo"
              @keyup="checkArabic"
              required
              :rules="[(v) => !!v || $i18n.t('codeRequired')]"
            ></v-text-field>

            <v-text-field
              v-model="addressSecond"
              name="addressFirst"
              :label="$t('addressSecond')"
              id="address2"
              type="text"
              color="indigo"
              @keyup="checkArabic"
              required
              :rules="[(v) => !!v || $i18n.t('codeRequired')]"
            ></v-text-field>

            <v-text-field
              v-model="city"
              name="city"
              :label="$t('city')"
              id="city"
              type="text"
              color="indigo"
              @keyup="checkArabic"
              required
              :rules="[(v) => !!v || $i18n.t('codeRequired')]"
            ></v-text-field>

            <v-text-field
              v-model="postal"
              name="postal"
              :label="$t('postal')"
              id="postal"
              type="text"
              color="indigo"
              @keyup="checkArabic"
              required
              :rules="[(v) => !!v || $i18n.t('codeRequired')]"
            ></v-text-field>

            <v-text-field
              v-model="phone"
              name="phone"
              :label="$t('phone')"
              id="phone"
              type="text"
              color="indigo"
              required
              :rules="[(v) => !!v || $i18n.t('codeRequired')]"
            ></v-text-field>

            <v-spacer></v-spacer>

            <v-spacer></v-spacer>

            <div v-if="loading" class="d-flex justify-center">
              <v-progress-circular
                :indeterminate="true"
                size="40"
                color="indigo"
              ></v-progress-circular>
            </div>

            <div v-else class="d-flex justify-center">
              <v-btn class="ma-2 mx-auto" type="submit" dark color="indigo">
                {{ $t("update") }}
              </v-btn>
            </div>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" max-width="600">
      <v-card>
        <v-card-title class="headline">{{
          $t("actionConfirmation")
        }}</v-card-title>
        <v-spacer></v-spacer>
        <v-card-text style="padding-bottom: 0">
          <i
            class="fa-solid fa-exclamation-triangle"
            style="color: red; font-size: 1rem; margin: 0 .5rem;"
          ></i>
          {{ $t("deleteAddressConfirmation") }}
          <b> {{ this.addrs ? ` ${this.addrs.title}` : "" }}</b>
          {{ $t("?") }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn depressed @click="handleDelete" color="error">{{
            $t("delete")
          }}</v-btn>
          <v-btn
            @click="deleteDialog = !deleteDialog"
            class="ma-2"
            color="grey white--text"
            >{{ $t("close") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar right top :color="color" v-model="snackbar">{{
      message
    }}</v-snackbar>
  </v-col>
</template>

<script>
import axios from "axios";
import auth from "../helpers/auth";
import { countryList } from "../config/config";

const newAxios = axios.create();
newAxios.defaults.headers.common = {};

const isArabic = /[\u0621-\u064A]+/g;

export default {
  name: "AddressCard",
  data() {
    return {
      hide: false,
      valid: false,
      color: "",
      snackbar: false,
      message: "",
      id: "",
      loading: false,
      dialog: false,
      deleteDialog: false,
      name: "",
      title: "",
      addressFirst: "",
      addressSecond: "",
      phone: "",
      postal: "",
      country: "",
      city: "",
      countryList: countryList,
      addrs: {},
      nationalID:""
    };
  },
  mounted() {
    if (this.$i18n.locale === "ar") {
      this.$vuetify.rtl = true;
    }

    this.id = this.address._id;

    this.addrs = this.address;

    this.title = this.address.title;
    this.name = this.address.name;
    this.phone = this.address.phone;
    this.addressFirst = this.address.addressLineOne;
    this.addressSecond = this.address.addressLineTwo;
    this.postal = this.address.zip;
    this.country = this.address.country;
    this.city = this.address.city;
    this.nationalID = this.address.nationalID;
  },
  props: {
    address: {
      type: Object,
    },
  },
  methods: {
    deleteRequest() {
      this.dialog = false;
      this.deleteDialog = true;
    },
    handleDelete() {
      newAxios
        .post(
          "api/users/addresses/delete",
          {
            addressId: this.id,
          },
          {
            headers: {
              Authorization: `bearer ${auth.token()}`,
            },
          }
        )
        .then((res) => {
          if (!res.data.error) {
            if (this.$i18n.locale == "ar") {
              this.message = "تم حذف العنوان بنجاح";
            } else if (this.$i18n.locale == "en") {
              this.message = "The address was deleted successfully";
            }

            this.color = "success";
            this.snackbar = true;
            this.deleteDialog = false;

            // its hidding the snackbar also
            this.hide = true;
          } else {
            if (this.$i18n.locale == "ar") {
              this.message = "حدث خطأ ما";
            } else if (this.$i18n.locale == "en") {
              this.message = "An error happened";
            }

            this.color = "error";
            this.snackbar = true;
          }
        })
        .catch((err) => {
          console.log(err);
          if (this.$i18n.locale == "ar") {
            this.message = "حدث خطأ ما";
          } else if (this.$i18n.locale == "en") {
            this.message = "An error happened";
          }

          this.color = "error";
          this.snackbar = true;
        });
    },
    handleUpdate() {
      if (!this.$refs.form.validate()) {
        return;
      }
        if(this.country == 'Algeria' && this.nationalID == ''){
        this.snackbar = true;
        this.message = this.$i18n.t("nationalIDRequired");
        this.color = 'error';
        return;
      }
      else if(this.country != 'Algeria') this.nationalID = ''
      newAxios
        .post(
          "api/users/address/update",
          {
            addressId: this.id,
            title: this.title,
            name: this.name,
            country: this.country,
            city: this.city,
            phone: this.phone,
            addressLineOne: this.addressFirst,
            addressLineTwo: this.addressSecond,
            zip: this.postal,
            nationalID: this.nationalID
          },
          {
            headers: {
              Authorization: `bearer ${auth.token()}`,
            },
          }
        )
        .then((res) => {
          this.loading = false;

          if (!res.data.error) {
            if (this.$i18n.locale == "ar") {
              this.message = "تم التحديث بنجاح";
            } else if (this.$i18n.locale == "en") {
              this.message = "Updated Successfully";
            }

            this.color = "success";
            this.snackbar = true;
            this.addrs = res.data.address;
          } else {
            if (this.$i18n.locale == "ar") {
              this.message = "حدث خطأ ما";
            } else if (this.$i18n.locale == "en") {
              this.message = "An error happened";
            }

            this.color = "error";
            this.snackbar = true;
          }
        })
        .catch((err) => {
          console.log(err);

          if (this.$i18n.locale == "ar") {
            this.message = "حدث خطأ ما";
          } else if (this.$i18n.locale == "en") {
            this.message = "An error happened";
          }

          this.color = "error";
          this.snackbar = true;
        });
    },
    checkArabic(e) {
      if (isArabic.test(e.target.value)) {
        e.target.value = "";
      }
    },
  },
};
</script>
