<template>
  <div>
    <DashboardNavbar />

    <DashboardToolbar />

    <CountrySelector :activeAddress.sync="activeAddress" />

    <v-container>
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="bars"
        color="indigo"
        :is-full-page="true"
      ></loading>

      <v-alert
        v-if="this.$route.query.payment == 'success'"
        type="success"
        dismissible
      >
        {{ $t("paymentSuccess") }}
      </v-alert>

      <v-alert
        v-if="this.$route.query.confirmed == 'confirmed' || confirmedMsg"
        type="success"
        dismissible
      >
        {{ $t("confirmedMessage") }}
      </v-alert>

      <v-alert v-if="!isConfirmed" type="warning" dismissible>
        {{ $t("confirmWarning") }}
        <span
          @click="getConfirmationEmail"
          style="text-decoration: underline; color: white; cursor: pointer"
          link
          >{{ $t("clickHere") }}</span
        >
      </v-alert>

      <v-alert v-if="limitMsg" type="error" dismissible>
        {{ $t("limitMessage") }}
      </v-alert>

      <v-alert
        v-if="this.$route.query.payment == 'cancel'"
        type="error"
        dismissible
      >
        {{ $t("paymentCancel") }}
      </v-alert>

      <v-row
        v-if="this.filteredPackages.length == 0 && this.assembleRequests.length == 0"
      >
        <v-col md="2" class="empty-col"></v-col>

        <v-col cols="12" sm="12" lg="4">
          <v-card height="100%" class="indigo">
            <div style="font-size: 20px" class="pa-3 indigo white--text">
              <v-icon
                color="white"
                :right="this.$i18n.locale == 'ar' ? true : false"
                :left="this.$i18n.locale != 'ar' ? true : false"
                >fas fa-shipping-fast</v-icon
              >
              {{ $t("lockTitle") }}
            </div>

            <v-card-text style="font-size: 15px" class="indigo white--text">
              {{ $t("lockerText") }}
            </v-card-text>

            <v-card-text class="indigo">
              <v-img
                class="mx-auto"
                style="margin-top: 80px"
                src="../assets/locker.png"
                width="180"
              />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col class="mobile-margin" cols="12" sm="12" lg="4">
          <v-card color="red darken-1 white--text">
            <div style="font-size: 20px" class="pa-3 white--text">
              <v-icon
                color="white"
                :right="this.$i18n.locale == 'ar' ? true : false"
                :left="this.$i18n.locale != 'ar' ? true : false"
                >fas fa-shopping-cart</v-icon
              >
              {{ $t("shopTitle") }}
            </div>

            <v-card-text style="font-size: 16px" class="white--text">
              {{ $t("shopText") }}
            </v-card-text>

            <v-card-text style="direction: ltr">
              <span
                style="font-weight: bold; font-size: 14px"
                class="white--text"
                >{{$i18n.locale === "ar" ? "Name" : $t("name")}}:</span
              >
              <br />
              <span
                style="font-size: 17px; display: block; margin-bottom: 8px"
                class="white--text"
                >{{ this.user.fullName }}
                <v-icon
                  v-if="this.user.fullName"
                  size="20"
                  style="cursor: pointer; color: gris"
                  @click="
                    () => {
                      copy(this.user.fullName);
                    }
                  "
                  >far fa-copy</v-icon
                ></span
              >

              <span
                style="font-weight: bold; font-size: 14px"
                class="white--text"
                >{{$i18n.locale === "ar" ? "Address Line 1" : $t("addressFirst")}}:</span
              >
              <br />
              <span
                style="font-size: 17px; display: block; margin-bottom: 8px"
                class="white--text"
                >{{ this.activeAddress.addressLineOne }}
                <v-icon
                  v-if="this.activeAddress.addressLineOne"
                  size="20"
                  style="cursor: pointer; color: gris"
                  @click="
                    () => {
                      copy(this.activeAddress.addressLineOne);
                    }
                  "
                  >far fa-copy</v-icon
                ></span
              >

              <span
                style="font-weight: bold; font-size: 14px"
                class="white--text"
                >{{$i18n.locale === "ar" ? "Address Line 2" : $t("addressSecond")}}:</span
              >
              <br />
              <span
                style="font-size: 17px; display: block; margin-bottom: 8px"
                class="white--text"
                >{{ this.activeAddress.addressLineTwo }} {{ this.userHash }}
                <v-icon
                  v-if="this.activeAddress.addressLineTwo && this.userHash"
                  size="20"
                  style="cursor: pointer; color: gris"
                  @click="
                    () => {
                      copy(
                        `${this.activeAddress.addressLineTwo} ${this.userHash}`
                      );
                    }
                  "
                  >far fa-copy</v-icon
                ></span
              >

              <span
                style="font-weight: bold; font-size: 14px"
                class="white--text"
                >{{$i18n.locale === "ar" ? "City" : $t("city")}}:</span
              >
              <br />
              <span
                style="font-size: 17px; display: block; margin-bottom: 8px"
                class="white--text"
                >{{ this.activeAddress.city }}
                <v-icon
                  v-if="this.activeAddress.city"
                  size="20"
                  style="cursor: pointer; color: gris"
                  @click="
                    () => {
                      copy(this.activeAddress.city);
                    }
                  "
                  >far fa-copy</v-icon
                ></span
              >

              <span
                style="font-weight: bold; font-size: 14px"
                class="white--text"
                >{{$i18n.locale === "ar" ? "State" : $t("state")}}:</span
              >
              <br />
              <span
                style="font-size: 17px; display: block; margin-bottom: 8px"
                class="white--text"
                >{{ this.activeAddress.state }}
                <v-icon
                  v-if="this.activeAddress.state"
                  size="20"
                  style="cursor: pointer; color: gris"
                  @click="
                    () => {
                      copy(this.activeAddress.state);
                    }
                  "
                  >far fa-copy</v-icon
                ></span
              >

              <span
                style="font-weight: bold; font-size: 14px"
                class="white--text"
                >{{$i18n.locale === "ar" ? "Postal Code" : $t("postal")}}:</span
              >
              <br />
              <span
                style="font-size: 17px; display: block; margin-bottom: 8px"
                class="white--text"
                >{{ this.activeAddress.zip }}
                <v-icon
                  v-if="this.activeAddress.zip"
                  size="20"
                  style="cursor: pointer; color: gris"
                  @click="
                    () => {
                      copy(this.activeAddress.zip);
                    }
                  "
                  >far fa-copy</v-icon
                ></span
              >

              <span
                style="font-weight: bold; font-size: 14px"
                class="white--text"
                >{{$i18n.locale === "ar" ? "Phone Number" : $t("phone")}}:</span
              >
              <br />
              <span
                style="font-size: 17px; display: block; margin-bottom: 8px"
                class="white--text"
                >{{ this.activeAddress.phone }}
                <v-icon
                  v-if="this.activeAddress.phone"
                  size="20"
                  style="cursor: pointer; color: gris"
                  @click="
                    () => {
                      copy(this.activeAddress.phone);
                    }
                  "
                  >far fa-copy</v-icon
                ></span
              >
            </v-card-text>

            <v-card-text style="font-size: 16px" class="white--text">
              <b>{{ $t("note") }}: </b>

              <ul class="mx-5 px-3 mt-3">
                <li>{{ $t("phoneNote") }}</li>
                <li class="mt-3">{{ $t("addresslineTwoNotice") }}</li>
              </ul>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <div v-else>
        <v-row>
          <Package
            v-for="userPackage in filteredPackages"
            :packages="filteredPackages"
            :flagImage="userPackage.warehouse.flagImage"
            :warehouse="activeAddress._id"
            :userPackage="userPackage"
            @updateData="updateData"
            :key="userPackage._id"
          />

          <AssemblePackage
            v-for="request in assembleRequests"
            :request="request"
            @updateData="updateData"
            :key="request._id"
          />
        </v-row>
      </div>
    </v-container>
    <v-snackbar left color="green" v-model="snackbar" timeout="2000">
      <v-icon color="white" left size="15">fas fa-copy</v-icon>
      {{ $t("copied") }}
    </v-snackbar>

    <v-snackbar left color="success" v-model="emailSnackbar" timeout="2000">
      {{ $t("emailSent") }}
    </v-snackbar>
  </div>
</template>

<script>
import DashboardToolbar from "../components/DashboardToolbar";
import Package from "../components/Package";
import DashboardNavbar from "../components/DashboardNavbar";
import AssemblePackage from "../components/AssemblePackage.vue";
import CountrySelector from "../components/CountrySelector.vue"

import axios from "axios";
import auth from "../helpers/auth";

// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

const newAxios = axios.create();
newAxios.defaults.headers.common = {};

export default {
  name: "Dashboard",
  metaInfo: {
    title: "Dashboard | Porsay",
  },
  data() {
    return {
      companyAddress: {},
      userHash: "",
      activeAddress: JSON.parse(localStorage.getItem("activeAddress")),
      packages: [],
      filteredPackages: [],
      isLoading: false,
      user: auth.user(),
      shippedPackages: [],
      snackbar: false,
      assembleRequests: [],
      isConfirmed: false,
      confirmedMsg: false,
      limitMsg: false,
      emailSnackbar: false,
    };
  },
  mounted() {
    if (this.$i18n.locale === "ar") {
      this.$vuetify.rtl = true;
    }

    auth.updateToken((confirmed) => {
      if(!confirmed)
        window.location.href = `/${this.$i18n.locale}/confirm-email`
    })

    this.userHash = auth.user().addressHash;

    newAxios.get("/api/addresses/company").then((res) => {
      this.companyAddress = res.data.addresses[0]
      this.activeAddress = localStorage.getItem('activeAddress') ? JSON.parse(localStorage.getItem('activeAddress')) : res.data.addresses[0]
      if(res.data.addresses.length == 1){
        this.activeAddress = res.data.addresses[0]
      }
    });

    
    this.isLoading = true;

    newAxios
      .get("/api/users/packages/get", {
        headers: {
          Authorization: `bearer ${auth.token()}`,
        },
      })
      .then((res) => {
        this.packages = res.data.packages;
        this.filteredPackages = this.packages
        //disabled warehouse managment temporarily
        // .filter(item => 
        //   item.warehouse._id == this.activeAddress._id)
        this.isLoading = false;
      })
      .catch((err) => {
        this.isLoading = false;
      });

    newAxios
      .get("/api/packages/group/user", {
        headers: {
          Authorization: `bearer ${auth.token()}`,
        },
      })
      .then((res) => {
        this.assembleRequests = res.data.packagesGroups
        //disabled warehouse managment temporarily
        // .filter((item) => 
        //   {
        //     return item.warehouse == this.activeAddress._id
        //   }
        // )
        })
      .catch((err) => {
        console.log(err);
      });

    newAxios
      .get("/api/packages/shipped/user", {
        headers: {
          Authorization: `bearer ${auth.token()}`,
        },
      })
      .then((res) => {
        this.shippedPackages = res.data.packages;
      })
      .catch((err) => {});

    newAxios
      .get("/api/users/confirmation-status", {
        headers: {
          Authorization: `bearer ${auth.token()}`,
        },
      })
      .then((res) => {
        this.isConfirmed = res.data.status;
      })
      .catch((err) => {
        // console.log(err);
        // localStorage.removeItem("token");
        // this.$router.push('/' + this.$i18n.locale + '/login');
      });
  },
  components: {
    Package,
    DashboardNavbar,
    Loading,
    DashboardToolbar,
    AssemblePackage,
    CountrySelector
  },
  methods: {
    getConfirmationEmail() {
      this.isLoading = true;
      newAxios
        .get("/api/users/generate-confirmation", {
          headers: {
            Authorization: `bearer ${auth.token()}`,
          },
        })
        .then((res) => {
          if (!res.data.error) {
            this.emailSnackbar = true;
          } else {
            if (res.data.message == "User Confirmed") {
              this.confirmedMsg = true;
            }
            if (res.data.message == "Limit Exceeded") {
              this.limitMsg = true;
            }
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },
    updateData() {
      newAxios
        .get("/api/users/packages/get", {
          headers: {
            Authorization: `bearer ${auth.token()}`,
          },
        })
        .then((res) => {
          this.packages = res.data.packages;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
        });

      newAxios
        .get("/api/packages/group/user", {
          headers: {
            Authorization: `bearer ${auth.token()}`,
          },
        })
        .then((res) => {
          this.assembleRequests = res.data.packagesGroups;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    copy(text) {
      try {
        navigator.clipboard.writeText(text);
        this.snackbar = true;
      } catch (error) {
        throw error;
      }
    },
  },
  watch:{
    activeAddress(val){
      //disabled warehouse managment temporarily

      // this.filteredPackages = this.packages.filter(item => 
      // item.warehouse._id == val._id)

      // newAxios
      //       .get("/api/packages/group/user", {
      //         headers: {
      //           Authorization: `bearer ${auth.token()}`,
      //         },
      //       })
      //       .then((res) => {
      //         this.assembleRequests = res.data.packagesGroups.filter((item) => 
      //           {
      //             return item.warehouse == val._id
      //           }
      //         )
      //         })
      //       .catch((err) => {
      //         console.log(err);
      //       });
    }
  }
};
</script>

<style>
@media screen and (max-width: 500px) {
  .empty-col {
    display: none;
  }

  .mobile-margin {
    margin-bottom: 65px;
  }
}
</style>
