<template>
    <div class="main-footer">
      <v-container fluid class="pa-10 footer-bg">

            <v-row class="mt-10">
              <v-col cols="12" md="6" lg="4">
                <v-img src="../assets/Footer-Logo.svg" width="150" />

                <div style="font-size:17.5px" class="white--text mt-7">
                    {{$t('footerDesc')}}
                </div>

                    <v-select
                            outlined
                            class="pa-0 mt-4"
                            style="width:100px;padding:0"
                            :label="$i18n.locale.toUpperCase()"
                            v-on="on"
                            :items="['EN','AR','FR']"
                    ></v-select>

   
              </v-col>

              <v-col class="mobile-footer mt-5" cols="12" md="12" lg="4">

                <h3 style="font-size:22px" class="white--text">{{$t('customerService')}}</h3>
                <div class="mt-7" style="font-size:18px" >
                  <router-link :to="'/' + $i18n.locale + '/faq'" style="text-decoration:none;color:white">- {{$t('FAQ')}}</router-link>
                </div>

                <div class="mt-4" style="font-size:18px" >
                  <router-link :to="'/' + $i18n.locale + '/support'" style="text-decoration:none;color:white">- {{$t('contactUs')}}</router-link>
                </div>

                <div class="mt-4" style="font-size:18px" >
                  <router-link :to="'/' + $i18n.locale + '/calculator'" style="text-decoration:none;color:white">- {{$t('shippingCalc')}}</router-link>
                </div>
              </v-col>

              <v-col style="font-size:20px" class="mt-5 mobile-footer" cols="12" md="12" lg="4">
                <h3  class="white--text">{{$t('terms')}}</h3>

                <div class="mt-7" style="font-size:18px" >
                  <router-link :to="'/' + $i18n.locale + '/banned-material'" style="text-decoration:none;color:white">- {{$t('prohibitedItems')}}</router-link>
                </div>

                <div class="mt-4" style="font-size:18px" >
                  <router-link :to="'/' + $i18n.locale + '/legal-info'" style="text-decoration:none;color:white">- {{$t('legalData')}}</router-link>
                </div>

                <div class="mt-4" style="font-size:18px" >
                  <router-link :to="'/' + $i18n.locale + '/privacy-policy'" style="text-decoration:none;color:white">- {{$t('privacyPolicy')}}</router-link>
                </div>
              </v-col>
            </v-row>
            
            <div class="text-center white--text">
            </div>

            <div class="d-flex justify-center align-center mt-4">
              <v-avatar class="mx-4" color="#445069"> <v-btn href="https://www.facebook.com/porsaycom" target="_blank" icon><v-icon color="white" class="ma-2" size="30">fab fa-facebook-square</v-icon></v-btn></v-avatar>
              <v-avatar class="mx-4" color="#445069"><v-btn href="https://twitter.com/porsaycom" target="_blank" icon><v-icon color="white" class="ma-2" size="30">fab fa-twitter-square</v-icon></v-btn></v-avatar>
              <v-avatar class="mx-4" color="#445069"><v-btn href="https://www.instagram.com/porsay.fr/" target="_blank" icon><v-icon color="white" class="ma-2" size="30">fab fa-instagram</v-icon></v-btn></v-avatar>
              <v-avatar class="mx-4" color="#445069"><v-btn href="https://www.snapchat.com/add/porsaycom" target="_blank" icon><v-icon color="white" class="ma-2" size="30">fab fa-snapchat-ghost</v-icon></v-btn></v-avatar>

            </div>

            <div style="font-size:18px" class="mt-10 text-center white--text">{{$t('copyrights')}}</div>
      </v-container>
    </div>
</template>

<script>
export default {
    name:'Footer'
}
</script>

<style scoped>
.footer-bg{
  background-image:url('../assets/footer-background.png');
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;

}

.mobile-footer{
  padding:0 40px;
}


@media screen and (max-width: 768px) {
  
  .mobile-footer{
    padding:0!important;
  }
}

.main-footer{
  
  background-image: linear-gradient(-180deg, #19356B 0%, #09142E 100%);  
}
</style>