<template>
  <v-container>
    <v-toolbar>
          <v-tabs
            v-model="tab"
            align-with-title
          >
            <v-tab
              v-for="item in items"
              :key="item.value"
            >
              {{ item.label }}
            </v-tab>
          </v-tabs>
      </v-toolbar>

      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="item in items"
          :key="item.value"
        >
        </v-tab-item>
      </v-tabs-items>
      <v-data-table
        :headers="tableHeaders"
        :items="tab == 1 ? approvedRequests : requests"
        :page="tab == 1 ? approvedRequestsPage : page"
        class="elevation-2 grey lighten-5 ma-3"
        @update:page="pageUpdateFunction"
        @update:items-per-page="itemsPerPageUpdateFunction"
        :items-per-page.sync="itemsPerPage"
        :server-items-length="tab == 1 ? approvedRequestCount : requestCount"
      >
        <template v-slot:top>
          <v-row class="d-flex align-center">
            <v-col :cols="10">
              <v-text-field
                v-model="search"
                placeholder="Search"
                class="mx-4"
              ></v-text-field>
            </v-col>
            <v-col :cols="2">
              <v-btn class="mx-4" @click="searchUpdateFunction" color="primary"
                >Search</v-btn
              >
            </v-col>
          </v-row>
        </template>

        <template v-slot:item.trackNumber="{ item }">
          <router-link
            style="text-decoration: none; font-size: 16px; color: #2e86c1"
            :to="'/en/admin/user/' + item.user.id"
          >
            <v-icon
              color="red"
              class="mx-3"
              size="15"
              v-if="item.user ? item.user.suspicious : false"
              >fas fa-exclamation-triangle</v-icon
            >
            {{ item.user ? item.user.fullName : "" }}
          </router-link>
        </template>

        <template v-slot:item.userHash="{ item }">
          <div>{{ item.user.addressHash }}</div>
        </template>

        <template v-slot:item.packageId="{ item }">
          <router-link
            style="text-decoration: none; font-size: 16px; color: #2e86c1"
            :to="'/en/admin/manage-packages?packageId=' + item.package.packageId"
            >{{ item.package ? item.package.packageId : "" }}</router-link
          >
        </template>

        <template v-slot:item.date="{ item }">
          <div>{{ formatDate(item.createdAt) }}</div>
        </template>

        <template v-slot:item.actions="{ item }">
          <div>
            <v-btn
              @click="showPackages(item)"
              class="ma-2"
              small
              tile
              outlined
              color="primary"
            >
              <v-icon size="15" left>fas fa-eye</v-icon> show
            </v-btn>

            <v-btn
              v-if="!item.complete && user.groupId === adminId"
              class="ma-2"
              @click="cancelRequest(item)"
              small
              tile
              outlined
              color="warning"
            >
              <v-icon size="15" left>fas fa-trash</v-icon> Cancel
            </v-btn>
          </div>
        </template>

        <template v-slot:footer>
          <v-row justify="center">
            <v-col cols="8">
              <v-container class="max-width">
                <v-pagination
                  v-if="tab == 1"
                  v-model="approvedRequestsPage"
                  class="my-4"
                  :total-visible="7"
                  :length="approvedRequestsPageCount"
                  @input="pageUpdateFunction"
                ></v-pagination>
                <v-pagination
                  v-else
                  v-model="page"
                  class="my-4"
                  :total-visible="7"
                  :length="pageCount"
                  @input="pageUpdateFunction"
                ></v-pagination>
              </v-container>
            </v-col>
          </v-row>
        </template>
      </v-data-table>

    <v-dialog v-model="deleteDialog" max-width="600">
      <v-card>
        <v-card-title class="headline">Please confirm your action</v-card-title>
        <v-spacer></v-spacer>
        <v-card-text>
          <v-alert border="top" colored-border type="error" elevation="2">
            Are you sure that you want to delete this request ?
          </v-alert>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            depressed
            @click="handleDeleteRequest(selectedRequest._id)"
            color="error"
            >Delete</v-btn
          >
          <v-btn
            @click="deleteDialog = !deleteDialog"
            class="ma-2"
            color="grey white--text"
            >close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" max-width="800">
      <v-card class="pa-3">
        <v-card-title>Disassemble Request</v-card-title>
        <v-card-text>
          <v-row>
            <v-col style="font-size: 16px" sm="6"
              >Username:
              <b>{{
                selectedRequest.user ? selectedRequest.user.fullName : ""
              }}</b></v-col
            >
            <v-col style="font-size: 16px" sm="6"
              >User Hash:
              <b>{{
                selectedRequest.user ? selectedRequest.user.addressHash : ""
              }}</b></v-col
            >

            <v-col sm="6" class="ma-1" style="font-size: 16px">
              <div>
                <b>Remove bills:</b>
                <span :style="selectedRequest.removeBills ? '' : 'color:red'">{{
                  selectedRequest.removeBills ? "Yes" : "No"
                }}</span>
              </div>
              <div class="mt-2">
                <b>Remove shoe boxes:</b>
                <span
                  :style="selectedRequest.removeShoeBoxes ? '' : 'color:red'"
                  >{{ selectedRequest.removeShoeBoxes ? "Yes" : "No" }}</span
                >
              </div>
            </v-col>
          </v-row>

          <div>
            <span style="font-size: 17px; font-weight: bold"
              >Disassemble Notes:
            </span>
            <p class="pa-2" style="font-size: 20px">
              {{ selectedRequest.note }}
            </p>
          </div>

          <v-card
            class="pa-0 grey my-4 lighten-4"
            v-if="this.selectedRequest.package"
            v-viewer
          >
            <v-row>
              <v-col sm="6">
                <img
                  height="90"
                  class="ma-2 elevation-3"
                  :src="
                    backendUrl + '/' + this.selectedRequest.package.images[0]
                  "
                />
              </v-col>

              <v-col sm="6">
                <div class="mx-4 my-2" style="font-size: 16px">
                  <b>Track Number: </b>
                  <span>{{ this.selectedRequest.package.trackNumber }}</span>
                </div>
                <div class="mx-4 my-2" style="font-size: 16px">
                  <b>Weight: </b>
                  <span>{{ this.selectedRequest.package.weight }} kg</span>
                </div>
                <div class="mx-4 my-2" style="font-size: 16px">
                  <b>dimensions: </b>
                  <span
                    >{{ this.selectedRequest.package.length }} x
                    {{ this.selectedRequest.package.width }} x
                    {{ this.selectedRequest.package.height }} cm
                  </span>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-card-text>

        <v-card-text>
          <v-row v-for="detail in details" :key="detail">
            <v-col>
              <v-text-field
                v-model="detail.description"
                name="description"
                :label="$t('description')"
                id="description"
                type="text"
                color="indigo"
                readonly
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="detail.quantity"
                name="quantity"
                :label="$t('quantity')"
                id="quantity"
                type="number"
                min="0"
                color="indigo"
                readonly
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="detail.price"
                name="price"
                :label="$t('price')"
                id="price"
                type="number"
                min="0"
                color="indigo"
                readonly
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="detail.madeIn"
                name="madeIn"
                label="Where was the item made in?"
                id="madeIn"
                type="text"
                color="indigo"
                readonly
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="detail.type"
                name="type"
                label="Type"
                id="type"
                type="text"
                color="indigo"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text>
          <span style="font-size: 17px; font-weight: bold">Packages: </span>

          <v-card
            class="pa-0 grey my-4 lighten-4"
            v-for="(pack,index) in packages"
            :key="pack"
            v-viewer
          >
            <v-row>
              <v-col sm="6">
                <img
                  height="90"
                  class="ma-2 elevation-3"
                  :src="renderImage(pack.images[0])"
                />
              </v-col>

              <v-col sm="6">
                <div class="mx-4 my-2" style="font-size: 16px">
                  <b>Track Number: </b> <span>{{ selectedRequest.package.trackNumber + `-${index+1}` }}</span>
                </div>
                <div class="mx-4 my-2" style="font-size: 16px">
                  <b>Weight: </b> <span>{{ pack.weight }} kg</span>
                </div>
                <div class="mx-4 my-2" style="font-size: 16px">
                  <b>dimensions: </b>
                  <span
                    >{{ pack.length }} x {{ pack.width }} x {{ pack.height }} cm
                  </span>
                </div>
                <div class="d-flex justify-end px-7 pa-2">
                  <v-btn
                    icon
                    right
                    color="red"
                    @click="deletePackage(pack.trackNumber)"
                    ><v-icon>fas fa-trash</v-icon></v-btn
                  >
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-card-text>

        <v-card-text v-if="!selectedRequest.complete">
          <v-btn @click="addDialog = !addDialog" class="indigo white--text"
            >Add Package</v-btn
          >
        </v-card-text>

        <v-card-actions
          v-if="!loading && !selectedRequest.complete"
          class="d-flex justify-end"
        >
          <v-btn @click="handleSubmit" class="ma-2" color="success white--text"
            >Accept</v-btn
          >
          <v-btn
            @click="
              () => {
                dialog = false;
                addDialog = false;
              }
            "
            class="ma-2"
            color="grey white--text"
            >cancel</v-btn
          >
        </v-card-actions>

        <v-card-actions v-if="loading" class="d-flex justify-center">
          <v-progress-circular
            :indeterminate="true"
            size="40"
            color="indigo"
          ></v-progress-circular>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="cancelDialog" max-width="600">
      <v-card>
        <v-card-title class="headline">Please confirm your action</v-card-title>
        <v-spacer></v-spacer>
        <v-card-text>
          <v-alert border="top" colored-border type="warning" elevation="2">
            Are you sure that you want to cancel this request ?
          </v-alert>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            depressed
            @click="handleCancelRequest(selectedRequest._id)"
            color="error"
            >Cancel Request</v-btn
          >
          <v-btn
            @click="cancelDialog = !cancelDialog"
            class="ma-2"
            color="grey white--text"
            >close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <AddPackageDialog
      v-model="addDialog"
      :callback="addPackage"
      :userId="selectedRequest.user ? selectedRequest.user._id : ''"
    />

    <v-snackbar left :color="color" v-model="snackbar">{{
      message
    }}</v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import auth from "../helpers/auth";
import { backendUrl } from "../config/config";

import AddPackageDialog from "../components/AddPackageDialog.vue";

export default {
  name: "Manage Disassemble",
  components: {
    AddPackageDialog,
  },
  mounted() {
    axios
      .get("/api/packages/requests/disassemble?complete=false")
      .then((res) => {
        this.requestCount = res.data.requestCount;
        this.pageCount = res.data.pageCount;
        this.requests = res.data.requests;
      })
    axios
      .get("/api/packages/requests/disassemble?complete=true")
      .then((res) => {
        this.approvedRequestCount = res.data.requestCount;
        this.approvedRequestsPageCount = res.data.pageCount;
        this.approvedRequests = res.data.requests;
      })
      .catch((err) => {
        this.snackbar = true;
        this.message = "An error happened please try again";
        this.color = "error";
        console.log(err);
      });
  },

  watch: {
    search: function (newSearch) {
      if (newSearch === "") {
        axios.get("/api/packages/requests/disassemble?complete=false").then((res) => {
          this.page = 1;
          this.requestCount = res.data.requestCount;
          this.pageCount = res.data.pageCount;
          this.requests = res.data.requests;
        });
        axios.get("/api/packages/requests/disassemble?complete=true").then((res) => {
          this.approvedRequestsPage = 1;
          this.approvedRequestCount = res.data.requestCount;
          this.approvedRequestsPageCount = res.data.pageCount;
          this.approvedRequests = res.data.requests;
        });
      }
    },
  },

  data() {
    return {
      user: auth.admin(),
      adminId: 1,
      supportId: 2,
      page: 1,
      approvedRequestsPage: 1,
      pageCount: 5,
      approvedRequestsPageCount: 5,
      requestCount: 1,
      approvedRequestCount: 1,
      itemsPerPage: 10,
      addDialog: false,
      tableHeaders: [
        {
          text: "Username",
          value: "trackNumber",
          align: "center",
        },
        {
          text: "User Hash",
          value: "userHash",
          align: "center",
        },
        {
          text: "Request Date",
          value: "date",
          align: "center",
        },
        {
          text: "Package ID",
          value: "packageId",
          align: "center",
        },
        {
          text: "Actions",
          value: "actions",
          align: "center",
        },
      ],
      requests: [],
      approvedRequests: [],
      tab: null,
      items: [
        {label: 'Awaiting Approval', value: 0}, {label: 'Approved', value: 1}, 
      ],
      dialog: false,
      cancelDialog: false,
      deleteDialog: false,
      snackbar: false,
      message: "",
      color: "",
      loading: false,
      search: "",
      selectedRequest: {},
      backendUrl,
      addedPackages: [],

      //accept assemble
      acceptDialog: false,
      height: "",
      width: "",
      weight: "",
      length: "",
      packages: [],
      price: "",
      addedPakcages: [],
      details: [
        {
          description: "",
          madeIn: "",
          type: "",
          quantity: 0,
          price: 0,
          index: 0,
        },
      ],
      acceptLoading: false,
      revertDialog: false,
    };
  },


  methods: {
    // customFilter (value, queryText, item) {

    //     const addressHash = item.user.addressHash.toLowerCase()
    //     const username = item.user.fullName.toLowerCase()
    //     const searchText = queryText.toLowerCase()

    //     return addressHash.indexOf(searchText) > -1 || username.indexOf(searchText) > -1
    // },

    renderImage(img) {
      // Check if file is an image
      const imgData = URL.createObjectURL(img);
      console.log(imgData);
      return imgData;
    },
    pageUpdateFunction(newPageNumber) {
      if(this.search == ""){  
        if(this.tab != 1){
          axios
            .get(
              `/api/packages/requests/disassemble?complete=false&page=${
                newPageNumber - 1
              }&perPage=${this.itemsPerPage}`
            )
            .then((res) => {
              this.requestCount = res.data.requestCount;
              this.pageCount = res.data.pageCount;
              this.requests = res.data.requests;
              this.page = newPageNumber;
            });
        }
        else{
          axios
            .get(
              `/api/packages/requests/disassemble?complete=true&page=${
                newPageNumber - 1
              }&perPage=${this.itemsPerPage}`
            )
            .then((res) => {
              this.approvedRequestCount = res.data.requestCount;
              this.approvedRequestsPageCount = res.data.pageCount;
              this.approvedRequests = res.data.requests;
              this.approvedRequestsPage = newPageNumber;
            });
        }
      }
      else {
        // TODO: Search by page
      }
    },

    addPackage(pack) {
      this.packages.push(pack);
      this.addDialog = false;
    },
    deletePackage(trackNumber) {
      const index = this.packages.findIndex(
        (pack) => pack.trackNumber === trackNumber
      );

      if (index !== -1) {
        this.packages.splice(index, 1);
      }
    },
    itemsPerPageUpdateFunction(length) {
      this.page = 1;
      this.pageCount = 1;
      this.approvedRequestsPageCount = 1;

      axios
        .get(
          `/api/packages/requests/disassemble?complete=false&page=${
            this.pageCount - 1
          }&perPage=${length}`
        )
        .then((res) => {
          this.requestCount = res.data.requestCount;
          this.pageCount = res.data.pageCount;
          this.requests = res.data.requests;
        });
      axios
        .get(
          `/api/packages/requests/disassemble?complete=true&page=${
            this.approvedRequestsPageCount - 1
          }&perPage=${length}`
        )
        .then((res) => {
          this.approvedRequestCount = res.data.requestCount;
          this.approvedRequestsPageCount = res.data.pageCount;
          this.approvedRequests = res.data.requests;
        });
    },

    searchUpdateFunction() {
      const newQuery = this.search;
      if (newQuery) {
        axios
          .get(`/api/packages/requests/disassemble?query=${newQuery}&complete=false`)
          .then((res) => {
            this.requestCount = res.data.requestCount;
            this.pageCount = res.data.pageCount;
            this.requests = res.data.requests;
          });
        axios
          .get(`/api/packages/requests/disassemble?query=${newQuery}&complete=true`)
          .then((res) => {
            this.approvedRequestCount = res.data.requestCount;
            this.approvedRequestsPageCount = res.data.pageCount;
            this.approvedRequests = res.data.requests;
          });
      }
    },

    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear(),
        hour = d.getHours(),
        mins = d.getMinutes();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      if (mins < 10) mins = "0" + mins;
      if (hour < 10) hour = "0" + hour;

      return [year, month, day].join("-") + ` ${hour}:${mins}`;
    },

    showPackages(request) {
      this.selectedRequest = request;
      this.dialog = true;
    },

    cancelRequest(request) {
      this.dialog = false;
      this.addDialog = false;

      this.selectedRequest = request;
      this.cancelDialog = true;
    },

    revertRequest(request) {
      this.dialog = false;
      this.deleteDialog = false;
      this.cancelRequest = false;

      this.selectedRequest = request;
      this.revertDialog = true;
    },

    handleCancelRequest(id) {
      axios
        .post("/api/packages/requests/disassemble/cancel", { requestId: id })
        .then((res) => {
          if (!res.error) {
            this.message = "Request was canceled Successfully";
            this.color = "warning";
            this.snackbar = true;

            this.requests = this.requests.filter((item) => {
              return item._id != id;
            });
            this.cancelDialog = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.message = "An error occurred please try again later";
          this.color = "error";
          this.snackbar = true;
        });
    },

    onFilesPicked(f) {
      this.images = f;
    },
    async handleSubmit() {
      this.loading = true;

      let count = 1;
      for (const pack of this.packages) {
        const data = new FormData();

        data.append("height", pack.height);
        data.append("weight", pack.weight);
        data.append("length", pack.length);
        data.append("width", pack.width);
        data.append("fees", pack.fees);
        data.append("trackNumber", this.selectedRequest.package.trackNumber + `-${count++}`);
        data.append("userId", pack.userId);

        data.append("fromDisassemble", true);

        pack.images.forEach((item) => {
          data.append("images", item);
        });

        data.append("shippingOptions", pack.shippingOptions);
        data.append("details", pack.details);

        try {
          const res = await axios.post("/api/packages/add", data);
          this.loading = false;

          if (!res.data.error) {
            this.addedPackages.push(res.data.orderedPackage._id);
          } else {
            this.message = `An error happened adding ${pack.trackNumber}, please try again`;
            this.color = "error";
            this.snackbar = true;
            return;
          }
        } catch (err) {
          console.log(err);
          this.loading = false;
          this.message = "An error occurred, please try again later";
          this.color = "error";
          this.snackbar = true;
          return;
        }
      }

      try {
        const res = await axios.post(
          `/api/packages/requests/disassemble/${this.selectedRequest._id}`,
          {
            addedPackages: this.addedPackages,
          }
        );

        if (!res.data.error) {
          this.message = `Request was disassembled Successfully`;
          this.color = "indigo";
          this.snackbar = true;
          
          // Update status after approving
          this.requests = this.requests.filter((item) => {
              return item._id != this.selectedRequest._id;
          });
          this.selectedRequest.complete = true
          this.approvedRequests.push(this.selectedRequest)

          this.dialog = false;
          this.addDialog = false;
          this.addedPackages = [];
          this.selectedRequest = {};
          this.packages = [];
        } else {
          this.message = `An error happened disassembling request, please try again`;
          this.color = "error";
          this.snackbar = true;
        }
      } catch (err) {
        console.log(err);
        this.message = `An error happened disassembling request, please try again`;
        this.color = "error";
        this.snackbar = true;
      }
    },
  },
};
</script>