<template>
    <v-container>

        <h3>Deleted Packages</h3>
        <v-data-table
            :headers="tableHeaders"
            :items="packages"
            class="elevation-2 grey lighten-5 ma-3"
            :page.sync="page"
            :server-items-length="packageCount"
            @update:page="pageUpdateFunction"
            @update:items-per-page="itemsPerPageUpdateFunction"
            :items-per-page.sync="itemsPerPage"
        
        >
            <template v-slot:top class="align-center">
                <v-row class="d-flex align-center">
                    <v-col :cols="10">
                        <v-text-field v-model="query" placeholder="Search" class="mx-4"></v-text-field>
                    </v-col>
                    <v-col :cols="2">
                        <v-btn class="mx-4" @click="searchUpdateFunction" color="primary">Search</v-btn>
                    </v-col>
                </v-row>
            </template>

            <template v-slot:item.username="{ item }">
                <router-link 
                    style="text-decoration:none;font-size:16px;color:#2E86C1"
                    :to="'/en/admin/user/' + item.user.id"
                >
                    {{(item.user) ? item.user.fullName : ''}}
                </router-link>
            </template>

             <template v-slot:item.userHash="{ item }">
                <div>{{(item.user)? item.user.addressHash :''}}</div>
            </template>

            <template v-slot:item.trackNumber="{ item }">
                <div>{{item.trackNumber}}</div>
            </template>

            <template v-slot:item.packageId="{ item }">
                <div>{{item.packageId}}</div>
            </template>

            <template v-slot:item.status="{ item }">
                <div>{{(item.status)? 'Yes' : 'No'}}</div>
            </template>

            <template v-slot:item.assemble="{ item }">
                <div>{{(item.assemble)? 'Yes' : 'No'}}</div>
            </template>


            <template v-slot:item.date="{ item }">
                <div>{{ formatDate(item.createdAt)}}</div>
            </template>

            <template v-slot:item.deletedBy="{ item }">
                <div>{{ item.deletedBy }}</div>
            </template>

            <template v-slot:item.deletedAt="{ item }">
                <div>{{ formatDate(item.deletedAt)}}</div>
            </template>

            

            <template v-slot:item.actions="{ item }">
                <div>
                    <v-btn @click="showPackage(item)"  class="ma-2" small tile outlined color="primary">
                        <v-icon size="15" left>fas fa-eye</v-icon> Show
                    </v-btn>
                </div>
            </template>

            <template v-slot:footer>
                <v-row justify="center">
                    <v-col cols="8">
                        <v-container class="max-width">
                            <v-pagination
                            v-model="page"
                            class="my-4"
                            :total-visible="7"
                            :length="pageCount"
                            @input="pageUpdateFunction"
                            ></v-pagination>
                        </v-container>
                    </v-col>
                </v-row>
            </template>

        </v-data-table>

             <v-dialog v-model="showDialog" max-width="600">
                    <v-card class="pa-3">
                        <v-card-title>Information</v-card-title>
                        <v-card-text >
                            <p style="font-size:15px"><b>Delete Reason:</b></p>
                            <div class="red--text">{{ selectedPackage.deleteReason }}</div>
                        </v-card-text>
                        <v-card-text>
                            <div class="ma-1" style="font-size:16px">
                                <b>Package Id:</b> <span>{{selectedPackage.packageId}}</span>
                            </div>
                            <div class="ma-1" style="font-size:16px">
                                <b>Track Number:</b> <span>{{selectedPackage.trackNumber}}</span>
                            </div>
                            <div class="ma-1" style="font-size:16px">
                                <b>Weight:</b> <span>{{selectedPackage.weight}} kg</span>
                            </div>
                            <div class="ma-1" style="font-size:16px">
                                <b>Dimensions:</b> <span>{{selectedPackage.length}} * {{selectedPackage.width}} * {{selectedPackage.height}} cm</span>
                            </div>
                            
                            <template v-for="detail in selectedPackage.details" >
                                <v-card>
                                    <v-card-text>
                                        <div class="ma-1" style="font-size:16px">
                                            <b>Description:</b> <span>{{detail.description}}</span>
                                        </div>

                                        <div class="ma-1" style="font-size:16px">
                                            <b>Made In:</b> <span>{{detail.madeIn}}</span>
                                        </div>

                                        <div class="ma-1" style="font-size:16px">
                                            <b>Type:</b> <span>{{detail.type}}</span>
                                        </div>

                                        <div class="ma-1" style="font-size:16px">
                                            <b>Quantity:</b> <span>x{{detail.quantity}}</span>
                                        </div>

                                        <div class="ma-1" style="font-size:16px">
                                            <b>price:</b> <span>{{detail.price}}</span>
                                        </div>
                                    </v-card-text>
                                </v-card>
                                
                            </template>

                            <div class="ma-2" style="font-size:20px;font-weight:bold">Images</div>

                            <v-row>
                                <v-col class="images" v-viewer v-for="img in selectedPackage.images" :key="img" sm="4">
                                    <img height="150" class="ma-2 elevation-3" :src="backendUrl + '/' + img"></img>
                                </v-col>
                            </v-row>

                        </v-card-text>
                        <v-card-text v-if="selectedPackageGroup != null && selectedPackageGroup.length > 0">
                            <b>Grouped with:</b>
                            <v-card class="pa-0 ma-4 grey lighten-4" v-for="pack in selectedPackageGroup" v-viewer :key="pack.id" >
                                <v-row>
                                    <v-col sm="6">
                                        <img height="150" class="ma-2 elevation-3" :src="backendUrl + '/' + pack.images[0]"></img>
                                    </v-col>

                                    <v-col sm="6">
                                        <div class="ma-1" style="font-size:16px"><b>Track Number: </b> <span>{{pack.trackNumber}}</span></div>
                                        <div class="ma-1" style="font-size:16px"><b>Weight: </b> <span>{{pack.weight}} kg</span></div>
                                        <div class="ma-1" style="font-size:16px"><b>dimensions: </b> <span>{{pack.length}} x {{pack.width}} x {{pack.height}} cm </span></div>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-card-text>

                        <v-card-actions class="d-flex justify-end">
                        <v-btn @click="showDialog=!showDialog" class="ma-2" color="grey white--text">cancel</v-btn>
                        </v-card-actions>

                    </v-card>
            </v-dialog>



        <v-snackbar left :color="color" v-model="snackbar">
                {{ message }}
            </v-snackbar>
    </v-container>
</template>

<script>
import axios from 'axios';
import auth from '../helpers/auth';
import {backendUrl} from '../config/config';

export default {

    name:'DeletedPackages',
    data(){
        return {
            user: auth.admin(),
            adminId: 1,
            supportId: 2,
            page: 1,
            pageCount: 5,
            packageCount: 1,
            itemsPerPage: 10,
            tableHeaders:[
                {
                    text:'Username',
                    value:'username',
                    align:'center'
                },
                {
                    text:'User Hash',
                    value:'userHash',
                    align:'center'
                },
                {
                    text:'Track Num',
                    value:'trackNumber',
                    align:'center'
                },
                {
                    text:'Pack Id',
                    value:'packageId',
                    align:'center'
                },
                {
                    text:'Date',
                    value:'date',
                    align:'center'
                },
                {
                    text:'Shipped?',
                    value:'status',
                    align:'center'
                },
                {
                    text:'Assemble?',
                    value:'assemble',
                    align:'center'
                },
                {
                    text:'DeletedAt',
                    value:'deletedAt',
                    align:'center'
                },
                {
                    text:'DeletedBy',
                    value:'deletedBy',
                    align:'center'
                },
                {
                    text:'Actions',
                    value:'actions',
                    align:'center'
                }
            ],
            packages:[],
            snackbar:false,
            message:'',
            color:'',
            dialog:false,
            selectedPackage:'',
            selectedPackageGroup: {},
            loading:false,
            showDialog:false,
            imgDialog: false,
            deleteDialog: false,
            displayImg: '',
            backendUrl,
            images:[],
            query: '',
            search:'',
            valid:false
        }
    },
    mounted(){
        const urlParams = new URLSearchParams(window.location.search);
        const packageId = urlParams.get('packageId');
        if(packageId) {
            axios.get('/api/packages/get/all?packageId='+packageId+'&deleted=1').then(res => {
                this.packageCount = res.data.packageCount
                this.pageCount = res.data.pageCount
                this.packages = res.data.packages
            })
        } else {

            axios.get('/api/packages/get/all?deleted=1').then(res => {
                this.packageCount = res.data.packageCount
                this.pageCount = res.data.pageCount
                this.packages = res.data.packages
            })
        }

    },

    watch: {
        query: function (newQuery) {
            if (newQuery === '') {
                axios.get('/api/packages/get/all?deleted=1').then(res => {
                    this.page = 1
                    this.packageCount = res.data.packageCount
                    this.pageCount = res.data.pageCount
                    this.packages = res.data.packages
                })
            }
        }
    },

    sockets: {
        updatePackage(data) {
            const index = this.packages.findIndex(pack => pack._id == data.package._id)
            this.packages[index].details = data.package.details
        }
    },

    methods:{
         formatDate(date) {
            var d = new Date(date) ,
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear(),
                hour = d.getHours(),
                mins = d.getMinutes(),
                secs = d.getSeconds();
            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;
            if(mins < 10)
            mins = '0' + mins
            if(secs < 10)
            secs = '0' + secs
            if(hour < 10)
            hour = '0' + hour
            return [year, month, day].join('-') + ` ${hour}:${mins}:${secs}`;
        },

    pageUpdateFunction(newPageNumber) {
        axios.get(`/api/packages/get/all?page=${newPageNumber-1}&perPage=${this.itemsPerPage}&deleted=1`).then(res=>{
            this.packageCount = res.data.packageCount
            this.pageCount = res.data.pageCount
            this.packages = res.data.packages

        })

    },
    itemsPerPageUpdateFunction(length) {
        this.page= 1
        this.pageCount = 1
        axios.get(`/api/packages/get/all?page=${this.pageCount -1}&perPage=${length}&deleted=1`).then(res=>{
            //console.log(res.data)
            this.pageCount = res.data.pageCount
            this.packages = res.data.packages
            this.itemsPerPage = length

        })

    },

    searchUpdateFunction(){
        const newQuery = this.query
        if (newQuery) {
            axios.get(`/api/packages/get/all?query=${newQuery}&deleted=1`).then(res => {
               this.packageCount = res.data.packageCount
               this.pageCount = res.data.pageCount
               this.packages = res.data.packages
           })
        }
    },

    async showPackage(pack){
        const res = await axios.get(`/api/packages/assemble/${pack._id}`)

        // verify if packagesGroup != null
        res.data.packagesGroup > 0 && res.data.packagesGroup[0] && res.data.packagesGroup[0] ? this.selectedPackageGroup = this.selectedPackageGroup : this.selectedPackageGroup = null
        
        this.selectedPackage = pack;
        this.showDialog = true;
    },
      onFilesPicked(f){
          this.images = f;
      }
  }
}
</script>