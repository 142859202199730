var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DashboardNavbar'),_c('v-container',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"name":"firstName","label":_vm.$t('firstName'),"id":"firstName","type":"text","color":"indigo","rules":[
          function (v) { return !!v || _vm.$t('firstNameRequired'); },
          function (v) { return (!!v && _vm.checkName(v)) || _vm.$t('invalidFirstName'); } ],"required":""},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}}),_c('v-text-field',{attrs:{"name":"laastName","label":_vm.$t('lastName'),"id":"lastName","type":"text","color":"indigo","rules":[
          function (v) { return !!v || _vm.$t('lastNameRequired'); },
          function (v) { return (!!v && _vm.checkName(v)) || _vm.$t('invalidLastName'); } ],"required":""},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}}),_c('v-text-field',{staticStyle:{"direction":"ltr"},attrs:{"name":"phone","label":_vm.$t('phone'),"id":"phone","type":"text","color":"indigo","rules":[
          function (v) { return !!v || _vm.$t('phoneRequired'); },
          function (v) { return this$1.phoneRe.test(String(v).toLowerCase()) || _vm.$t('phoneValid'); } ],"disabled":""},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('v-text-field',{attrs:{"name":"email","label":_vm.$t('email'),"id":"email","type":"email","color":"indigo","rules":[
          function (v) { return !!v || _vm.$t('emailRequired'); },
          function (v) { return this$1.emailRe.test(String(v).toLowerCase()) || _vm.$t('validEmail'); } ],"disabled":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-spacer'),_c('v-spacer'),(_vm.loading)?_c('div',{staticClass:"d-flex justify-center"},[_c('v-progress-circular',{attrs:{"indeterminate":true,"size":"40","color":"indigo"}})],1):_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"ma-2 mx-auto",attrs:{"type":"submit","dark":"","color":"indigo"}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1),_c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('changePass')))]),_c('v-card-text',[_c('v-form',{ref:"passForm",on:{"submit":function($event){$event.preventDefault();return _vm.updatePass($event)}},model:{value:(_vm.passValid),callback:function ($$v) {_vm.passValid=$$v},expression:"passValid"}},[_c('v-text-field',{attrs:{"name":"password","label":_vm.$t('currentPassword'),"id":"password","type":"password","color":"indigo","rules":[
                function (v) { return !!v || _vm.$t('passwordRequired'); },
                function (v) { return v.length >= 8 || _vm.$t('minPassword'); } ],"required":""},model:{value:(_vm.curPassword),callback:function ($$v) {_vm.curPassword=$$v},expression:"curPassword"}}),_c('v-text-field',{attrs:{"name":"password","label":_vm.$t('newPass'),"id":"password","type":"password","color":"indigo","rules":[
                function (v) { return !!v || _vm.$t('passwordRequired'); },
                function (v) { return v.length >= 8 || _vm.$t('minPassword'); } ],"required":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-text-field',{attrs:{"name":"confirm-password","label":_vm.$t('confirmPassword'),"id":"confirmPassword","type":"password","color":"indigo","rules":[
                  function (v) { return !!v || _vm.$t('passwordRequired'); },
                  function (v) { return v.length >= 8 || _vm.$t('minPassword'); } ].concat([
                  function (v) { return v === this$1.password || _vm.$t('indenticalPassword'); } ]),"required":""},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_c('v-spacer'),_c('v-spacer'),(_vm.passwordLoading)?_c('div',{staticClass:"d-flex justify-center"},[_c('v-progress-circular',{attrs:{"indeterminate":true,"size":"40","color":"indigo"}})],1):_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"ma-2 mx-auto",attrs:{"type":"submit","dark":"","color":"indigo"}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1)],1)],1)],1),_c('div',{staticClass:"ma-4"},[_c('v-btn',{staticClass:"white--text",attrs:{"disabled":_vm.loading,"color":"success"},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},[_vm._v(_vm._s(_vm.$t('changePass')))])],1),_c('v-snackbar',{attrs:{"left":"","color":_vm.color},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.message)+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }