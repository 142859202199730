<template>
  <v-container>
    <div v-if="loading" class="ma-6 d-flex justify-center">
      <v-progress-circular :indeterminate="true" size="40" color="indigo">
      </v-progress-circular>
    </div>

    <template v-else>
    <h3 class="my-1">Descriptions</h3>
    <v-row class="ma-2">
      <v-col class="ma-0 pa-0" cols="5">Category:</v-col>
      <v-col class="ma-0 pa-0" cols="5">HS Code:</v-col>
      <v-col class="ma-0 pa-0" cols="2">Actions:</v-col>
    </v-row>
    <v-card v-for="item in packageDescriptions" :key="item._id" class="px-4 py-1 my-2" elevation="0">
      <v-row class="mt-2">
        <v-col class="ma-0 pa-0" cols="5">
          <v-text-field class="ma-2 mt-0" v-model="item.description"
            v-if="item._id == selectedDescription._id && !deleteDialog"></v-text-field>
          <p v-else class="mt-2 ml-2 ft-18">{{ item.description }}</p>
        </v-col>
        <v-col class="ma-0 pa-0" cols="5">
          <v-text-field class="ma-2 mt-0" v-model="item.code"
            v-if="item._id == selectedDescription._id && !deleteDialog"></v-text-field>
          <p v-else class="mt-2 ml-2 ft-18">{{ item.code }}</p>
        </v-col>
        <v-col v-if="Object.keys(selectedDescription).length === 0 || deleteDialog" class="ma-0 pa-0" cols="2">
          <v-btn class="ma-1" outlined color="success" @click="handleUpdateButton(item)">Update</v-btn>
          <v-btn class="ma-1" outlined color="error" @click="toggleDeleteDialog(item, true)">Delete</v-btn>
        </v-col>
        <v-col v-else-if="selectedDescription._id == item._id" class="ma-0 pa-0" cols="2">
          <v-btn class="ma-1" outlined color="primary" @click="handleUpdate(item)">Save</v-btn>
          <v-btn class="ma-1" outlined color="error" @click="handleCancelUpdate()">Cancel</v-btn>
        </v-col>
      </v-row>
    </v-card>


    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title>
          Are you sure you want to delete "{{ selectedDescription.description }}"?
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="handleDelete()">Confirm</v-btn>
          <v-btn @click="toggleDeleteDialog(item, false)">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  </v-container>
</template>

<script>
import axios from 'axios';
import auth from '../helpers/auth';

axios.defaults.headers.common["Authorization"] = `bearer ${auth.adminToken()}`;

export default {
  name: "ManagePackageDescriptions",
  data() {
    return {
      admin: auth.admin(),
      adminId: 1,
      supportId: 2,
      packageDescriptions: [],
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "",
      selectedDescription: {},
      tempDescription: {},
      deleteDialog: false,
      loading: true
    };
  },
  mounted() {
    if (this.admin.groupId > this.adminId) {
      this.$router.push('support/');
    }

    axios.get('/api/admins/package_descriptions').then((res) => {
      if (!res.data.error) {
        this.packageDescriptions = res.data.descriptions;
        this.loading = false;
      }
      else {
        this.snackbarColor = "error";
        this.snackbarMessage = "Error loading package descriptions! Please reload the page.";
        this.snackbar = true;
      }
    }).catch((err) => {
      this.snackbarColor = "error";
      this.snackbarMessage = "Error loading package descriptions! Please reload the page.";
      this.snackbar = true;
    })
  },
  methods: {
    handleUpdate: function (item) {
      axios.patch('/api/admins/package_descriptions', item).then((res) => {
        if (!res.data.error) {
          this.snackbarColor = "success";
          this.snackbarMessage = "Successfully updated package description!";
          this.snackbar = true;
          this.selectedDescription = {}
        }
        else {
          this.snackbarColor = "error";
          this.snackbarMessage = "Error updating package description! Please try again.";
          this.snackbar = true;
        }
      }).catch((err) => {
        this.snackbarColor = "error";
        this.snackbarMessage = "Error updating package description! Please try again.";
        this.snackbar = true;
      })
    },
    handleUpdateButton: function(item){
      this.tempDescription = structuredClone(item);
      this.selectedDescription = item;
    },
    handleCancelUpdate: function(){
      const index = this.packageDescriptions.findIndex((item) => item._id == this.selectedDescription._id);
      if(index !== -1)
        this.packageDescriptions[index] = this.tempDescription;
      this.selectedDescription = {}
    },
    toggleDeleteDialog: function (item, value) {
      if (value) {
        this.selectedDescription = item;
        this.deleteDialog = true;
      }
      else {
        this.selectedDescription = {};
        this.deleteDialog = false;
      }
    },
    handleDelete: function () {
      axios.delete(`/api/admins/package_descriptions/${this.selectedDescription._id}`).then((res) => {
        if (!res.data.error) {
          this.snackbarColor = "success";
          this.snackbarMessage = "Successfully deleted package description!";
          this.snackbar = true;
          const index = this.packageDescriptions.findIndex((item) => item._id == this.selectedDescription._id)
          if (index !== -1) {
            this.packageDescriptions.splice(index, 1);
          }

          this.selectedDescription = {};
          this.deleteDialog = false;
        }
        else {
          this.snackbarColor = "error";
          this.snackbarMessage = "Error updating package description! Please try again.";
          this.snackbar = true;
        }
      })
    },
  }
};
</script>

<style scoped>
.ft-18 {
  font-size: 18px;
}
</style>