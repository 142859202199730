<template>
  <v-toolbar class="dashboard__tooltip" app color="indigo lighten-1">
    <v-toolbar-items>
      <v-btn
        :to="'/' + this.$i18n.locale + '/dashboard'"
        text
        class="navbar-link white--text dashboard__tooltip-item"
      >
        <v-icon class="dashboard__tooltip-icon" color="white" left size="15">fas fa-cart-arrow-down</v-icon
        ><span class="hidden-sm-and-down">{{ $t('incomingPackages') }}</span>
        <v-chip class="dashboard__tooltip-chip" color="indigo" text-color="white" x-small style="margin:5px">{{
          incomePackagesCount
        }}</v-chip>
      </v-btn>

      <v-btn
        :to="'/' + this.$i18n.locale + '/awaiting-shipping'"
        text
        class="navbar-link white--text dashboard__tooltip-item"
      >
        <v-icon class="dashboard__tooltip-icon" color="white" left size="15">fas fa-hourglass-half</v-icon
        ><span class="hidden-sm-and-down">{{
          $t('awaitingShippingToolbar')
        }}</span>
        <v-chip class="dashboard__tooltip-chip" color="indigo" text-color="white" x-small style="margin:5px">{{
          awaitingPackagesCount
        }}</v-chip>
      </v-btn>

      <v-btn
        :to="'/' + this.$i18n.locale + '/shipped'"
        text
        class="navbar-link white--text dashboard__tooltip-item"
      >
        <v-icon class="dashboard__tooltip-icon" color="white" left size="15">fas fa-dolly-flatbed</v-icon
        ><span class="hidden-sm-and-down">{{ $t('shippedPackages') }}</span>
        <v-chip class="dashboard__tooltip-chip" color="indigo" text-color="white" x-small style="margin:5px">{{
          shippedPackagesCount
        }}</v-chip>
      </v-btn>
      <v-btn
        :to="'/' + this.$i18n.locale + '/search'"
        text
        class="navbar-link white--text dashboard__tooltip-item align-right"
      >
        <v-icon class="dashboard__tooltip-icon" color="white" size="15">fa fa-search</v-icon
        >
      </v-btn>
    </v-toolbar-items>
  </v-toolbar>
</template>

<script>
import axios from 'axios';
import auth from '../helpers/auth';

const newAxios = axios.create();
newAxios.defaults.headers.common = {};

export default {
  name: 'AddressCard',
  data() {
    return {
      incomePackagesCount: 0,
      awaitingPackagesCount: 0,
      shippedPackagesCount: 0,
    };
  },
  mounted() {
    newAxios
      .get('/api/packages/data/count', {
        headers: {
          Authorization: `bearer ${auth.token()}`,
        },
      })
      .then((res) => {
        this.incomePackagesCount = res.data.incomingPackages;
        this.awaitingPackagesCount = res.data.awaitingPackages;
        this.shippedPackagesCount = res.data.shippedPackages;
      })
      .catch((err) => {
        console.log(err.message);
      });
  },
};
</script>
