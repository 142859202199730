<template>
  <div>
    
    <Navbar />
    
    <v-layout style="margin-top:150px;" align-center justify-center>
    <v-flex xs12 sm8 md4>
      <v-card class="elevation-12" style="margin: 0 20px 0 20px">
        <v-toolbar dark color="indigo">
          <v-toolbar-title>{{$t('loginTitle')}}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" @submit.prevent="handleSubmit" v-model="valid">
            <v-text-field
              v-model="email"
              name="email"
              :label="$t('email')"
              id="email"
              type="email"
              color="green"
              :rules="[
              v => !!v || $i18n.t('emailRequired'),
              v =>emailRe.test(String(v).toLowerCase()) || $i18n.t('validEmail')]"
              required
            ></v-text-field>
            <v-text-field
              v-model="password"
              name="password"
              :label="$t('password')"
              id="password"
              :type="showPassword ? 'text' : 'password'"
              ref="password"
              color="green"
              :rules="[
                v => !!v || $i18n.t('passwordRequired'),
                v => (v && v.length >= 8) || $i18n.t('minPassword'),
              ]"
              required
              :append-icon="showPassword ? 'fa-eye' : 'fa-eye-slash'"
              @click:append="()=>{showPassword = !showPassword}"
            >
            </v-text-field>

            <v-spacer></v-spacer>

            <div><router-link style="text-decoration:none;font-size:15px;" class="indigo--text" :to="'/' +this.$i18n.locale + '/recoverpass'">{{$t('forgotPass')}}</router-link></div>
          
            <v-spacer></v-spacer>


            <div v-if="loading" class="d-flex justify-center">
              <v-progress-circular
            :indeterminate="true"
            size="40"
            color="indigo"
          ></v-progress-circular>
            </div>

            <div v-else class="d-flex justify-center" >
              <v-btn class="ma-2 mx-auto"  type="submit"  dark color="indigo" >
                  {{$t('login')}}
              </v-btn>
            </div>
          </v-form>
        </v-card-text>

        <v-card-actions>
          
          
        </v-card-actions>
      </v-card>
    </v-flex>
    <v-snackbar left :color="color" v-model="snackbar">
              {{ message }}
    </v-snackbar>

  </v-layout>
  </div>
</template>


<script>
// @ is an alias to /src
import Navbar from '../components/Navbar';
import auth from '../helpers/auth';

//eslint-disable-next-line 
export default {
  name: 'Login',
  metaInfo() {
    return {
      title: this.$t('loginPageTitle'),
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: "description", content: "أحصل على عنوان فرنسي مجاناً، وتسوق بمفردك من المتاجر الأوروبية والفرنسية. نشحنها إلى باب بيتك بأي مكان في العالم." },
        { name: "keywords", content: "عنوان فرنسي للشحن, عنوان اوروبي للشحن, تجميع الشحنات فرنسا, عنوان فرنسي, porsay, بورساي, الحصول على عنوان فرنسي, france forwarding service, french address for online shopping" }

      ]
  }},
  components:{Navbar},

  data: ()=> ({
    valid: false,

    message: '',

    loading:false,

    email: '',
    emailRe : /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password: "",
    showPassword: false,
    snackbar:false,
    color:''
  }),

  mounted(){
    if(this.$i18n.locale === 'ar'){
        this.$vuetify.rtl = true;
    }

  },
  methods:{
    test(){
      //eslint-disable-next-line
      return $i18n.$t('validEmail')
    },
    handleSubmit(){

      this.loading = true;

      this.$recaptcha('login').then((token) => {
        //console.log(token) // Will print the token
        auth.login(this.email, this.password, token, (loggedIn, message) => {
          if(loggedIn){
            this.$emit("authChange");
            this.$router.push('/' + this.$i18n.locale + '/dashboard')
          }else{
            this.message = this.$i18n.locale === 'en' ? "Incorrect password. Try again, or click 'Forgot Password' to reset it." : "كلمة مرور خاطئة. أعد المحاولة، أو انقر على 'نسيت كلمة المرور' لإعادة تعيينها.";
            this.snackbar = true;
            this.color = 'error'
            
          }
          this.loading = false;
        });
      })

    }
  }
}
</script>
