<template>
    <v-container>
            <v-layout style="margin-top:150px;" align-center justify-center>
    <v-flex xs12 sm8 md6>
      <v-card class="elevation-12" style="margin: 0 20px 0 20px">
        <v-toolbar dark color="indigo">
          <v-toolbar-title>Change Password</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" @submit.prevent="handleSubmit">
            <v-text-field
              v-model="currentPassword"
              name="CurPass"
              label="Current Password"
              id="curPass"
              type="password"
              color="indigo"
              required
            ></v-text-field>

            <v-text-field
              v-model="newPassword"
              name="newPass"
              label="New Password"
              id="newPass"
              type="password"
              color="indigo"
              required
            ></v-text-field>

            <v-spacer></v-spacer>
          
            <v-spacer></v-spacer>


            <div v-if="loading" class="d-flex justify-center">
              <v-progress-circular
            :indeterminate="true"
            size="40"
            color="indigo"
          ></v-progress-circular>
            </div>

            <div v-else class="d-flex justify-center" >
              <v-btn class="ma-2 mx-auto"  type="submit" dark color="indigo" >
                  Change
              </v-btn>
            </div>
          </v-form>
        </v-card-text>

        <v-card-actions>
          
          
        </v-card-actions>
      </v-card>
    </v-flex>
    <v-snackbar left :color="color" v-model="snackbar">
              {{ message }}
    </v-snackbar>

  </v-layout>
    </v-container>
</template>

<script>
import axios from 'axios';
import auth from '../helpers/auth';


export default {
    name:'ChangePass',
    data(){
        return {
            currentPassword:'',
            newPassword:'',
            loading:false,
            snackbar:false,
            message:'',
            color:''
        }
    },
    methods:{
      handleSubmit(){
        this.loading = true;

        axios.post('/api/admins/changepass',{
          oldPassword:this.currentPassword,
          newPassword:this.newPassword
        }).then(res=>{
          this.loading = false;
          if(!res.data.error){
            this.message = 'Password Changed Successfully'
            this.color = 'success';
            this.snackbar = true;

            this.$refs.form.reset()
          }else{
            this.message = res.data.message;
            this.color = 'error';
            this.snackbar = true;
          }
        }).catch(err=>{
          console.log(err)
          this.message = 'An error happened please try again later';
          this.color = 'error';
          this.snackbar = true;
        })
      }
    }
}
</script>