<template>
<v-container>
  <!-- <h2 style="color: #646464;">Address Management</h2>
  <hr class="mt-2 mb-4"> -->
  <v-btn @click="addAddressDialog = !addAddressDialog" class="mb-2 mt-4 primary-btn-inv"><v-icon small left>fas fa-plus</v-icon>Add Address</v-btn>
  <div v-for="address in addresses" :key="address._id">
    <h2 class="mb-2 mt-6">
        {{address.country}}
        <v-btn @click="handleUpdateAddress(address)" class="mx-2" style="box-shadow:none" fab dark small color="rgb(63, 81, 181)">
          <v-icon dark>mdi-pencil</v-icon>
        </v-btn>
        </h2>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col :cols="6">
            Address Line 1: <b class="ft-sz-18">{{address.addressLineOne}}</b> <br>
            Address Line 2: <b class="ft-sz-18">{{address.addressLineTwo}}</b> <br>
            City: <b class="ft-sz-18" style="margin-left: 70px">{{address.city}}</b> <br>
            </v-col>
            <v-col :cols="4">
              State: <b class="ft-sz-18" style="margin-left: 7px">{{address.state}}</b> <br>
              Postal: <b class="ft-sz-18">{{address.zip}}</b> <br>
              Phone: <b class="ft-sz-18">{{address.phone}}</b> <br>
            </v-col>
          <v-col class="d-flex align-center">
            <label class="switch">
              <input type="checkbox" v-model="address.isActive" @click="handleDisableAddress(address)">
              <div class="slider round"></div>
            </label>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>

    <!-- Add Address popup -->
    <v-dialog v-model="addAddressDialog" width="600">
      <v-card >
        <v-card-title>Add Company Address</v-card-title>
        <v-card-text>

          <v-divider class="mb-4"></v-divider>

          <v-form ref="form" @submit.prevent="addAddress">
            <v-text-field
             label="Address Line 1"
             v-model="addressFirst"
             :rules="[v=> !!v || 'Please fill this field']"
             ></v-text-field>

            <v-text-field
             label="Address Line 2"
             v-model="addressSecond"
             ></v-text-field>

             <v-select label="Country" v-model="country" :items="countryList"
             :rules="[v=> !!v || 'Please select a country']"></v-select>

            <v-text-field
             label="City"
             v-model="city"
             :rules="[v=> !!v || 'Please fill this field']"
             ></v-text-field>

            <v-text-field
             label="State"
             v-model="state"
             :rules="[v=> !!v || 'Please fill this field']"
             ></v-text-field>

            <v-text-field
             label="Postal"
             v-model="postal"
             :rules="[v=> !!v || 'Please fill this field']"
             ></v-text-field>

            <v-text-field
             label="Phone"
             v-model="phone"
             :rules="[v=> !!v || 'Please fill this field']"
             ></v-text-field>

             <v-text-field v-model="pickedColor.hex" :disabled="pickColorDialog" label="Color" @focus="pickColorDialog = true">
             </v-text-field>

            <v-file-input
            accept="image/*"
            label="Flag"
            prepend-icon="fa-solid fa-paperclip"
            ref="fileupload"
            @change="this.onFilesPicked"
          ></v-file-input>

            <div class="d-flex justify-center">
              <v-btn class="ma-2 mx-auto" type="submit" color="rgb(63, 81, 181)" dark >Add</v-btn>
            </div>

          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Update Address popup -->
    <v-dialog v-model="updateAddressDialog" width="600">
      <v-card >
        <v-card-title>Update Address</v-card-title>
        <v-card-text>

          <v-divider class="mb-4"></v-divider>

          <v-form ref="form" @submit.prevent="updateAddress(selectedAddress._id)">
            <v-text-field
             label="Address Line 1"
             v-model="addressFirst"
             :rules="[v=> !!v || 'Please fill this field']"
             ></v-text-field>

            <v-text-field
             label="Address Line 2"
             v-model="addressSecond"
             ></v-text-field>

             <v-select label="Country" v-model="country" :items="countryList"
             :rules="[v=> !!v || 'Please select a country']"></v-select>

            <v-text-field
             label="City"
             v-model="city"
             :rules="[v=> !!v || 'Please fill this field']"
             ></v-text-field>

            <v-text-field
             label="State"
             v-model="state"
             :rules="[v=> !!v || 'Please fill this field']"
             ></v-text-field>

            <v-text-field
             label="Postal"
             v-model="postal"
             :rules="[v=> !!v || 'Please fill this field']"
             ></v-text-field>

            <v-text-field
             label="Phone"
             v-model="phone"
             :rules="[v=> !!v || 'Please fill this field']"
             ></v-text-field>

             <v-text-field v-model="pickedColor.hex" :disabled="pickColorDialog" label="Color" @focus="pickColorDialog = true">
             </v-text-field>

             <v-file-input
              accept="image/*"
              label="Flag"
              prepend-icon="fa-solid fa-paperclip"
              ref="fileupload"
              @change="this.onFilesPicked">
             </v-file-input>

            <div class="d-flex justify-center">
              <v-btn class="ma-2 mx-auto" type="submit" color="rgb(63, 81, 181)" dark >Update</v-btn>
            </div>

          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Disable Address Popup -->
    <v-dialog v-model="disableAddressDialog" width="400">
      <v-card>
        <v-card-title>
          Are you sure you want to disable <b>{{selectedAddress.country}}</b>?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="disableAddress" color="error" class="mb-2 mx-1">
            Disable
          </v-btn>
          <v-btn @click="cancelDisableAddress" class="mb-2 mx-1">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Color picker popup -->
    <v-dialog v-model="pickColorDialog" width="300">
      <v-color-picker v-model="pickedColor"
      @keyup="pickColor"
      mode="hexa"
      hide-mode-switch>
      </v-color-picker>
      <v-btn color="rgb(63, 81, 181)" class="white--text" @click="pickColorDialog = false">
        Pick
      </v-btn>
    </v-dialog>

    <v-snackbar center v-model="snackbar" :color="color">
      {{message}}
    </v-snackbar>
  </v-container>
</template>

<script>

import axios from 'axios'; //eslint-disable-line
import auth from '../helpers/auth'; //eslint-disable-line
import { countryList } from '../config/config';


export default {
  name: "AddressManagement",
  data() {
    return {
      addresses: [],
      addressFirst: "",
      addressSecond: "",
      city: "",
      postal: "",
      country: "",
      pickedColor: {},
      active: false,
      addAddressDialog: false,
      pickColorDialog: false,
      updateAddressDialog: false,
      disableAddressDialog: false,
      selectedAddress: {},
      countryDropdown: false,
      loading: false,
      phone:'',
      image: '',
      countryList: countryList,
      addressId:'',
      message:'',
      snackbar:false,
      color: '',
      state:'',
      adminId: 1,
      supportId: 2,
      admin: auth.admin(),

    };
  },
  watch: {
    updateAddressDialog(val){
      if(!val)
        this.resetForm()
    },
    addAddressDialog(val){
      if(!val)
        this.resetForm()
    },
  },
  methods: {
    resetForm(){
      this.addressFirst = ''
      this.addressSecond = ''
      this.country = ''
      this.city = ''
      this.state = ''
      this.postal = ''
      this.phone = ''
      if(this.pickedcolor)
        this.pickedcolor.hex = '#FF0000'
      this.image = ''

      this.$refs.form.reset()
    },
    pickColor(){
      console.log(this.pickedColor.hex)
    },
    addAddress(){
      if(!this.$refs.form.validate())
        return
      
      this.loading = true
      const data = new FormData()

      data.append('country', this.country)
      data.append('city', this.city)
      data.append('state', this.state)
      data.append('phone', this.phone)
      data.append('addressLineOne', this.addressFirst)
      data.append('addressLineTwo', this.addressSecond ? this.addressSecond : ' ')
      data.append('zip', this.postal)
      data.append('color', this.pickedColor.hex)
      data.append('isActive', false)

      data.append('image', this.image)

      axios.post('api/admins/address/add', data).then(res => {
        if(!res.data.error){
          this.addresses.push(res.data.address)
          this.message = 'Address added'
          this.color = 'indigo'
          this.snackbar = true
        } else {
          this.loading = false;
          this.message = 'An error happened please try again later';
          this.color = 'error';
          this.snackbar = true;
        }
      }).catch((err)=>{
        this.loading = false;
        this.message = 'An error happened please try again later';
        this.color = 'error';
        this.snackbar = true;
      })
    },
    updateAddress(addressId) {
      if(!this.$refs.form.validate())
        return
      
      this.loading = true;
      const data = new FormData()

      data.append('country', this.country)
      data.append('city', this.city)
      data.append('state', this.state)
      data.append('phone', this.phone)
      data.append('addressLineOne', this.addressFirst)
      data.append('addressLineTwo', this.addressSecond ? this.addressSecond : ' ')
      data.append('zip', this.postal)
      data.append('color', this.pickedColor.hex)

      data.append('image', this.image)

      axios.put('api/admins/address/' + addressId, data).then((res)=>{
        if(!res.data.error){
          this.loading = false;
          this.message = 'Updated successfully';
          this.color = 'indigo';
          this.snackbar = true;

          this.selectedAddress.addressLineOne = this.addressFirst
          this.selectedAddress.addressLineTwo = this.addressSecond
          this.selectedAddress.country = this.country
          this.selectedAddress.state = this.state
          this.selectedAddress.city = this.city
          this.selectedAddress.phone = this.phone
          this.selectedAddress.zip = this.postal

          this.updateAddressDialog = false
          this.resetForm()

        }else{
          this.loading = false;
          this.message = 'An error happened please try again later';
          this.color = 'error';
          this.snackbar = true;
        }
      }).catch((err)=>{
        this.loading = false;
        this.message = 'An error happened please try again later';
        this.color = 'error';
        this.snackbar = true;
      })
    },
    handleUpdateAddress(address){
      this.selectedAddress = address
      this.updateAddressDialog = true
      console.log(this.pickedColor)

      this.addressFirst = address.addressLineOne
      this.addressSecond = address.addressLineTwo
      this.country = address.country
      this.city = address.city
      this.state = address.state
      this.postal = address.zip
      this.phone = address.phone
      if(address.color)
        this.pickedColor.hex = address.color
    },
    handleDisableAddress(address){
      this.selectedAddress = address
      if(this.selectedAddress.isActive)
        this.disableAddressDialog = true
      else{
        this.enableAddress()
      }
    },
    enableAddress(){
      axios.put('api/admins/address/' + this.selectedAddress._id, {
        isActive: true
      }).then(res => {
        if(!res.data.error){
          this.loading = false;
          this.message = 'Enabled successfully';
          this.color = 'indigo';
          this.snackbar = true;

        }else{
          this.loading = false;
          this.message = 'An error happened please try again later';
          this.color = 'error';
          this.snackbar = true;
          this.selectedAddress.isActive = false
        }
      }).catch((err)=>{
        this.loading = false;
        this.message = 'An error happened please try again later';
        this.color = 'error';
        this.snackbar = true;
        this.selectedAddress.isActive = false
      })
    },
    disableAddress(){
      axios.put('api/admins/address/' + this.selectedAddress._id, {
        isActive: false
      }).then(res => {
        if(!res.data.error){
          this.loading = false;
          this.message = 'Disabled successfully';
          this.color = 'indigo';
          this.snackbar = true;

          console.log(res.data)

          this.disableAddressDialog = false

        }else{
          this.loading = false;
          this.message = 'An error happened please try again later';
          this.color = 'error';
          this.snackbar = true;
          this.selectedAddress.isActive = true
        }
      }).catch((err)=>{
        this.loading = false;
        this.message = 'An error happened please try again later';
        this.color = 'error';
        this.snackbar = true;
        this.selectedAddress.isActive = true
      })
    },
    cancelDisableAddress(){ 
      this.selectedAddress.isActive = true
      this.disableAddressDialog = false
    },

    onFilesPicked(f) {
      this.image = f;
    },
  },
  mounted(){
    if( this.admin.groupId >= this.adminId ) {
      this.$router.push('/en/admin/support');
    }


    axios.get('/api/admins/address/all').then(res => {
      this.addresses = res.data.addresses
    })

    // axios.get('/api/admins/address/').then((res)=>{
    //   let address = res.data.address

    //   this.addressId = address._id;
    //   this.name = address.name;
    //   this.country = address.country;
    //   this.city = address.city;
    //   this.addressFirst = address.addressLineOne;
    //   this.addressSecond = address.addressLineTwo;
    //   this.phone = address.phone;
    //   this.postal = address.zip;
    //   this.state = address.state;
    // })
  }
};
</script>

<style scoped>
.ft-sz-18{
  font-size:18px
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: rgb(63, 81, 181);
}

input:focus + .slider {
  box-shadow: 0 0 1px rgb(63, 81, 181);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.primary-btn-inv {
  color:rgb(63, 81, 181);
  font-weight:bold;
  border: 2px solid rgb(63, 81, 181)
}
</style>
