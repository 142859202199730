<template>
  <v-dialog max-width="650" v-model="value">
    <v-card>
      <v-card-title>Add Package</v-card-title>
      <v-card-text>
        <v-form ref="form" @submit.prevent="handleSubmit" v-model="valid">
          <!-- <v-text-field
              v-model="trackNumber"
              name="trackNumber"
              @input="up"
              label="Track Number"
              id="trackNumber"
              type="text"
              color="indigo"
              :rules="[(v) => !!v || $i18n.t('codeRequired')]"
              required
            ></v-text-field> -->

          <v-text-field
            v-model="weight"
            name="weight"
            label="Weight"
            id="weight"
            type="text"
            color="indigo"
            :rules="[(v) => !!v || $i18n.t('codeRequired')]"
            required
          ></v-text-field>

          <v-text-field
            v-model="length"
            name="length"
            label="Length"
            id="length"
            type="text"
            color="indigo"
            :rules="[(v) => !!v || $i18n.t('codeRequired')]"
            required
          ></v-text-field>

          <v-text-field
            v-model="width"
            name="width"
            label="Width"
            id="width"
            type="text"
            color="indigo"
            :rules="[(v) => !!v || $i18n.t('codeRequired')]"
            required
          ></v-text-field>

          <v-text-field
            v-model="height"
            name="height"
            label="Hieght"
            id="height"
            type="text"
            color="indigo"
            :rules="[(v) => !!v || $i18n.t('codeRequired')]"
            required
          ></v-text-field>

          <v-select
            v-model="hasDangerous"
            :items="['Yes', 'No']"
            :label="$t('dangerousQst')"
            required
            :rules="[(v) => !!v || $i18n.t('codeRequired')]"
          ></v-select>

          <v-select
            v-model="hasLithium"
            :items="['Yes', 'No']"
            :label="$t('lithiumQst')"
            required
            :rules="[(v) => !!v || $i18n.t('codeRequired')]"
          ></v-select>

          <v-text-field
            v-model="fees"
            name="fees"
            label="Fees"
            id="fees"
            type="text"
            color="indigo"
            :rules="[(v) => !!v || $i18n.t('codeRequired')]"
            required
          ></v-text-field>

          <v-file-input
            accept="image/*"
            chips
            multiple
            label="Package Images"
            prepend-icon="fa-solid fa-paperclip"
            ref="fileupload"
            @change="this.onFilesPicked"
          ></v-file-input>

          <template v-for="info in infos">
            <v-row>
              <v-col>
                <v-text-field
                  v-model="info.description"
                  name="description"
                  label="Description"
                  id="description"
                  type="text"
                  color="indigo"
                  :rules="[(v) => !!v || $i18n.t('codeRequired')]"
                  required
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="info.quantity"
                  name="quantity"
                  label="Quantity"
                  id="quantity"
                  type="number"
                  min="0"
                  color="indigo"
                  :rules="[(v) => !!v || $i18n.t('codeRequired')]"
                  required
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="info.price"
                  name="price"
                  label="Price"
                  id="price"
                  type="number"
                  min="0"
                  color="indigo"
                  required
                  :rules="[(v) => !!v || $i18n.t('codeRequired')]"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="info.madeIn"
                  name="madeIn"
                  label="Where was the item made in?"
                  id="madeIn"
                  type="text"
                  color="indigo"
                  :rules="[(v) => !!v || $i18n.t('codeRequired')]"
                  required
                ></v-text-field>
              </v-col>

              <v-col>
                <v-btn icon color="error" @click="deleteLine(info)"
                  ><i-icon
                    color="error"
                    size="30"
                    class="fa-solid fa-times-circle"
                  ></i-icon
                ></v-btn>
              </v-col>
            </v-row>
          </template>
          <v-btn depressed color="primary" @click="addLine"> New line </v-btn>

          <div v-if="loading" class="d-flex justify-center">
            <v-progress-circular
              :indeterminate="true"
              size="40"
              color="indigo"
            ></v-progress-circular>
          </div>

          <div v-else class="d-flex justify-center">
            <v-btn class="ma-2 mx-auto" type="submit" dark color="indigo"
              >Add</v-btn
            >
          </div>
        </v-form>
      </v-card-text>

      <v-dialog v-model="confirmModal" max-width="600">
        <v-card>
          <v-card-title class="headline"
            >Please confirm your action</v-card-title
          >
          <v-spacer></v-spacer>
          <v-card-text>
            <v-alert border="top" colored-border type="error" elevation="2">
              This Package <b>{{ trackNumber }}</b> ? already exist! Are you
              sure that you want to add it again?
            </v-alert>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn depressed color="success">Add</v-btn>
            <v-btn
              @click="confirmModal = !confirmModal"
              class="ma-2"
              color="grey white--text"
              >close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar left :color="color" v-model="snackbar">{{
        message
      }}</v-snackbar>
    </v-card>
  </v-dialog>
</template>
  
  <script>
import axios from "axios";
// import auth from "../helpers/auth";

export default {
  name: "AddPackageDialog",
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true,
    },
    callback: Function,
    userId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      valid: false,
      price: "",
      trackNumber: "",
      weight: "",
      fees: "",
      users: [],
      confirmModal: false,

      images: [],
      loading: false,
      message: "",
      color: "",
      selectDangerOption: "",
      snackbar: false,
      height: "",
      width: "",
      length: "",
      infos: [
        {
          description: "",
          madeIn: "",
          type: "",
          quantity: 0,
          price: 0,
          index: 0,
        },
      ],
      hasDangerous: "",
      hasLithium: "",
      shippingOptions: {
        hasDangerous: false,
        hasLithium: false,
      },
      line: 1,
      proceed: false,
    };
  },
  mounted() {},
  methods: {
    addLine() {
      this.line++;
      this.infos.push({
        description: "",
        type: "Parcels",
        quantity: 0,
        price: 0,
        index: this.line,
      });
    },

    deleteLine(info) {
      this.infos = this.infos.filter((item) => item.index != info.index);
    },

    handleProceed() {
      this.proceed = true;
      this.handleSubmit();
    },

    handleSubmit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.loading = true;

      const data = {
        height: this.height,
        weight: this.weight,
        length: this.length,
        width: this.width,
        fees: this.fees,
        trackNumber: this.trackNumber,
        userId: this.userId,
        images: this.images,
        details: this.infos.map((item) => ({
          description: item.description,
          quantity: item.quantity,
          price: item.price,
          madeIn: item.madeIn,
        })),
        shippingOptions: {
          hasDangerous: this.hasDangerous === "Yes",
          hasLithium: this.hasLithium === "Yes",
        },
      };

      data.shippingOptions = JSON.stringify(data.shippingOptions);
      data.details = JSON.stringify(data.details);

      this.callback(data);

      this.loading = false;
      this.$refs.form.reset();
      this.images = [];
    },
    onFilesPicked(f) {
      this.images = f;
    },
    up() {
      this.trackNumber = this.trackNumber.toUpperCase();
    },
  },
};
</script>
  
  <style scoped></style>
  