<template>
    <v-layout fill-height column>

        <DashboardNavbar />

        <v-container>
            <div class="ma-2" style="font-size:18px"><b>{{$t('subject')}}:</b> {{ticket.title}}</div>

            <div class="ma-2" style="font-size:18px"><b>{{$t('status')}}:</b> <span :class="(ticket.status == 'open') ? 'success--text' : 'error--text'">{{
                            (ticket.status == 'open' ) ? $t('open') : $t('closed')
                            }}</span></div>

            <div class="ma-2" style="font-size:18px"><b>{{$t('date')}}:</b> {{formatDate(ticket.createdAt)}}</div>

            <div class="ma-4">

                <div v-for="msg in ticket.messages" :key="msg.id">
                    <v-card v-if="!msg.isAdmin" class="blue ma-4 darken-1 white--text">
                        <v-card-text class="pa-2">
                            <v-row>
                                    <v-col md="1">
                                        <div class="ma-1"><v-icon size="55" color="white">fas fa-user-circle</v-icon></div>

                                    </v-col>

                                    <v-col md="11" style="font-size:16px" class="pa-4 white--text">
                                        {{msg.message}}
                                    </v-col>
                             </v-row>
                        </v-card-text>

                        <div class="d-flex align-center justify-space-between pa-3">
                            <a v-if="msg.file" class="v-btn v-btn--depressed v-btn--flat v-btn--outlined v-btn--rounded theme--dark v-size--default white--text" target="_blank" :href="backendUrl + '/' + msg.file">{{$t('openFile')}}</a>
                            {{formatDate(msg.createdAt)}}
                        </div>
                    </v-card>


                    <v-card v-else class="green ma-4 darken-1 white--text">
                        <v-card-text class="pa-2">
                            <v-row>
                                    <v-col md="1">
                                        <div class="ma-1"><v-icon size="55" color="white">fas fa-info-circle</v-icon></div>

                                    </v-col>

                                    <v-col md="11" style="font-size:16px" class="pa-4 white--text">
                                        {{msg.message}}
                                    </v-col>
                             </v-row>
                        </v-card-text>

                        <div class="d-flex align-center justify-space-between pa-3">
                            <a v-if="msg.file" class="v-btn v-btn--depressed v-btn--flat v-btn--outlined v-btn--rounded theme--dark v-size--default white--text" target="_blank" :href="backendUrl + '/' + msg.file">{{$t('openFile')}}</a>
                            {{formatDate(msg.createdAt)}}
                        </div>
                </v-card>


                </div>


            </div>

        </v-container>

         <v-layout align-end class="pa-5 ma-5" >
               <v-container>
                    <v-textarea

                    class="ma-1"
                    :label="$t('content')"
                    auto-grow
                    dense
                    outlined
                    color="indigo"
                    v-model="content"
            >
            </v-textarea>
            <v-file-input accept="image/*, application/pdf" @change="this.onFilesPicked" show-size hide-input label="Attach file"></v-file-input>
            

            <v-btn @click="sendMsg" :disabled="loading" color="indigo"  class="white--text ma-1">{{$t('send')}}</v-btn>
               </v-container>
            </v-layout>


    <v-snackbar left :color="color" v-model="snackbar">
                {{ message }}
            </v-snackbar>

    </v-layout>
</template>

<script>
import axios from 'axios';
import auth from '../helpers/auth';
import { backendUrl } from "../config/config";

const newAxios = axios.create();

newAxios.defaults.headers.common = {};

import DashboardNavbar from '../components/DashboardNavbar';

export default {
    name:'SupportTicket',
    components:{DashboardNavbar},
    data(){

        return {
            ticket:{},
            backendUrl,
            content:'',
            file: {},
            message:'',
            color:'',
            snackbar:false,
            loading:false,
        }
    },
    mounted(){
        if(this.$i18n.locale == 'ar'){
            this.$vuetify.rtl = true;
        }

        newAxios.post('/api/users/support/get',{
            ticketId:this.$route.params.id
        },{
            headers:{
                Authorization:`bearer ${auth.token()}`
            }
        }).then(res=>{
            this.ticket = res.data.ticket
        })
    },
    methods:{
       formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear(),
        hours = (d.getHours().toString().length ==1) ? '0' +d.getHours() : d.getHours(),
        mins = (d.getMinutes().toString().length ==1) ? '0' +d.getMinutes() : d.getMinutes()

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-") + ` ${hours}:${mins}`;
    },
    onFilesPicked(f){
        const type = f.type.split('/')
        const size = parseInt(f.size)
        if (f.type !== "application/pdf" && type[0] !== "image") {
            this.snackbar = true;
            this.color = 'warning';

            if(this.$i18n.locale == 'ar'){
                this.message = 'يمكنك فقط تحميل ملفات الصور أو pdf'
            }else if(this.$i18n.locale == 'en'){
                this. message = "you can only upload image or pdf files";
            }
        } else if (size > 10485760) {
            this.snackbar = true;
            this.color = 'warning';

            if(this.$i18n.locale == 'ar'){
                this.message = 'لا يمكنك تحميل ملف أكبر من 10 ميغا بايت'
            }else if(this.$i18n.locale == 'en'){
                this. message = "you can't upload file larger than 10MB";
            }
        } else {
            this.file = f
        }
        //console.log(this.images.size)
    }
    ,
    sendMsg(){
        if(!this.content){
            this.snackbar = true;
            this.color = 'error';

            if(this.$i18n.locale == 'ar'){
                this.message = 'لا يمكن أرسال رسالة فارغة'
            }else if(this.$i18n.locale == 'en'){
                this. message = "you can't send an empty message";
            }
            return 
        }

        this.$recaptcha('sendMsg').then((token) => {
        //console.log(token) // Will print the 
            const data = new FormData();

            data.append('message',this.content)
            data.append('file',this.file)
            data.append('ticketId',this.ticket._id)
            data.append('captcha', token)
        
            newAxios.post('/api/users/support/send', data,{
                headers:{
                    Authorization:`bearer ${auth.token()}`
                }
            }).then(res=>{

                if(!res.data.error){
                    
                    this.ticket.messages.push(res.data.message)
                    this.content = '';
                    
                }else{
                    
                    if(this.$i18n.locale == 'ar'){
                        this.message = 'لقد حذث خطأ ما الرجاء المحاولة لاحقاً'
                    }else if(this.$i18n.locale == 'en'){
                        this.message = 'An error happened please try again'
                    }

                    this.color = 'error';
                    this.snackbar = true;
                }
            }).catch(err=>{
                if(this.$i18n.locale == 'ar'){
                        this.message = 'لقد حذث خطأ ما الرجاء المحاولة لاحقاً'
                    }else if(this.$i18n.locale == 'en'){
                        this.message = 'An error happened please try again'
                    }

                    this.color = 'error';
                    this.snackbar = true;
            })

        })

        
    }

    }
}
</script>

<style scoped>


</style>
