<template>
    <v-container>

        <v-btn @click.stop="dialog=!dialog" dark color="indigo" class="ma-4">
            <v-icon small left>fas fa-plus</v-icon>

            Open Ticket
        </v-btn>
        <v-data-table
            :headers="tableHeaders"
            :items="tickets"
            :page.sync="page"
            :server-items-length="requestCount"
            class="elevation-2 grey lighten-5 ma-3"
            @update:page="pageUpdateFunction"
            @update:items-per-page="itemsPerPageUpdateFunction"
            :items-per-page.sync="itemsPerPage"
        >


            <template v-slot:item.subject="{ item }">
                <router-link :to="'/' + $i18n.locale + '/admin/support/' + item._id">{{item.title}}</router-link>
            </template>

            <template v-slot:item.username="{ item }">
                <div>{{item.user.fullName}}</div>
            </template>

             <template v-slot:item.userHash="{ item }">
                <div>{{item.user.addressHash}}</div>
            </template>

            <template v-slot:item.status="{ item }">
                <div>{{item.status}}</div>
            </template>

            <template v-slot:item.date="{ item }">
                <div>{{ formatDate(item.createdAt)}}</div>
            </template>

            <template v-slot:item.newMsg="{ item }">
                <div>{{ (item.newMessage) ? 'yes' : 'no'}}</div>
            </template>

            <template v-slot:item.actions="{ item }">
                <div>
                    <v-btn :disabled="!item.newMessage" @click="markRead(item._id)"  class="ma-2" small tile outlined color="primary">
                        <v-icon size="15" left>fas fa-eye</v-icon> mark as read
                    </v-btn>


                    <v-btn class="ma-2"  small tile outlined @click="markClosed(item._id)" :disabled="item.status == 'closed'" color="success">
                        <v-icon size="15" left>fas fa-check</v-icon> mark as closed
                    </v-btn>
                </div>
            </template>

            <template v-slot:footer>
                <v-row justify="center">
                    <v-col cols="8">
                        <v-container class="max-width">
                            <v-pagination
                            v-model="page"
                            class="my-4"
                            :total-visible="7"
                            :length="pageCount"
                            @input="pageUpdateFunction"
                            ></v-pagination>
                        </v-container>
                    </v-col>
                </v-row>
            </template>

        </v-data-table>

        <v-dialog v-model="dialog" width="600">
                <v-card>
                    <v-card-title>New Ticket</v-card-title>
                    <v-card-text>
                        <v-form ref="form" @submit.prevent="createSupportTicket" v-model="valid">

                            <v-textarea
                            filled
                            :label="$t('about')"
                            auto-grow
                            v-model="about"
                            ></v-textarea>

                                    <v-autocomplete
                                        v-model="userId"
                                        :items="users"
                                        filled
                                        :filter="customFilter"
                                        color="indigo"
                                        label="User"
                                        item-text="firstName"
                                        item-value="id"
                                        >
                                        <template v-slot:selection="data">
                                    
                                            <div class="ma-2">
                                                {{data.item.firstName + ' ' + data.item.lastName}}
                                            </div>
                                            
                                            <div style="border-radius:25px;font-size:14px;" class="ma-1 pa-1 grey lighten-1">
                                                {{data.item.addressHash}}
                                            </div>
                                        </template>

                                        <template v-slot:item="data">
                                            <template>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{data.item.firstName + ' ' + data.item.lastName}}
                                                </v-list-item-title>
                                                <v-list-item-subtitle>
                                                    {{data.item.addressHash}}
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                            </template>
                                        </template>
                                        </v-autocomplete>

                        <v-spacer></v-spacer>

                        <v-spacer></v-spacer>

                        <div v-if="loading" class="d-flex justify-center">
                            <v-progress-circular :indeterminate="true" size="40" color="indigo"></v-progress-circular>
                        </div>

                        <div v-else class="d-flex justify-center">
                            <v-btn class="ma-2 mx-auto" type="submit" dark color="indigo">{{$t('send')}}</v-btn>
                        </div>

                    </v-form>
                    </v-card-text>
                </v-card>
            </v-dialog>

        <v-snackbar left :color="color" v-model="snackbar">
                {{ message }}
            </v-snackbar>
    </v-container>
</template>

<script>
import axios from 'axios';


export default {

    name:'ManageSupport',
    data(){
        return {
            page: 1,
            pageCount: 5,
            requestCount: 1,
            itemsPerPage: 10,
            tableHeaders:[
                {
                    text:'Subject',
                    value:'subject',
                    align:'center'
                },
                {
                    text:'Username',
                    value:'username',
                    align:'center'
                },
                {
                    text:'User Hash',
                    value:'userHash',
                    align:'center'
                },
                {
                    text:'Date',
                    value:'date',
                    align:'center'
                },
                {
                    text:'Status',
                    value:'status',
                    align:'center'
                },
                {
                    text:'New message',
                    value:'newMsg',
                    align:'center'
                },
                {
                    text:'Actions',
                    value:'actions',
                    align:'center'
                }
            ],
            tickets:[],
            snackbar:false,
            message:'',
            color:'',
            dialog:false,
            users:[],
            userId:'',
            title:'',
            about:'',
            loading:false
        }
    },
    mounted(){
        axios.get('/api/admins/support/get').then(res=>{
            this.requestCount = res.data.requestCount
            this.pageCount = res.data.pageCount
            this.tickets = res.data.tickets;
        })
        axios.get('/api/users/?all=true').then((res)=>{
          this.users = res.data.users;
      })
    },

    methods:{

        pageUpdateFunction(newPageNumber) {
        axios.get(`/api/admins/support/get?page=${newPageNumber-1}&perPage=${this.itemsPerPage}`).then(res=>{
            this.page = newPageNumber
            this.pageCount = res.data.pageCount
            this.tickets = res.data.tickets

        })

    },
    itemsPerPageUpdateFunction(length) {
        this.page= 1
        this.pageCount = 1
        axios.get(`/api/admins/support/get?page=${this.pageCount -1}&perPage=${length}`).then(res=>{
            this.requestCount = res.data.requestCount
            this.pageCount = res.data.pageCount
            this.tickets = res.data.tickets

        })

    },

        formatDate(date) {
        var d = new Date(date) ,
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            hour = d.getHours(),
            mins = d.getMinutes();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
        if(mins < 10)
           mins = '0' + mins
        if(hour < 10)
           hour = '0' + hour

        return [year, month, day].join('-') + ` ${hour}:${mins}`;
 },
    markRead(id){

        axios.get('/api/admins/support/read/' + id).then(res=>{
            if(!res.data.error){
                this.message = 'updated successfully';
                this.color = 'success';
                this.snackbar = true;

                this.tickets.forEach(item=>{
                     if(item._id == id){
                         item.newMessage = false;
                     }
                 })
                
            }
        }).catch(err=>{
            this.message = 'An error happened please try again';
                this.color = 'error';
                this.snackbar = true;
        })
    },
    markClosed(id){

        axios.get('/api/admins/support/close/' + id).then(res=>{
            if(!res.data.error){
                this.message = 'updated successfully';
                this.color = 'success';
                this.snackbar = true;

                this.tickets.forEach(item=>{
                     if(item._id == id){
                         item.status = 'closed'
                     }
                })
            }
        }).catch(err=>{
            this.message = 'An error happened please try again';
                this.color = 'error';
                this.snackbar = true;
        })
    },
    customFilter (item, queryText, itemText) {
        
        const addressHash = item.addressHash.toLowerCase()
        const searchText = queryText.toLowerCase()

        return addressHash.indexOf(searchText) > -1
      },
    createSupportTicket(){
        if(!this.about){

                this.snackbar = true;
                this.color = 'error';
                this.message = 'please fill all fields';
                return;
                    
            }

            this.loading = true;

            axios.post('api/admins/support/create',{
                about:this.about,
                userId:this.userId
            }).then(res=>{

                if(!res.data.error){
                    
                
                    axios.get('/api/admins/support/get').then(res=>{
                        this.tickets = res.data.tickets;

                            this.message = 'Created Successfully';
                            this.snackbar = true;
                            this.color = 'success'
                            this.loading = false;

                            this.$refs.form.reset()
                            this.dialog = false;
                    })

                    
                }else{

                    this.message = 'An error happened please try again';
                    this.snackbar = true;
                    this.color = 'error';
                    this.loading = false;

                }
            }).catch(err=>{

                    this.message = 'An error happened please try again';
                    this.snackbar = true;
                    this.color = 'error';
                    this.loading = false;
                
                console.log(err)
            })
    }
    }
}
</script>