<template>
    <div>
        <DashboardNavbar />

        <v-container>
            <div style="font-size:17px" v-if="tickets.length == 0" class="d-flex justify-center">
                {{$t('emptyTicketsText')}}
            </div>

            <!-- <v-btn class="ma-2" @click="openNewTicket" color="success">{{$t('new')}}</v-btn> -->

            <!-- <v-btn class="ma-2" href="https://wa.me/33767026103" target="_blank"  color="success">
                <v-icon dark class="mx-2"> fab fa-whatsapp </v-icon>
                {{$t('contact')}}
                </v-btn> -->

            <v-card class="ma-3 grey lighten-4" v-for="ticket in tickets" :key="ticket._id">
                <v-card-title><router-link :to="'/' + $i18n.locale + '/support/' + ticket._id">{{ticket.title}}</router-link> <v-chip
          color="red"
          text-color="white"
          x-small
          v-if="ticket.userNewMessage"
          style="margin:3px;direction:ltr"
          >+1</v-chip></v-card-title>

                <v-card-text>
                    <div class="ma-1" style="font-size:16px">
                        {{$t('status')}}: <span :class="(ticket.status == 'open') ? 'success--text' : 'error--text'">{{
                            (ticket.status == 'open' ) ? $t('open') : $t('closed')
                            }}</span>
                    </div>

                    <div style="font-size:16px" class="ma-1">
                            {{$t('date')}}: <span>{{formatDate(ticket.createdAt)}}</span>
                    </div>
                    
                </v-card-text>


            </v-card>

            <v-dialog v-model="dialog" width="600">
                <v-card>
                    <v-card-title>{{$t('newTicket')}}</v-card-title>
                    <v-card-text>
                        <v-form ref="form" @submit.prevent="handleSubmit" v-model="valid">

                            <v-textarea
                            filled
                            :label="$t('content')"
                            auto-grow
                            v-model="about"
                            ></v-textarea>

                        <v-spacer></v-spacer>

                        <v-spacer></v-spacer>

                        <div v-if="loading" class="d-flex justify-center">
                            <v-progress-circular :indeterminate="true" size="40" color="indigo"></v-progress-circular>
                        </div>

                        <div v-else class="d-flex justify-center">
                            <v-btn class="ma-2 mx-auto" type="submit" dark color="indigo">{{$t('send')}}</v-btn>
                        </div>

                    </v-form>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-dialog v-model="alreadyOpenDialog" width="600">
                <v-card>
                    <v-card-title>{{$t('newTicket')}}</v-card-title>
                    <v-card-text>
                        {{$t('alreadyOpen')}}
                    </v-card-text>
                    <v-card-actions v-if="!loading">
                        <v-btn class="ma-2" @click="alreadyOpenDialog = !alreadyOpenDialog" color="primary"><router-link style="color: white;text-decoration: none;" :to="'/' + $i18n.locale + '/support/' + openTicket._id">{{$t('goTo')}}</router-link></v-btn>
                        <v-btn class="ma-2" @click="alreadyOpenDialog = !alreadyOpenDialog" color="default">{{$t('cancel')}}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-snackbar left :color="color" v-model="snackbar">
                {{ message }}
            </v-snackbar>
        </v-container>
    </div>
</template>


<script>
import axios from 'axios';
import auth from '../helpers/auth';

import DashboardNavbar from '../components/DashboardNavbar'

const newAxios = axios.create();
newAxios.defaults.headers.common = {};

export default {
    name:'UserSupport',
    data(){
        return {
            tickets:[],
            dialog:false,
            alreadyOpenDialog: false,
            openTicket: {},
            title:'',
            about:'',
            valid:false,
            loading:false,
            message:'',
            snackbar:false,
            color:''
        }
    },
    components:{DashboardNavbar},
    mounted(){
    if(this.$i18n.locale === 'ar'){
        this.$vuetify.rtl = true;
    }

    newAxios('/api/users/support/user',{
        headers:{
            Authorization:`bearer ${auth.token()}`
        }
    }).then(res=>{
        this.tickets = res.data.supportTickets;
    })
    },
    methods:{
        openNewTicket() {
            const ticket = this.tickets.find(el => el.status == 'open')

            if(ticket) {
                this.openTicket = ticket
                return this.alreadyOpenDialog = true
            }
            
            this.dialog = true
        },

        handleSubmit(){
             if(!this.about){
                    return;
            }

            this.loading = true;

            this.$recaptcha('createSupport').then((token) => {
                //console.log(token) // Will print the token

                newAxios.post('api/users/create/ticket',{
                    about:this.about,
                    captcha: token
                },{
                    headers:{
                        Authorization:`bearer ${auth.token()}`
                    }
                }).then(res=>{

                    if(!res.data.error){
                        
                        if(this.$i18n.locale == 'ar'){
                            this.message = 'تمت الأضافة'
                        }else if(this.$i18n.locale == 'en'){
                            this.message = 'Created Successfully';
                        }

                        this.snackbar = true;
                        this.color = 'success'
                        this.loading = false;

                        this.$refs.form.reset()
                        this.dialog = false;

                        this.tickets.unshift(res.data.supportTicket)
                    }else{

                        if(this.$i18n.locale == 'ar'){
                            this.message = 'حدث خطأ الرجاء المحاولة مجدداً'
                        }else if(this.$i18n.locale == 'en'){
                            this.message = 'An error happened please try again';
                        }

                        this.snackbar = true;
                        this.color = 'error';
                        this.loading = false;

                    }
                }).catch(err=>{
                    if(this.$i18n.locale == 'ar'){
                            this.message = 'حدث خطأ الرجاء المحاولة مجدداً'
                        }else if(this.$i18n.locale == 'en'){
                            this.message = 'An error happened please try again';
                        }

                        this.snackbar = true;
                        this.color = 'error';
                        this.loading = false;
                    
                    console.log(err)
                })
            })

        },

        formatDate(date) {
            var d = new Date(date),
                month = "" + (d.getMonth() + 1),
                day = "" + d.getDate(),
                year = d.getFullYear();


            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            return [year, month, day].join("-");
        }
    }
}
</script>

