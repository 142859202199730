<template>
    <div>



        <v-container class="mt-10 px-5">
            <div style="font-size:20px;font-weight:bold">{{ $t('advancedSearch') }}</div>

            <v-row>
                <v-col cols="12" md="5">
                    <v-select
                        prepend-inner-icon="fas fa-th-large"
                        :label="$t('categories')"
                        outlined
                        v-model="category"
                        :items="categories.map(item=>{
                            return {
                                text:item.name,
                                value:item._id
                            }
                        })"
                    />
                </v-col>

                 <v-col cols="12" md="5">
                    <v-select 
                        prepend-inner-icon="fas fa-flag"
                        :label="$t('countries')"
                        outlined
                        v-model="country"
                        :items="countryList"
                     />
                </v-col>

                <v-col cols="12"  md="2">
                    <v-btn @click.stop="filterShops" dark block depressed class="pa-0" large style="height:55px;width:80%;font-size:19px;font-weight:bold;border-radius:5px" color="#2564FF">
                        {{ $t('search') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>

        <v-container class="d-flex mb-10 pb-10">
            <v-row>
                 <v-col cols="12" md="6" lg="3" v-for="shop in shops" :key="shop._id">
                     <v-card :color="shop.color" target="_blank" :href="shop.link" style="box-shadow: 0 0 10px 0 rgba(221,225,238,0.59);border-radius: 16px;" class="d-flex justify-center align-center pa-5 my-10 mx-5 shop-card" ripple height="150">
                        <v-card-text class="d-flex justify-center align-center">
                            <img :src="backendUrl + '/' + shop.image" width="100" height="100" />
                        </v-card-text>

                    </v-card>
                 </v-col>
            </v-row>
        </v-container>

    
    </div>
</template>

<script>

import {backendUrl} from '../config/config';
import axios from 'axios';

let newAxios = axios.create();

newAxios.defaults.headers.common = {}

export default {
    name:'Market',
    metaInfo: {
    title: 'بورساي - التسوق : تسوق من المتاجر الأوروبية و من اوروبا',
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: "description", content: "أحصل على عنوان فرنسي مجاناً، وتسوق بمفردك من المتاجر الأوروبية والفرنسية. نشحنها إلى باب بيتك بأي مكان في العالم." },
      { name: "keywords", content: "عنوان فرنسي للشحن, عنوان اوروبي للشحن, تجميع الشحنات فرنسا, عنوان فرنسي, porsay, بورساي, الحصول على عنوان فرنسي, france forwarding service, french address for online shopping" }

    ]
  },
    mounted(){
        axios.get('/api/shops/shop/get').then(res=>{
            this.shops = res.data.shops
        })

        axios.get('/api/shops/category/get').then(res=>{
            this.categories = res.data.categories
        })
    },
    data(){
        return {
            shops:[],
            categories:[],
            countryList:[
                 'France',
                 'Austria',
                 'Belgium',
                 'Bulgaria',
                 'Croatia',
                 'Cyprus',
                 'Czech Republic',
                 'Denmark',
                 'Estonia',
                 'Finland',
                 'Germany',
                 'Greece',
                 'Hungary',
                 'Ireland',
                 'Italy',
                 'Latvia',
                 'Lithuania',
                 'Luxembourg',
                 'Malta',
                 'Netherlands',
                 'Poland',
                 'Portugal',
                 'Romania',
                 'Slovakia',
                 'Slovenia',
                 'Spain',
                 'Sweden',
                 'United Kingdom',
            ],
            country:'France',
            category:'',
            backendUrl
        }
    },
    methods:{
        filterShops(){

            axios.get(`/api/shops/shop/filter?category=${this.category}&country=${this.country}`).then(res=>{
                this.shops = res.data.shops
            })
        }
    }
}
</script>


<style scoped>

.v-btn:before {
  display: none;
}

.navbar-link {
  color: grey !important;
  font-size: 16px!important;
}

.navbar-link:hover {
  color:black!important
}

.v-btn--active{
    color:black!important;
}

.header{
    background:white;
    background-image:url('../assets/test4.svg');
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    height:500px
}

.shop-card{

      -webkit-box-shadow: 3px 4px 28px -4px rgba(209,209,209,1);
      -moz-box-shadow: 3px 4px 28px -4px rgba(209,209,209,1);
      box-shadow: 3px 4px 28px -4px rgba(209,209,209,1);
    }



</style>

