<template>
  <v-container>
    <v-row align="center">
      <v-col class="d-flex justify-end align-center" cols="12" sm="6">
        <span class="mr-8 font-weight-bold" style="color: #3f51b5"
          >Choose template:</span
        >
        <v-select
          v-model="template"
          :items="['Mail', 'Parcels']"
          label="type"
          v-on:change="handleTemplate"
        ></v-select>
      </v-col>
    </v-row>
    <v-form @submit.prevent="handleSubmit">
      <v-text-field
        v-model="trackNumber"
        name="trackNumber"
        @input="up"
        label="Track Number"
        id="trackNumber"
        type="text"
        color="indigo"
        required
      ></v-text-field>

      <v-text-field
        v-model="weight"
        name="weight"
        label="Weight"
        id="weight"
        type="text"
        color="indigo"
        required
      ></v-text-field>

      <v-text-field
        v-model="length"
        name="length"
        label="Length"
        id="length"
        type="text"
        color="indigo"
        required
      ></v-text-field>

      <v-text-field
        v-model="width"
        name="width"
        label="Width"
        id="width"
        type="text"
        color="indigo"
        required
      ></v-text-field>

      <v-text-field
        v-model="height"
        name="height"
        label="Hieght"
        id="height"
        type="text"
        color="indigo"
        required
      ></v-text-field>

      <v-select
        v-model="shippingOptions.hasDangerous"
        :items="['Yes', 'No']"
        :label="$t('dangerousQst')"
        required
        @change="$v.select.$touch()"
        @blur="$v.select.$touch()"
      ></v-select>

      <v-text-field
        v-model="fees"
        name="fees"
        label="Fees"
        id="fees"
        type="text"
        color="indigo"
        required
      ></v-text-field>

      <v-autocomplete
        v-model="userId"
        :items="users"
        filled
        :filter="customFilter"
        color="indigo"
        label="User"
        item-text="firstName"
        item-value="id"
      >
        <template v-slot:selection="data">
          <div class="ma-2">
            {{ data.item.firstName + " " + data.item.lastName }}
          </div>

          <div
            style="border-radius:25px;font-size:14px;"
            class="ma-1 pa-1 grey lighten-1"
          >
            {{ data.item.addressHash }}
          </div>
        </template>

        <template v-slot:item="data">
          <template>
            <v-list-item-content>
              <v-list-item-title>
                {{ data.item.firstName + " " + data.item.lastName }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ data.item.addressHash }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>

      <v-file-input
        accept="image/*"
        chips
        multiple
        label="Package Images"
        prepend-icon="fa-solid fa-paperclip"
        @change="this.onFilesPicked"
      ></v-file-input>

      <template v-for="info in infos">
        <v-row>
          <v-col>
            <v-text-field
              v-model="info.description"
              name="description"
              label="Description"
              id="description"
              type="text"
              color="indigo"
              required
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="info.quantity"
              name="quantity"
              label="Quantity"
              id="quantity"
              type="number"
              min="0"
              color="indigo"
              required
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="info.price"
              name="price"
              label="Price"
              id="price"
              type="number"
              min="0"
              color="indigo"
              required
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="info.madeIn"
              name="madeIn"
              label="Where was the item made in?"
              id="madeIn"
              type="text"
              color="indigo"
              required
            ></v-text-field>
          </v-col>

          <v-col>
            <v-btn icon color="error" @click="deleteLine(info)"
              ><i-icon
                color="error"
                size="30"
                class="fa-solid fa-times-circle"
              ></i-icon
            ></v-btn>
          </v-col>
        </v-row>
      </template>
      <v-btn depressed color="primary" @click="addLine">
        New line
      </v-btn>

      <div v-if="loading" class="d-flex justify-center">
        <v-progress-circular
          :indeterminate="true"
          size="40"
          color="indigo"
        ></v-progress-circular>
      </div>

      <div v-else class="d-flex justify-center">
        <v-btn class="ma-2 mx-auto" type="submit" dark color="indigo"
          >Create</v-btn
        >
      </div>
    </v-form>

    <v-dialog v-model="confirmModal" max-width="600">
      <v-card>
        <v-card-title class="headline">Please confirm your action</v-card-title>
        <v-spacer></v-spacer>
        <v-card-text>
          <v-alert border="top" colored-border type="error" elevation="2">
            This Package <b>{{ trackNumber }}</b> ? already exist! Are you sure
            that you want to add it again?
          </v-alert>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn depressed @click="handleAddPackage" color="success">Add</v-btn>
          <v-btn
            @click="confirmModal = !confirmModal"
            class="ma-2"
            color="grey white--text"
            >close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar left :color="color" v-model="snackbar">{{
      message
    }}</v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import auth from "../helpers/auth";

export default {
  name: "AddPackage",
  data() {
    return {
      template: "Parcels",
      admin: auth.admin(),
      adminId: 1,
      supportId: 2,
      exist: {},
      confirmModal: false,
      price: "",
      trackNumber: "",
      weight: "",
      price: "",
      fees: "",
      users: [],
      userId: "",
      images: [],
      loading: false,
      message: "",
      color: "",
      selectDangerOption: "",
      snackbar: false,
      height: "",
      width: "",
      length: "",
      infos: [
        {
          description: "",
          madeIn: "",
          type: "",
          quantity: 0,
          price: 0,
          index: 0,
        },
      ],
      shippingOptions: {
        hasDangerous: false,
      },
      line: 1,
    };
  },
  mounted() {
    //to allow only admin with groupId 1 and 3 = full access admin + assemble admin
    if (this.admin.groupId > 1 && this.admin.groupId !== 3) {
      this.$router.push("support/");
    }

    axios.get("/api/users/?all=true").then((res) => {
      this.users = res.data.users;
    });
  },
  methods: {
    customFilter(item, queryText, itemText) {
      const addressHash = item.addressHash.toLowerCase();
      const username = item.fullName.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        addressHash.indexOf(searchText) > -1 ||
        username.indexOf(searchText) > -1
      );
    },
    handleTemplate(value) {
      if (value === "Mail") {
        this.infos = [
          {
            description: "Letter",
            madeIn: "",
            type: "Mail",
            quantity: 1,
            price: 5,
            index: 0,
          },
        ];
      } else if (value === "Parcels") {
        this.infos = [
          {
            description: "",
            madeIn: "",
            type: "Parcels",
            quantity: 0,
            price: 0,
            index: 0,
          },
        ];
      }
    },
    addLine() {
      this.line++;

      switch (this.template) {
        case "Mail":
          this.infos.push({
            description: "Letter",
            type: "Mail",
            quantity: 1,
            price: 5,
            index: this.line,
          });
          break;

        case "Parcels":
          this.infos.push({
            description: "",
            type: "Parcels",
            quantity: 0,
            price: 0,
            index: this.line,
          });
          break;

        default:
          break;
      }
    },

    deleteLine(info) {
      this.infos = this.infos.filter((item) => item.index != info.index);
    },

    handleAddPackage() {
      this.loading = true;

      const data = new FormData();

      data.append("height", this.height);
      data.append("weight", this.weight);
      data.append("length", this.length);
      data.append("width", this.width);
      data.append("fees", this.fees);
      data.append("trackNumber", this.trackNumber);
      data.append("userId", this.userId);

      this.images.forEach((item) => {
        data.append("images", item);
      });

      let details = this.infos.map((item) => {
        return {
          description: item.description,
          quantity: item.quantity,
          price: item.price,
          madeIn: item.madeIn,
        };
      });

      this.shippingOptions.hasDangerous =
        this.shippingOptions.hasDangerous === "Yes" ? true : false;

      data.append("shippingOptions", this.shippingOptions);

      details = JSON.stringify(details);

      data.append("details", details);

      axios
        .post("/api/packages/add", data)
        .then((res) => {
          if (!res.data.error) {
            this.loading = false;
            this.message = "Created Successfully";
            this.color = "indigo";
            this.snackbar = true;

            //emptying the fields
            this.width = "";
            this.price = "";
            this.height = "";
            this.length = "";
            this.weight = "";
            this.fees = "";
            this.infos = [
              {
                description: "",
                madeIn: "",
                type: "",
                quantity: 0,
                price: 0,
                index: 0,
              },
            ];
            this.line = 1;
            this.userId = "";
            this.trackNumber = "";
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.message = "An error occurred please try again later";
          this.color = "error";
          this.snackbar = true;
        });
    },

    handleSubmit() {
      this.loading = true;

      if (this.trackNumber) {
        axios
          .get("/api/packages/get/all?query=" + this.trackNumber)
          .then((res) => {
            this.exist = res.data.packages;

            if (!this.exist.length > 0) {
              const data = new FormData();

              data.append("height", this.height);
              data.append("weight", this.weight);
              data.append("length", this.length);
              data.append("width", this.width);
              data.append("fees", this.fees);
              data.append("trackNumber", this.trackNumber);
              data.append("userId", this.userId);

              this.images.forEach((item) => {
                data.append("images", item);
              });

              let details = this.infos.map((item) => {
                return {
                  description: item.description,
                  quantity: item.quantity,
                  price: item.price,
                  madeIn: item.madeIn,
                };
              });

              this.shippingOptions.hasDangerous =
                this.shippingOptions.hasDangerous === "Yes" ? true : false;

              data.append("shippingOptions", this.shippingOptions);

              details = JSON.stringify(details);

              data.append("details", details);

              axios
                .post("/api/packages/add", data)
                .then((res) => {
                  if (!res.data.error) {
                    this.loading = false;
                    this.message = "Created Successfully";
                    this.color = "indigo";
                    this.snackbar = true;

                    //emptying the fields
                    this.width = "";
                    this.price = "";
                    this.height = "";
                    this.length = "";
                    this.weight = "";
                    this.fees = "";
                    this.infos = [
                      {
                        description: "",
                        madeIn: "",
                        type: "",
                        quantity: 0,
                        price: 0,
                        index: 0,
                      },
                    ];
                    this.line = 1;
                    this.userId = "";
                    this.trackNumber = "";
                  }
                })
                .catch((err) => {
                  console.log(err);
                  this.loading = false;
                  this.message = "An error occurred please try again later";
                  this.color = "error";
                  this.snackbar = true;
                });
            } else {
              this.loading = false;
              this.confirmModal = true;
            }
          });
      }
    },
    onFilesPicked(f) {
      this.images = f;
    },
    up() {
      this.trackNumber = this.trackNumber.toUpperCase();
    },
  },
};
</script>

<style scoped></style>
