<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      temporary
      app
      hide-overlay
      :right="this.$i18n.locale == 'ar' ? false : true"
      :left="this.$i18n.locale != 'ar' ? false : true"
      width="300"
      id="drawer"
      value="false"
    >
      <v-list nav>
        <v-list-item
          :to="'/' + this.$i18n.locale + '/dashboard'"
          v-if="loggedIn"
          color="white"
        >
          <div>
            <v-icon
              size="20"
              :right="this.$i18n.locale == 'ar' ? true : false"
              :left="this.$i18n.locale != 'ar' ? true : false"
              >fas fa-door-closed</v-icon
            >
          </div>

          <v-list-item-content>
            <v-list-item-title class="navbar-title">{{
              $t('locker')
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item href="https://porsay.com/stores/" color="white">
          <div>
            <v-icon
              size="20"
              :right="this.$i18n.locale == 'ar' ? true : false"
              :left="this.$i18n.locale != 'ar' ? true : false"
              >fas fa-shopping-cart</v-icon
            >
          </div>

          <v-list-item-content>
            <v-list-item-title class="navbar-title">{{
              $t('shop')
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item href="https://porsay.com/feature/" color="white">
          <div>
            <v-icon
              size="20"
              :right="this.$i18n.locale == 'ar' ? true : false"
              :left="this.$i18n.locale != 'ar' ? true : false"
              >fas fa-money-bill-wave</v-icon
            >
          </div>

          <v-list-item-content>
            <v-list-item-title class="navbar-title">{{
              $t('pricing')
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- <v-list-item color="white">

            <div>
                    <v-icon size="20" :right="(this.$i18n.locale =='ar')? true: false" 
                    :left="(this.$i18n.locale != 'ar')? true : false" >fas fa-heart</v-icon>
                </div>

                <v-list-item-content>
                    <v-list-item-title class="navbar-title">{{$t('review')}}</v-list-item-title>
                </v-list-item-content>

            </v-list-item> -->

        <v-list-item :to="'/' + this.$i18n.locale + '/faq'" color="white">
          <div>
            <v-icon
              size="20"
              :right="this.$i18n.locale == 'ar' ? true : false"
              :left="this.$i18n.locale != 'ar' ? true : false"
              >fas fa-question-circle</v-icon
            >
          </div>

          <v-list-item-content>
            <v-list-item-title class="navbar-title">{{
              $t('about')
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- <v-list-item @click="goSupport" color="white" >

            <div>
                    <v-icon size="20" :right="(this.$i18n.locale =='ar')? true: false" 
                    :left="(this.$i18n.locale != 'ar')? true : false" >fas fa-envelope</v-icon>
                </div>

                <v-list-item-content>
                    <v-list-item-title class="navbar-title">{{$t('contact')}}</v-list-item-title>
                </v-list-item-content>

            </v-list-item> -->

        <v-list-item
          color="white"
          v-if="!loggedIn"
          :to="'/' + this.$i18n.locale + '/login'"
        >
          <div>
            <v-icon
              size="20"
              :right="this.$i18n.locale == 'ar' ? true : false"
              :left="this.$i18n.locale != 'ar' ? true : false"
              >fas fa-sign-in-alt</v-icon
            >
          </div>

          <v-list-item-content>
            <v-list-item-title class="navbar-title">{{
              $t('login')
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          color="white"
          v-if="!loggedIn"
          :to="'/' + this.$i18n.locale + '/signup'"
        >
          <div>
            <v-icon
              size="20"
              :right="this.$i18n.locale == 'ar' ? true : false"
              :left="this.$i18n.locale != 'ar' ? true : false"
              >fas fa-plus-circle</v-icon
            >
          </div>

          <v-list-item-content>
            <v-list-item-title class="navbar-title">{{
              $t('register')
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item color="white" @click="logout" v-if="loggedIn">
          <div>
            <v-icon
              size="20"
              :right="this.$i18n.locale == 'ar' ? true : false"
              :left="this.$i18n.locale != 'ar' ? true : false"
              >fas fa-sign-out-alt</v-icon
            >
          </div>

          <v-list-item-content>
            <v-list-item-title class="navbar-title">{{
              $t('logout')
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item color="white">
          <div>
            <v-icon
              size="20"
              :right="this.$i18n.locale == 'ar' ? true : false"
              :left="this.$i18n.locale != 'ar' ? true : false"
              >fas fa-language</v-icon
            >
          </div>

          <v-list-item-content>
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-list-item-title
                  style="cursor:pointer"
                  class="navbar-title"
                  v-on="on"
                  text
                >
                  {{ $i18n.locale.toUpperCase() }}
                </v-list-item-title>
              </template>
              <v-list>
                <v-list-item href="/en">
                  <v-list-item-title>EN</v-list-item-title>
                </v-list-item>
                <v-list-item href="/fr">
                  <v-list-item-title>FR</v-list-item-title>
                </v-list-item>
                <v-list-item href="/ar">
                  <v-list-item-title>AR</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-toolbar style="height:103px" class="grey lighten-4 pa-5" app dense flat>
      <v-toolbar-title>
        <a href="https://porsay.com">
          <v-img src="../assets/Header-Logo.svg" width="140" />
        </a>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn
          v-if="loggedIn"
          :to="'/' + this.$i18n.locale + '/dashboard'"
          :ripple="false"
          text
          class="navbar-link"
        >
          <v-icon left size="17">fas fa-door-closed</v-icon>{{ $t('locker') }}
          <v-chip
            color="indigo"
            text-color="white"
            x-small
            style="margin:5px"
            >{{ incomePackages.length }}</v-chip
          >
        </v-btn>
        <v-btn
          href="https://porsay.com/stores/"
          :ripple="false"
          text
          class="navbar-link"
        >
          <v-icon left size="17">fas fa-shopping-cart</v-icon>{{ $t('shop') }}
        </v-btn>
        <v-btn
          href="https://porsay.com/feature/"
          :ripple="false"
          text
          class="navbar-link"
        >
          <v-icon left size="17">fas fa-money-bill-wave</v-icon
          >{{ $t('pricing') }}
        </v-btn>
        <!-- <v-btn :ripple="false" text class="navbar-link">
                <v-icon left size="17">fas fa-heart</v-icon>{{$t('review')}}
                </v-btn> -->
        <v-btn
          :to="`/${this.$i18n.locale}/faq`"
          :ripple="false"
          text
          class="navbar-link"
        >
          <v-icon left size="17">fas fa-question-circle</v-icon
          >{{ $t('about') }}
        </v-btn>
        <!-- <v-btn @click="goSupport" :ripple="false" text class="navbar-link">
                <v-icon left size="17">fas fa-envelope</v-icon>{{$t('contact')}}
                </v-btn> -->
        <v-btn
          v-if="!loggedIn"
          :to="`/${this.$i18n.locale}/login`"
          :ripple="false"
          text
          class="navbar-link"
        >
          <v-icon left size="17">fas fa-sign-in-alt</v-icon>{{ $t('login') }}
        </v-btn>

        <v-btn
          v-if="!loggedIn"
          :to="`/${this.$i18n.locale}/signup`"
          :ripple="false"
          style="border-radius:5px"
          dark
          depressed
          color="#2564FF"
        >
          <v-icon left size="17">fas fa-plus-circle</v-icon>{{ $t('register') }}
        </v-btn>

        <v-btn
          v-if="loggedIn"
          @click="logout"
          :ripple="false"
          text
          class="navbar-link"
        >
          <v-icon left size="17">fas fa-sign-out-alt</v-icon>{{ $t('logout') }}
        </v-btn>

        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" :ripple="false" text class="navbar-link">
              <v-icon left size="17">fas fa-language</v-icon>
              {{ $i18n.locale.toUpperCase() }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item href="/en">
              <v-list-item-title>EN</v-list-item-title>
            </v-list-item>
            <v-list-item href="/fr">
              <v-list-item-title>FR</v-list-item-title>
            </v-list-item>
            <v-list-item href="/ar">
              <v-list-item-title>AR</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>

      <v-toolbar-items class="hidden-md-and-up">
        <v-btn text @click.stop="drawer = !drawer"
          ><v-icon color="black" size="25">fas fa-bars</v-icon></v-btn
        >
      </v-toolbar-items>
    </v-toolbar>
  </div>
</template>

<script>
import auth from '../helpers/auth';
import axios from 'axios';

const newAxios = axios.create();
newAxios.defaults.headers.common = {};

export default {
  name: 'LandingNavbar',
  data() {
    return {
      drawer: false,
      loggedIn: auth.user() ? true : false,
      incomePackages: [],
      ticket: [],
    };
  },
  mounted() {
    if (this.$i18n.locale == 'ar') {
      this.$vuetify.rtl = true;
    }

    if (auth.user()) {
      newAxios
        .get('/api/users/packages/get', {
          headers: {
            Authorization: `bearer ${auth.token()}`,
          },
        })
        .then((res) => {
          this.incomePackages = res.data.packages;
        })
        .catch((err) => {});
    }
  },
  methods: {
    logout() {
      auth.logout();
      this.$emit('authChange');
      this.$router.push('/' + this.$i18n.locale + '/login');
    },
    goSupport() {
      if (!this.loggedIn) {
        this.$router.push('/' + this.$i18n.locale + '/support/');
      }
      if (!this.ticket) {
        this.dialog = true;
      } else {
        newAxios
          .get('/api/users/support/latest', {
            headers: {
              Authorization: `bearer ${auth.token()}`,
            },
          })
          .then((res) => {
            this.ticket = res.data.supportTicket._id;
            window.location.href = 'https://www.porsay.com';
          });
      }
    },
  },
};
</script>

<style scoped>
.v-btn:before {
  display: none;
}

.navbar-link {
  color: grey !important;
  font-size: 16px !important;
}

.navbar-link:hover {
  color: black !important;
}

.v-list .v-list-item--active {
  color: rgb(77, 75, 75) !important;
}
</style>
