<template>
    <v-container>
        <v-data-table
            :headers="tableHeaders"
            :items="requests"
            :page.sync="page"
            :server-items-length="requestCount"
            class="elevation-2 grey lighten-5 ma-3"
            @update:page="pageUpdateFunction"
            @update:items-per-page="itemsPerPageUpdateFunction"
            :items-per-page.sync="itemsPerPage"
        >

            <template v-slot:top>
                <v-row class="d-flex align-center">
                    <v-col :cols="10">
                        <v-text-field v-model="search" placeholder="Search" class="mx-4"></v-text-field>
                    </v-col>
                    <v-col :cols="2">
                        <v-btn class="mx-4" @click="searchUpdateFunction" color="primary">Search</v-btn>
                    </v-col>
                </v-row>
            </template>

            <template v-slot:item.trackNumber="{ item }">
                <router-link 
                    style="text-decoration:none;font-size:16px;color:#2E86C1"
                    :to="'/en/admin/user/' + item.user.id"
                >
                 <v-icon color="red" class="mx-3" size="15" v-if="(item.user) ? item.user.suspicious : false">fas fa-exclamation-triangle</v-icon>   {{(item.user) ? item.user.fullName : ''}}
                </router-link>
            </template>

            <template v-slot:item.userHash="{ item }">
                <div :class="isExpired(item.createdAt)">{{item.user.addressHash}}</div>
            </template>

            <template v-slot:item.status="{ item }">
                <div :class="isExpired(item.createdAt)">{{(item.approved) ? 'approved' : 'unapproved'}}</div>
            </template>

             <template v-slot:item.packageId="{ item }">
                <router-link
                style="text-decoration:none;font-size:16px;color:#2E86C1"
                :to="'/en/admin/manage-packages?packageId=' + item.assemblePackage.packageId"
                >{{(item.assemblePackage) ? item.assemblePackage.packageId: ''}}</router-link>
            </template>

            <template v-slot:item.date="{ item }">
                <div :class="isExpired(item.createdAt)">{{ formatDate(item.createdAt)}}</div>
            </template>

            <template v-slot:item.actions="{ item }">
                <div>
                    <v-btn @click="showPackages(item)" class="ma-2" small tile outlined color="primary">
                        <v-icon size="15" left>fas fa-eye</v-icon> show
                    </v-btn>

                    <v-btn v-if="item.approved && user.groupId <= adminId" class="ma-2" @click="deleteRequest(item)"  small tile outlined color="error">
                        <v-icon size="15" left>fas fa-trash</v-icon> delete
                    </v-btn>

                    <v-btn v-if="!item.approved && user.groupId <= adminId" class="ma-2" @click="cancelRequest(item)"   small tile outlined color="warning">
                        <v-icon size="15" left>fas fa-times-circle</v-icon> cancel
                    </v-btn>

                    <v-btn class="ma-2" v-if="item.assemblePackage && user.groupId <= adminId" @click.stop="revertRequest(item)" small tile outlined color="warning" :disabled="!item.approved|| item.assemblePackage.approved || item.assemblePackage.awaitingApprove">
                        <v-icon size="15" left>fas fa-undo</v-icon> revert
                    </v-btn>

                </div>
            </template>

            <template v-slot:footer>
                <v-row justify="center">
                    <v-col cols="8">
                        <v-container class="max-width">
                            <v-pagination
                            v-model="page"
                            class="my-4"
                            :total-visible="7"
                            :length="pageCount"
                            @input="pageUpdateFunction"
                            ></v-pagination>
                        </v-container>
                    </v-col>
                </v-row>
            </template>

        </v-data-table>


        <v-dialog v-model="cancelDialog" max-width="600">

            <v-card>
                <v-card-title class="headline">Please confirm your action</v-card-title>
                <v-spacer></v-spacer>
                <v-card-text>
                <v-alert
                    border="top"
                    colored-border
                    type="warning"
                    elevation="2"
                >
                    Are you sure that you want to cancel this request ?
                </v-alert>
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn depressed @click="handleCancelRequest(selectedRequest._id)" color="error">Cancel Request</v-btn>
                <v-btn @click="cancelDialog=!cancelDialog" class="ma-2" color="grey white--text">close</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>

        <v-dialog v-model="revertDialog" max-width="600">

            <v-card>
                <v-card-title class="headline">Please confirm your action</v-card-title>
                <v-spacer></v-spacer>
                <v-card-text>
                <v-alert
                    border="top"
                    colored-border
                    type="warning"
                    elevation="2"
                >
                    Are you sure that you want to revert this request ?
                </v-alert>
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn depressed @click="handleRevertRequest()" color="error">Revert Request</v-btn>
                <v-btn @click="revertDialog=!revertDialog" class="ma-2" color="grey white--text">close</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>

        <v-dialog v-model="deleteDialog" max-width="600">

            <v-card>
                <v-card-title class="headline">Please confirm your action</v-card-title>
                <v-spacer></v-spacer>
                <v-card-text>
                <v-alert
                    border="top"
                    colored-border
                    type="error"
                    elevation="2"
                >
                    Are you sure that you want to delete this request ?
                </v-alert>
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn depressed @click="handleDeleteRequest(selectedRequest._id)" color="error">Delete</v-btn>
                <v-btn @click="deleteDialog=!deleteDialog" class="ma-2" color="grey white--text">close</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>


        <v-dialog v-model="dialog" max-width="800">
            <v-card class="pa-3">
                <v-card-title>Packages</v-card-title>
                <v-card-text>
                        <v-row>
                            <v-col style="font-size:16px" sm="6">Username: <b>{{selectedRequest.user ? selectedRequest.user.fullName : ''}}</b></v-col>
                            <v-col style="font-size:16px" sm="6">User Hash: <b>{{selectedRequest.user ? selectedRequest.user.addressHash : ''}}</b></v-col>
                        </v-row>

                        <div>
                          <span style="font-size:18px">Packages Count: </span> <span style="font-weight:bold;font-size:17.5px">{{selectedRequest.packages ? selectedRequest.packages.length : ''}}</span>
                        </div>
                        <div>
                          <span style="font-size:18px">Packages Total Weight: </span> <span style="font-weight:bold;font-size:17.5px">{{selectedRequest.packages ? selectedRequest.packages.reduce((total,pack)=>{
                              return total + pack.weight;
                          },0) : ''}} KG</span>
                        </div>


                    <v-card class="pa-0 grey my-4 lighten-4" v-for="pack in selectedRequest.packages" v-viewer :key="pack.id" >
                        <v-row>
                        <v-col sm="6">
                            <img height="150" class="ma-2 elevation-3" :src="backendUrl + '/' + pack.images[0]"></img>
                        </v-col>

                        <v-col sm="6">
                            <div class="mx-4 my-2" style="font-size:16px"><b>Track Number: </b> <span>{{pack.trackNumber}}</span></div>
                            <div class="mx-4 my-2" style="font-size:16px"><b>Weight: </b> <span>{{pack.weight}} kg</span></div>
                            <div class="mx-4 my-2" style="font-size:16px"><b>dimensions: </b> <span>{{pack.length}} x {{pack.width}} x {{pack.height}} cm </span></div>
                        
                        </v-col>
                    </v-row>
                    </v-card>
                     
                </v-card-text>

                <v-card-text>
                    <v-row v-for="detail in details" :key="detail">
                            <v-col>
                                <v-text-field
                                    v-model="detail.description"
                                    name="description"
                                    :label="$t('description')"
                                    id="description"
                                    type="text"
                                    color="indigo"
                                    readonly
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    v-model="detail.quantity"
                                    name="quantity"
                                    :label="$t('quantity')"
                                    id="quantity"
                                    type="number"
                                    min="0"
                                    color="indigo"
                                    readonly
                                    >
                                </v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    v-model="detail.price"
                                    name="price"
                                    :label="$t('price')"
                                    id="price"
                                    type="number"
                                    min="0"
                                    color="indigo"
                                    readonly
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    v-model="detail.madeIn"
                                    name="madeIn"
                                    label="Where was the item made in?"
                                    id="madeIn"
                                    type="text"
                                    color="indigo"
                                    readonly
                                    ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    v-model="detail.type"
                                    name="type"
                                    label="Type"
                                    id="type"
                                    type="text"
                                    color="indigo"
                                    readonly
                                    ></v-text-field>
                            </v-col>
                        </v-row>
                </v-card-text>

                <v-card-actions v-if="!loading" class="d-flex justify-end">
                        <v-btn v-if="!selectedRequest.approved && user.groupId <= adminId" @click="acceptDialog=!acceptDialog" class="ma-2" color="success white--text">Accept</v-btn>
                        <v-btn @click="dialog=!dialog" class="ma-2" color="grey white--text">cancel</v-btn>
                </v-card-actions>

                    
                <v-card-actions v-else class="d-flex justify-center">
                     <v-progress-circular :indeterminate="true" size="40" color="indigo"></v-progress-circular>
                </v-card-actions>

                
            </v-card>
        </v-dialog>

        <v-dialog max-width="650" v-model="acceptDialog">
            <v-card>
                <v-card-title>Package Information</v-card-title>

                <v-card-text>
                     <v-form @submit.prevent="handleSubmit">
     

                        <v-text-field
                        v-model="weight"
                        name="weight"
                        label="Weight"
                        id="wieght"
                        type="text"
                        color="indigo"
                        required
                        ></v-text-field>

                        <v-text-field
                        v-model="height"
                        name="height"
                        label="Height"
                        id="height"
                        type="text"
                        color="indigo"
                        required
                        ></v-text-field>

                        <v-text-field
                        v-model="width"
                        name="width"
                        label="Width"
                        id="width"
                        type="text"
                        color="indigo"
                        required
                        ></v-text-field>

                        <v-text-field
                        v-model="length"
                        name="name"
                        label="Length"
                        id="Length"
                        type="text"
                        color="indigo"
                        required
                        ></v-text-field>

                        <v-file-input
                            accept="image/*"
                            chips
                            multiple
                            label="Package Images"
                            prepend-icon="fas fa-paperclip"
                            @change="this.onFilesPicked"
                            ></v-file-input>


                        <div v-if="acceptLoading" class="d-flex justify-center">
                        <v-progress-circular
                        :indeterminate="true"
                        size="40"
                        color="indigo"
                    ></v-progress-circular>
                        </div>

                        <div v-else class="d-flex justify-center" >
                        <v-btn class="ma-2 mx-auto"  type="submit"  dark color="success" >
                            Confirm
                        </v-btn>
                        </div>
                     </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>

       

        <v-snackbar left :color="color" v-model="snackbar">{{ message }}</v-snackbar>
    </v-container>
</template>

<script>
import axios from 'axios';
import moment from 'moment'
import auth from '../helpers/auth';
import {backendUrl} from '../config/config'

export default {
    name:'Manage Assemble',
    mounted(){
        axios.get('/api/packages/groups/complete').then(res=>{
            this.requestCount = res.data.requestCount
            this.pageCount = res.data.pageCount
            this.requests = res.data.packagesGroups


        }).catch(err=>{
            this.snackbar = true;
            this.message = 'An error happened please try again'
            this.color = 'error'
        })
    },

    watch: {
        search: function (newSearch) {
            if (newSearch === '') {
                axios.get('/api/packages/groups').then(res => {
                    this.page = 1
                    this.requestCount = res.data.requestCount
                    this.pageCount = res.data.pageCount
                    this.requests = res.data.packagesGroups
                })
            }
        }
    },

    data(){
        return {
            user: auth.admin(),
            adminId: 1,
            supportId: 2,
            page: 1,
            pageCount: 5,
            requestCount: 1,
            itemsPerPage: 10,
            tableHeaders:[
                {
                    text:'Username',
                    value:'trackNumber',
                    align:'center'
                },
                {
                    text:'User Hash',
                    value:'userHash',
                    align:'center'
                },
                {
                    text:'Request Date',
                    value:'date',
                    align:'center'
                },
                {
                    text:'Package ID',
                    value:'packageId',
                    align:'center'
                },
                {
                    text:'Status',
                    value:'status',
                    align:'center'
                },
                {
                    text:'Actions',
                    value:'actions',
                    align:'center'
                }
            ],
            requests:[],
            dialog:false,
            cancelDialog: false,
            deleteDialog: false,
            snackbar:false,
            message:'',
            color:'',
            loading:false,
            search:'',
            selectedRequest:{},
            backendUrl,

           

            //accept assemble 
            acceptDialog:false,
            height:'',
            width:'',
            weight:'',
            length:'',
            images:[],
            price:'',
            details: [
                {
                    description: '',
                    madeIn: '',
                    type: '',
                    quantity: 0,
                    price: 0,
                    index: 0
                }
            ],
            acceptLoading:false,
            revertDialog:false
        }
    },

    methods:{
        // customFilter (value, queryText, item) {
        
        //     const addressHash = item.user.addressHash.toLowerCase()
        //     const username = item.user.fullName.toLowerCase()
        //     const searchText = queryText.toLowerCase()

        //     return addressHash.indexOf(searchText) > -1 || username.indexOf(searchText) > -1
        // },
        isExpired(item){
            const itemDate = moment(item)
            if(itemDate.isBefore(moment().subtract(6, 'months')) === true){
                return "expired"
            } else{
                return ''
            }
        },

       
        pageUpdateFunction(newPageNumber) {
            axios.get(`/api/packages/groups?page=${newPageNumber-1}&perPage=${this.itemsPerPage}`).then(res=>{
                this.page = newPageNumber
                this.requestCount = res.data.requestCount
                this.pageCount = res.data.pageCount
                this.requests = res.data.packagesGroups

            })

        },
        itemsPerPageUpdateFunction(length) {
            this.page= 1
            this.pageCount = 1
            axios.get(`/api/packages/groups?page=${this.pageCount -1}&perPage=${length}`).then(res=>{
                this.requestCount = res.data.requestCount
                this.pageCount = res.data.pageCount
                this.requests = res.data.packagesGroups

            })

        },

        searchUpdateFunction(){
            const newQuery = this.search
            if (newQuery) {
                axios.get(`/api/packages/groups?query=${newQuery}`).then(res => {
                    this.requestCount = res.data.requestCount
                    this.pageCount = res.data.pageCount
                    this.requests = res.data.packagesGroups
                })
            }
        },

    formatDate(date) {
        var d = new Date(date) ,
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            hour = d.getHours(),
            mins = d.getMinutes();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
        if(mins < 10)
           mins = '0' + mins;
        if(hour < 10)
           hour = '0' + hour;

        return [year, month, day].join('-') + ` ${hour}:${mins}`;
 },
    
    showPackages(request){
        let detArr = []

        request.packages.forEach(pack => {
            pack.details.forEach(det => detArr.push(det))
        })

        this.selectedRequest = request;
        this.details = detArr
        this.dialog = true; 
    },

    cancelRequest(request) {
        this.dialog = false;
        this.deleteDialog = false; 

        this.selectedRequest = request;
        this.cancelDialog = true; 
    },

    revertRequest(request) {
        this.dialog = false;
        this.deleteDialog = false;
        this.cancelRequest = false; 

        this.selectedRequest = request;
        this.revertDialog = true; 
    },

    deleteRequest(request) {
        this.dialog = false;
        this.cancelDialog = false;

        this.selectedRequest = request;
        this.deleteDialog = true; 
    },

    handleCancelRequest(id) {
        axios.post('/api/packages/group/cancel',{requestId:id}).then(res=>{

            if(!res.error){

                this.message = 'Request was canceled Successfully';
                 this.color = 'warning';
                 this.snackbar = true;

                 this.requests = this.requests.filter(item=>{
                    
                    return item._id != id
                 })
                 this.cancelDialog = false;
            }
        }).catch(err=>{
            console.log(err)
             this.loading = false;
             this.message = 'An error occurred please try again later';
             this.color = 'error';
             this.snackbar = true;
        })
    },
    handleRevertRequest() {
        axios.post('/api/packages/group/revert',{requestId:this.selectedRequest._id}).then(res=>{

            if(!res.error){

                this.message = 'Request was reverted Successfully';
                 this.color = 'warning';
                 this.snackbar = true;

                 this.selectedRequest.approved = false;
                 this.revertDialog = false;
            }
        }).catch(err=>{
            console.log(err)
             this.loading = false;
             this.message = 'An error occurred please try again later';
             this.color = 'error';
             this.snackbar = true;
        })
    },

    handleDeleteRequest(id){

        axios.post('/api/packages/group/delete',{requestId:id}).then(res=>{

            if(!res.error){

                this.message = 'Deleted Successfully';
                 this.color = 'warning';
                 this.snackbar = true;

                 this.requests = this.requests.filter(item=>{
                    
                    return item._id != id
                 })
                 this.deleteDialog = false; 
            }
        }).catch(err=>{
            console.log(err)
             this.loading = false;
             this.message = 'An error occurred please try again later';
             this.color = 'error';
             this.snackbar = true;
        })
    },

    onFilesPicked(f){
          this.images = f;
      },
    handleSubmit(){
          this.acceptLoading = true;

         const data = new FormData();

         data.append('height',this.height)
         data.append('weight',this.weight)
         data.append('length',this.length)
         data.append('width',this.width)
         data.append('requestId',this.selectedRequest._id)

         // If any package hasDangerous or hasLithium, the assembly also will
         let shippingOptions = {}
         this.selectedRequest.packages.forEach(item=>{
            if(item.shippingOptions.hasDangerous)
                shippingOptions.hasDangerous = true
            if(item.shippingOptions.hasLithium)
                shippingOptions.hasLithium = true
         })
        const so = JSON.stringify(shippingOptions)
        data.append('shippingOptions', so)
        let details = JSON.stringify(this.details)

        data.append('details',details)

         this.images.forEach((item)=>{
            data.append('images',item)
         })

         axios.post('/api/packages/add-assemble',data).then((res)=>{
             if(!res.data.error){
                 this.acceptLoading = false;
                 this.acceptDialog = false;
                 this.message = 'Assembled Successfully';
                 this.color = 'indigo';
                 this.snackbar = true;

                 //emptying the fields
                 this.width = '';
                 this.price = '';
                 this.height = '';
                 this.length = '';
                 this.weight = '';
                 this.details = [
                    {
                        description: '',
                        madeIn: '',
                        type: '',
                        quantity: 0,
                        price: 0,
                        index: 0
                    }
                ],

                 this.requests.forEach(item=>{
                     if(item._id == this.selectedRequest._id){
                         item.approved = true;
                     }
                 })

                 this.selectedRequest.approved = true;
                 
             }
         }).catch(err=>{
             console.log(err)
             this.loading = false;
             this.message = 'An error occurred please try again later';
             this.color = 'error';
             this.snackbar = true;
         })
      },
    

}
    }

</script>

<style scoped>
    .expired{
        color: red;
        font-weight: bold;
    }
</style>