import Vue from 'vue';
import VueI18n from 'vue-i18n';

import en from 'vuetify/lib/locale/en'
import ar from 'vuetify/lib/locale/ar'
import fr from 'vuetify/lib/locale/fr'


Vue.use(VueI18n)

const messages = {
  en: {
    ...require('../locales/en.json'),
    $vuetify: en,
  },
  ar: {
    ...require('../locales/ar.json'),
    $vuetify: ar,
  },
  fr: {
    ...require('../locales/fr.json'),
    $vuetify: fr,
  }
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'ar',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'ar',
  messages,
})
