<template>
  <v-container>

    <v-btn class="my-4" @click="addCompanyDialog = !addCompanyDialog" color="success"><v-icon small left color="white">fas fa-plus</v-icon>Add Company</v-btn>

    <div style="margin-bottom:40px" v-for="company in companies" :key="company._id">
        
        <div class="d-flex align-center">
          <h4>{{company.name}}</h4>

          <v-btn @click.stop="removeCompany(company._id)" icon tile class="ml-auto">
            <v-icon color="error">fas fa-trash</v-icon>
          </v-btn>

          <v-btn @click="openUpdateCompanyDialog(company)" class="mx-2" fab dark small color="info">
            <v-icon dark>mdi-pencil</v-icon>
          </v-btn>
          
        </div>

        <v-btn color="indigo" @click="onRangeAdd(company)" class="ma-2 white--text">Add Range</v-btn>

        <div class="mt-2">
            <v-card v-for="range in company.ranges" :key="range._id">
                <v-card-text class="ma-2">
                    <v-row>
                        <v-col class="ma-0 pa-0" cols="4">range: {{range.from +'-' + range.to}} kg</v-col>
                        <v-col class="ma-0 pa-0" cols="4"> price: {{range.price}} $</v-col>
                        <v-col class="ma-0 pa-0" cols="4"><v-btn @click="onRangeEdit(range)" color="success">update</v-btn></v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </div>

        
    </div>


    <v-dialog max-width="650" v-model="updateCompanyDialog">
        <v-card>
            <v-card-title>Update shipping company</v-card-title>

            <v-card-text>
                <v-text-field
                  label="Company Name"
                  v-model="updatedCompanyName"
                ></v-text-field>

                <v-text-field
                  label="Company Arabic Name"
                  v-model="updatedCompanyArName"
                ></v-text-field>

                <v-text-field
                  label="Dimensional Weight Coefficent"
                  v-model="updatedCompanyDimWeight"
                 
                ></v-text-field>

                <v-text-field
                  label="minimum shipping time "
                  v-model="updatedMinShipTime"
                ></v-text-field>

                <v-text-field
                  label="maximum shipping time"
                  v-model="updatedMaxShipTime"
                ></v-text-field>

            <div class="d-flex justify-center">
              <v-progress-circular v-if="loading" :indeterminate="true" size="40" color="indigo"></v-progress-circular>

              <v-btn v-else @click="handleUpdateShippingCompany" color="indigo">update</v-btn>
            </div>
            </v-card-text>
        </v-card>
      </v-dialog>

    

    <v-snackbar left :color="color" v-model="snackbar">{{ message }}</v-snackbar>

    <v-dialog max-width="650" v-model="addCompanyDialog">
      <v-card>
          <v-card-title>Add a new shipping company</v-card-title>

          <v-card-text>
              <v-text-field
                label="Company Name"
                v-model="companyName"
              ></v-text-field>

              <v-text-field
                label="Company Arabic Name"
                v-model="companyNameAR"
              ></v-text-field>

              <v-text-field
                label="Dimensional Weight Coefficent"
                v-model="dimensionalWeight"
              ></v-text-field>

              <v-text-field
                label="minShipTime"
                v-model="minShipTime"
              ></v-text-field>

              <v-text-field
                label="maxShipTime"
                v-model="maxShipTime"
              ></v-text-field>

              <v-file-input
              accept="image/*"
              chips
              label="Logo Image"
              prepend-icon="fas fa-paperclip"
              @change="this.onFilesPicked"
              ></v-file-input>

          <div class="d-flex justify-center">
            <v-progress-circular v-if="loading" :indeterminate="true" size="40" color="indigo"></v-progress-circular>

            <v-btn v-else @click="handleShippingCompany" color="success">create</v-btn>
          </div>
          </v-card-text>
      </v-card>
    </v-dialog>


    <v-dialog max-width="650" v-model="addRangeDialog">
        <v-card>
          <v-card-title>Add Range</v-card-title>

          <v-card-text>
              <v-text-field
                label="From"
                v-model="from"
              ></v-text-field>

              <v-text-field
                label="To"
                v-model="to"
              ></v-text-field>

              
              <v-select 
              label="Zone"
              :items="zones"
              v-model="zone"
              />

              <v-text-field
                label="Price"
                v-model="price"
              ></v-text-field>

              <div class="d-flex justify-center">
            <v-progress-circular v-if="loading" :indeterminate="true" size="40" color="indigo"></v-progress-circular>

            <v-btn v-else @click="createRange" color="success">create</v-btn>
          </div>
          </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog max-width="650" v-model="editRangeDialog">
        <v-card>
          <v-card-title>Edit Range</v-card-title>

          <v-card-text>
              <v-text-field
                label="From"
                v-model="from"
              ></v-text-field>

              <v-text-field
                label="To"
                v-model="to"
              ></v-text-field>

              <v-select 
              label="Zone"
              :items="zones"
              v-model="zone"
              />

              <v-text-field
                label="Price"
                v-model="price"
              ></v-text-field>

              <div class="d-flex justify-center">
            <v-progress-circular v-if="loading" :indeterminate="true" size="40" color="indigo"></v-progress-circular>

            <v-btn v-else @click="editRange" color="success">Update</v-btn>
          </div>
          </v-card-text>
        </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios';
import auth from '../helpers/auth';

export default {
  name: "SideShippingRates",
  data() {
    return {
      admin: auth.admin(),
      adminId: 1,
      supportId: 2,
      companies:[],
      message: "",
      snackbar: false,
      loading: false,
      color: "",
      addCompanyDialog:false,
      updateCompanyDialog: false,
      companyName:'',
      companyNameAR:'',
      addRangeDialog:false,
      from:'',
      to:'',
      price:'',
      zone:'',
      selectedCompany:'',
      editRangeDialog:false,
      selectedRange:'',
      dimensionalWeight:'',
      zones:[],
      logoFile:'',
      minShipTime: '',
      maxShipTime: '',
      updatedCompanyName: '',
      updatedCompanyArName: '',
      updatedCompanyDimWeight: '',
      updatedMinShipTime: '',
      updatedMaxShipTime: '',
      updatedCompanyId: ''
    };
  },
  mounted(){

    if( this.admin.groupId > this.adminId ) {
      this.$router.push('support/');
    }

    axios.get('/api/admins/side_get_shipping_companies',{
      headers:{
        Authorization:`Bearer ${auth.adminToken()}`
      }
    }).then((res)=>{
      this.companies = res.data.companies;
    })

     axios.get('/api/zones/').then(res=>{
        this.zones= res.data.zones.map(item=>{
          let obj = {};
          obj.text = item.name;
          obj.value = item._id;

          return obj;
        });
    })
  },
  methods:{
    onRangeAdd(company){
      this.selectedCompany = company;
      this.addRangeDialog = true;
      this.from = '';
      this.to = '';
      this.price = '';
      this.zone = '';
    },
    onRangeEdit(range){
      this.selectedRange = range;
      this.from = range.from;
      this.to = range.to;
      this.price = range.price;
      this.zone = range.zone;

      this.editRangeDialog = true;
    },

    openUpdateCompanyDialog(company) {
      this.updatedCompanyId = company._id
      this.updatedCompanyName = company.name
      this.updatedCompanyArName = company.nameAR
      this.updatedCompanyDimWeight = company.dimensionalWeight
      this.updatedMinShipTime = company.minShipTime
      this.updatedMaxShipTime = company.maxShipTime

      this.updateCompanyDialog = true
    },

    handleUpdateShippingCompany() {
      this.loading = true;

      // const form = new FormData();

      // form.append('id',this.updatedCompanyId);
      // form.append('name',this.updatedCompanyName);
      // form.append('dimensionalWeight',this.updatedCompanyDimWeight);
      // form.append('minShipTime',this.updatedMinShipTime);
      // form.append('maxShipTime',this.updatedMaxShipTime);

      axios.post('/api/admins/side_update_shipping_company', {
        id: this.updatedCompanyId,
        name: this.updatedCompanyName,
        nameAR: this.updatedCompanyArName,
        dimensionalWeight: this.updatedCompanyDimWeight,
        minShipTime: this.updatedMinShipTime,
        maxShipTime: this.updatedMaxShipTime
      },{
        headers:{
          Authorization:`Bearer ${auth.adminToken()}`
        }
      }).then((res)=>{

        this.loading = false;

        if(!res.data.error){

          this.message = 'Updated successfully';
          this.color = 'indigo';
          this.snackbar = true;
        }else{
          this.message = 'An error occured please try again later';
          this.color = 'error'
          this.snackbar = true;
        }

        axios.get('/api/admins/side_get_shipping_companies',{
          headers:{
            Authorization:`Bearer ${auth.adminToken()}`
          }
        }).then((res)=>{
          this.companies = res.data.companies;
        })
        
        this.updatedCompanyId = ''
        this.updatedCompanyName = '';
        this.updatedCompanyArName = '';
        this.updatedCompanyDimWeight = '';
        this.updatedMinShipTime = '';
        this.updatedMaxShipTime = ''
        this.updateCompanyDialog = false;

      }).catch((err)=>{
        this.message = 'An error occured please try again later';
        this.color = 'error'
        this.snackbar = true;
        this.loading = false;
      })
    },

    handleShippingCompany(){
      this.loading = true;

      const form = new FormData();

      form.append('name',this.companyName);
      form.append('nameAR',this.companyNameAR);
      form.append('dimensionalWeight',this.dimensionalWeight);
      form.append('logoImage',this.logoFile);
      form.append('minShipTime',this.minShipTime);
      form.append('maxShipTime',this.maxShipTime);

      axios.post('/api/admins/side_create_shipping_company',form,{
        headers:{
          Authorization:`Bearer ${auth.adminToken()}`
        }
      }).then((res)=>{

        this.loading = false;

        if(!res.data.error){

          this.message = 'Created successfully';
          this.color = 'indigo';
          this.snackbar = true;
        }else{
          this.message = 'An error occured please try again later';
          this.color = 'error'
          this.snackbar = true;
        }

        this.companyName = '';
        this.dimensionalWeight = '';
        this.logoFile = '';
        this.addCompanyDialog = false;

        this.companies = [...this.companies,res.data.company]
      }).catch((err)=>{
        this.message = 'An error occured please try again later';
        this.color = 'error'
        this.snackbar = true;
        this.loading = false;
      })
    },
    createRange(){
      this.loading = true;

      axios.post('/api/admins/side_create_range',{
        from:this.from,
        to:this.to,
        price:this.price,
        company:this.selectedCompany._id,
        zone:this.zone
      },{
        headers:{
          Authorization:`Bearer ${auth.adminToken()}`
        }
      }).then((res)=>{

        this.loading = false;

        if(!res.data.error){

          this.message = 'Created successfully';
          this.color = 'indigo';
          this.snackbar = true;
        }else{
          this.message = 'An error occured please try again later';
          this.color = 'error'
          this.snackbar = true;
        }

        this.from = '';
        this.to = '';
        this.price = '';
        this.addRangeDialog = false;

        this.companies = this.companies.map(item=>{
          if(item._id == this.selectedCompany._id){
            item.ranges.push(res.data.range)
            return item;
          }

          return item;
        })

        this.selectedCompany = '';
      }).catch((err)=>{
        this.message = 'An error occured please try again later';
        this.color = 'error'
        this.snackbar = true;
        this.loading = false;
      })
    },
    editRange(){
      this.loading = true;

      axios.post('/api/admins/side_edit_range',{
        from:this.from,
        to:this.to,
        price:this.price,
        rangeId:this.selectedRange._id,
        zone:this.zone
      },{
        headers:{
          Authorization:`Bearer ${auth.adminToken()}`
        }
      }).then((res)=>{

        this.loading = false;

        if(!res.data.error){

          this.message = 'edited successfully';
          this.color = 'indigo';
          this.snackbar = true;
        }else{
          this.message = 'An error occured please try again later';
          this.color = 'error'
          this.snackbar = true;
        }

        this.from = '';
        this.to = '';
        this.price = '';
        this.editRangeDialog = false;

        this.selectedRange.from = res.data.range.from;
        this.selectedRange.price = res.data.range.price;
        this.selectedRange.to = res.data.range.to;
        this.selectedRange.zone = res.data.range.zone;

      }).catch((err)=>{
        this.message = 'An error occured please try again later';
        this.color = 'error'
        this.snackbar = true;
        this.loading = false;
      })
    },
    removeCompany(id){

      axios.get('/api/admins/side_remove_company/'+id).then(res=>{

        if(!res.data.error){
          this.snackbar = true;
          this.color= 'warning';
          this.message = res.data.message;

          this.companies = this.companies.filter(item=>item._id != id);
        }else{
          this.snackbar = true;
          this.color = 'error';
          this.message = res.data.message;
        }
      }).catch(err=>{
        console.log(err);
        this.snackbar = true;
        this.color = 'error';
        this.message = 'An error happened please try again';
      })
    },
    onFilesPicked(f){
          this.logoFile = f;
      }
  }
};
</script>

<style scoped>
</style>