<template>
<v-col sm="12" md="12" lg="3">
    <v-card>
      <div class="flag-icon" @click="dialog = !dialog">
        <v-img 
        :src="backendUrl + '/' + flagImage" alt="" ></v-img>
      </div>

      <v-img
        style="cursor: pointer"
        @click="dialog = !dialog"
        height="250"
        :src="backendUrl + '/' + this.request.packages[0].images[0]"
      >
      
      </v-img>


          <v-card-text class="warning--text">
        {{ $t("awaitingAssemble") }}
      </v-card-text>
</v-card>

 <v-dialog v-model="dialog" max-width="800">
            <v-card class="pa-3">
                <v-card-title>{{$t('assembleRequest')}}</v-card-title>
                <v-card-text>
                     

                    <v-card class="pa-0 grey my-4 lighten-4" v-for="pack in request.packages" v-viewer :key="pack.id" >
                        <v-row>
                        <v-col sm="6">
                            <img height="150" width="150" class="ma-2 elevation-3" :src="backendUrl + '/' + pack.images[0]"></img>
                        </v-col>

                        <v-col sm="6">
                            <div class="mx-4 my-2" style="font-size:16px"><b>{{$t('trackNumber')}}: </b> <span>{{pack.trackNumber}}</span></div>
                            <div class="mx-4 my-2" style="font-size:16px"><b>{{$t('weight')}} : </b> <span>{{pack.weight}} kg</span></div>
                            <div class="mx-4 my-2" style="font-size:16px"><b>{{$t('dimension')}}: </b> <span> {{pack.length + 'x' + pack.width + 'x' + pack.height + ' cm'}} </span></div>
                        </v-col>
                    </v-row>

                     <v-card-text>
                    <v-row v-for="detail in pack.details" :key="detail">
                            <v-col>
                                <v-text-field
                                    v-model="detail.description"
                                    name="description"
                                    :label="$t('description')"
                                    id="description"
                                    type="text"
                                    color="indigo"
                                    readonly
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    v-model="detail.quantity"
                                    name="quantity"
                                    :label="$t('quantity')"
                                    id="quantity"
                                    type="number"
                                    min="0"
                                    color="indigo"
                                    readonly
                                    >
                                </v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    v-model="detail.price"
                                    name="price"
                                    :label="$t('price')"
                                    id="price"
                                    type="number"
                                    min="0"
                                    color="indigo"
                                    readonly
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    v-model="detail.madeIn"
                                    name="madeIn"
                                    label="Where was the item made in?"
                                    id="madeIn"
                                    type="text"
                                    color="indigo"
                                    readonly
                                    ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    v-model="detail.type"
                                    name="type"
                                    label="Type"
                                    id="type"
                                    type="text"
                                    color="indigo"
                                    readonly
                                    ></v-text-field>
                            </v-col>
                        </v-row>
                </v-card-text>

                

                </v-card>
                     
                </v-card-text>

                <v-card-text
            v-if="
              request.packages[0].awaitingAssemble &&
              new Date().getTime() - new Date(request.packages[0].assembleTime).getTime() < 900000
            "
            class=""
          >
            <p class="mt-5" style="text-align: center">
              <b>{{ $t("note") }}: </b>{{ $t("cancelRequestNote") }}
            </p>

            <v-row class="d-flex justify-center align-center">
              <v-col sm="12" lg="4">
                <v-btn
                  @click="cancelAssembleDialog = !cancelAssembleDialog"
                  :class="
                    this.$i18n.locale == 'ar'
                      ? 'white--text ma-1 mr-auto'
                      : 'white--text ma-1 ml-auto'
                  "
                  style="text-transform: none; font-size: 16px"
                  color="warning"
                  width="100%"
                >
                  <v-icon
                    :right="this.$i18n.locale == 'ar' ? true : false"
                    :left="this.$i18n.locale != 'ar' ? true : false"
                    class="ma-2 mx-1"
                    size="15"
                  >
                    fa-solid fa-undo
                  </v-icon>

                  {{ $t("cancelAssemble") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>

                <v-card-actions v-if="!loading" class="d-flex justify-end">
                        
                </v-card-actions>

                
            </v-card>
        </v-dialog>

         <v-dialog v-model="cancelAssembleDialog" persistent max-width="650">
        <v-card>
          <v-card-title>
            {{ $t("cancelAssemble") }}
          </v-card-title>

          <v-card-text style="font-size: 17px">
            {{ $t("cancelAssembleText") }}{{ $t("?") }}
          </v-card-text>

          <v-card-action v-if="!this.loading" class="d-flex justify-center pa-3">
            <v-btn @click="handleCancelAssemble" color="success mx-2">
              {{ $t("confirm") }}
            </v-btn>

            <v-btn
              @click="cancelAssembleDialog = false"
              color="grey darken-1 white--text mx-2"
            >
              {{ $t("close") }}
            </v-btn>
          </v-card-action>

          <v-card-action v-else class="d-flex justify-center pa-3">
            <v-progress-circular
              :indeterminate="true"
              size="40"
              color="indigo"
            ></v-progress-circular>
          </v-card-action>

        </v-card>
      </v-dialog>
</v-col>
</template>

<script>
import { backendUrl } from "../config/config";
import axios from "axios";
import auth from "../helpers/auth";

const newAxios = axios.create();
newAxios.defaults.headers.common = {};

export default {
  methods: {
      handleCancelAssemble() {
      this.loading = true;

      newAxios
        .post(
          "/api/packages/group/user-cancel",
          {
            requestId: this.request._id,
          },
          {
            headers: {
              Authorization: `bearer ${auth.token()}`,
            },
          }
        )
        .then((res) => {
          this.loading = false;

          if (res.data.error) {
            this.snackbar = true;
            this.color = "error";
            this.message = this.$i18n.t("errorMessage");
          } else {
            this.snackbar = true;
            this.color = "success";
            this.message = this.$i18n.t("cancelAssembleRequestSuccess");

            this.dialog = false;
            this.addressDialog = false;

            this.$emit("updateData");
          }

          this.cancelAssembleDialog = false;
        })
        .catch((err) => {
          this.loading = false;

          this.snackbar = true;
          this.color = "error";
          this.message = this.$i18n.t("errorMessage");

          console.log(err);
        });
    }
  },
  data () {
    return {
      backendUrl:backendUrl,
      dialog:false,
      cancelAssembleDialog:false,
      loading:false
    }
  },
  props: ['request', 'flagImage'],
  name: "AssemblePackage",
};
</script>

<style scoped>
.flag-icon{
  z-index: 1;
  position: absolute;
  top: 2%;
  right: 2%;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
</style>