import Vue from 'vue'
import VueSocketIO from 'vue-socket.io';
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import VueMeta from 'vue-meta'
import { VueReCaptcha } from 'vue-recaptcha-v3'

import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'

import {backendUrl} from './config/config';
import './registerServiceWorker'


Vue.use(new VueSocketIO({
  debug: true,
  connection: backendUrl
}))

Vue.use(VueReCaptcha, {
  siteKey: '6Lde77IZAAAAAPV89vvF8kdIm05pLhb8PZoT0NAf',
  loaderOptions: {
    useRecaptchaNet: true,

  }
})

Viewer.setDefaults({
  toolbar:false,
  title:false
})

Vue.use(Viewer)

Vue.use(VueMeta)




Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
