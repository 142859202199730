<template>
  <div>
    <Navbar />

    <v-layout style="margin-top:60px;margin-bottom:60px;" align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12" style="margin: 0 20px 0 20px">
          <v-toolbar dark color="indigo">
            <v-toolbar-title>{{ $t('signup') }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form ref="form" @submit.prevent="handleSubmit" v-model="valid">
              <v-row>
                <v-col sm="6">
                  <v-text-field
                    v-model="firstName"
                    name="firstName"
                    :label="$t('firstName')"
                    id="firstName"
                    type="text"
                    color="indigo"
                    :rules="[
                      (v) => !!v || $t('firstNameRequired'),
                      (v) => (!!v && checkName(v)) || $t('invalidFirstName'),
                    ]"
                    @keyup="checkArabic"
                    required
                  ></v-text-field>
                </v-col>

                <v-col>
                  <v-text-field
                    v-model="lastName"
                    name="lastName"
                    :label="$t('lastName')"
                    id="lastName"
                    type="text"
                    color="indigo"
                    :rules="[
                      (v) => !!v || $t('lastNameRequired'),
                      (v) => (!!v && checkName(v)) || $t('invalidLastName'),
                    ]"
                    @keyup="checkArabic"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row style="direction:ltr;margin-left:0px">
                <v-col cols="4" class="mx-0 px-0">
                  <v-menu
                    offset-y
                    transition="scale-transition"
                    nudge-top="18"
                    max-height="240"
                    :close-on-content-click="true"
                    v-model="codeMenu"
                  >
                    <template v-slot:activator="{ attrs, on }">
                      <v-text-field
                        name="codeCount"
                        :label="$t('countryCode')"
                        v-on="on"
                        v-model="countryCode"
                        autocomplete="off"
                        :rules="[
                          (v) => !!v || $t('codeRequired'),
                          (v) => checkCountryCode(v) || 'Invalid code',
                        ]"
                        @keyup="filterCodes"
                      ></v-text-field>
                    </template>

                    <v-list-item
                      @click.stop="setCode(item)"
                      v-for="item in countryCodes"
                      :key="item.id"
                      class="white pa-2"
                    >
                      <v-list-item-content style="direction:ltr">{{
                        item
                      }}</v-list-item-content>
                    </v-list-item>
                  </v-menu>
                </v-col>

                <v-col cols="8">
                  <v-text-field
                    v-model="phone"
                    name="phone"
                    :label="$t('phone')"
                    id="phone"
                    type="text"
                    color="indigo"
                    style="direction:ltr"
                    :rules="[
                      (v) => !!v || $t('phoneRequired'),
                      (v) => phoneRe.test(v) || $t('invalidPhoneNumber')
                    ]"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-text-field
                v-model="email"
                name="email"
                :label="$t('email')"
                id="email"
                type="email"
                color="indigo"
                @keyup="checkArabic"
                :rules="[
                  (v) => !!v || $t('emailRequired'),
                  (v) =>
                    this.emailRe.test(String(v).toLowerCase()) ||
                    $t('validEmail'),
                ]"
                required
              ></v-text-field>

              <v-text-field
              name="confirmEmail"
              :label="$t('confirmEmail')"
              id="confirmEmail"
              type="email"
              color="indigo"
              :rules="[
              v => !!v || $i18n.t('emailConfirmationRequired'),
              v => v == email || $i18n.t('matchEmail')]"
              required
            ></v-text-field>

              <v-text-field
                v-model="password"
                name="password"
                :label="$t('password')"
                id="password"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'fa-eye' : 'fa-eye-slash'"
                @click:append="()=>{showPassword = !showPassword}"
                ref="password"
                color="indigo"
                :rules="[
                  (v) => !!v || $t('passwordRequired'),
                  (v) => v.length >= 8 || $t('minPassword'),
                ]"
                required
              ></v-text-field>

              <v-spacer></v-spacer>

              <v-spacer></v-spacer>

              <div v-if="loading" class="d-flex justify-center">
                <v-progress-circular
                  :indeterminate="true"
                  size="40"
                  color="indigo"
                ></v-progress-circular>
              </div>

              <div v-else class="d-flex justify-center">
                <v-btn class="ma-2 mx-auto" type="submit" dark color="indigo">
                  {{ $t('create') }}
                </v-btn>
              </div>
            </v-form>
          </v-card-text>

          <v-card-actions> </v-card-actions>
        </v-card>
      </v-flex>
      <v-snackbar left :color="color" v-model="snackbar">
        {{ message }}
      </v-snackbar>
    </v-layout>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from '../components/Navbar';
import auth from '../helpers/auth';
import axios from 'axios';

import { codes } from '../config/config';

let countryCodes = codes.map((item) => '+' + item);

const isArabic = /[\u0621-\u064A]+/g;

export default {
  name: 'Signup',
  metaInfo(){
    return {
    title: this.$t("signupPageTitle"),
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      {
        name: 'description',
        content:
          'أحصل على عنوان فرنسي مجاناً، وتسوق بمفردك من المتاجر الأوروبية والفرنسية. نشحنها إلى باب بيتك بأي مكان في العالم.',
      },
      {
        name: 'keywords',
        content:
          'عنوان فرنسي للشحن, عنوان اوروبي للشحن, تجميع الشحنات فرنسا, عنوان فرنسي, porsay, بورساي, الحصول على عنوان فرنسي, france forwarding service, french address for online shopping',
      },
    ],}
  },

  components: { Navbar },

  data: () => ({
    valid: false,

    message: '',

    countryCode: '',

    loading: false,

    email: '',
    //eslint-disable-next-line
    emailRe: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    //eslint-disable-next-line
    phoneRe: /^\d+$/i,
    emailRules: [
      (v) => !!v || 'Email is required',
      (v) => v || 'Enter a valid Email',
    ],

    phone: '',
    firstName: '',

    lastName: '',

    password: '',
    showPassword: false,
    snackbar: false,
    color: '',
    countryCodes,
    codeMenu: false,
  }),

  mounted() {
    if (this.$i18n.locale === 'ar') {
      this.$vuetify.rtl = true;
    }

    if (this.$route.query.email) {
      this.email = this.$route.query.email;
    }

    axios.get('https://ipapi.co/json/').then((res) => {
      this.countryCode = res.data.country_calling_code;
    });
  },
  methods: {
    confirmRule(val, text) {
      if (val != this.password) {
        return text;
      } else {
        return true;
      }
    },

    checkName(text) {
      const restrectedChar = ['@', '&', '<', '>', '=', '/', '!', '?'];
      let passed = true;
      restrectedChar.forEach((el) => {
        if (text.includes(el)) {
          passed = false;
        }
      });
      if (passed) {
        const str = text[0].toLowerCase();
        if (!str.match(/[a-z]/i)) passed = false;
      }

      return passed;
    },

    checkCountryCode(code) {
      return countryCodes.includes(code);
    },
    handleSubmit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      if(!this.checkCountryCode(this.countryCode)){
        this.message = this.$t('invalidCountryCode');
        this.snackbar = true;
        this.color = 'error';
        return;
      }

      this.loading = true;
      this.message = '';

      this.$recaptcha('signup').then((token) => {
        //console.log(token) // Will print the token
        auth.signup(
          {
            firstName: this.capitalizeFirstLetter(this.firstName),
            lastName: this.capitalizeFirstLetter(this.lastName),
            email: this.email,
            phone: this.countryCode + this.phone,
            password: this.password,
            captcha: token,
          },
          (status, message) => {
            if (status) {
              this.$emit('authChange');
              this.$recaptcha('login').then((captcha) => {
                auth.login(this.email, this.password, captcha, (status) => {
                  this.$router.push('/' + this.$i18n.locale + '/confirm-email');
                });
              });
            } else {
              this.message = message;
              this.snackbar = true;
              this.color = 'error';
              this.loading = false
            }
          }
        );
      });
    },
    checkArabic(e) {
      if (isArabic.test(e.target.value)) {
        this[e.target.name] = '';
      }
    },

    checkNumber(e) {
      if (!this.phoneRe.test(e.target.value)) {
        this[e.target.name] = '';
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    filterCodes() {
      let arr = [];

      countryCodes.forEach((item) => {
        if (item.indexOf(this.countryCode) >= 0) {
          arr.push(item);
        }
      });

      this.countryCodes = arr;
    },
    setCode(item) {
      this.countryCode = item;
      this.codeMenu = false;
    },
  },
};
</script>

<style scoped>
.phone-input {
  border: none !important;
  outline: none !important;
  border-bottom: grey solid 1px !important;
}

.vue-tel-input:focus-within {
  box-shadow: none !important;
  border-color: green !important;
}

</style>
