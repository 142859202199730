<template>
<div>
  <DashboardNavbar />
  <v-layout style="margin-top:10%;" align-center justify-center>
    <v-flex xs12 sm8 md4>
      <v-card class="elevation-12" style="margin: 0 20px 0 20px">
        <v-toolbar dark color="indigo">
          <v-toolbar-title>{{$t('confirmEmail')}}</v-toolbar-title>
        </v-toolbar>
        <v-dialog v-model="dialog" max-width="450">
            <v-card class="pa-1">
        <v-card-text class="pa-3 blue--text" style="font-size:18px">
          {{ $t("editEmailText") }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text>
          <v-form ref="form" @submit.prevent="handleUpdate" v-model="valid">
            <v-text-field
              v-model="email"
              name="email"
              :label="$t('email')"
              :rules="[v => !!v || $t('emailRequired'),
              v => emailRe.test(v) || $t('validEmail')]"
              id="email"
              type="email"
              color="indigo"
              required
            ></v-text-field>

            <v-text-field
              v-model="email2"
              name="email2"
              :label="$t('confirmEmail')"
              :rules="[v => !!v || $t('emailConfirmationRequired'),
              v => email2 == email || $t('matchEmail')]"
              id="email2"
              type="email"
              color="indigo"
              required
            ></v-text-field>

            <v-spacer></v-spacer>

            <v-spacer></v-spacer>

            <div v-if="loading" class="d-flex justify-center">
              <v-progress-circular
                :indeterminate="true"
                size="40"
                color="indigo"
              ></v-progress-circular>
            </div>

            <div v-else class="d-flex justify-center">
              <v-btn class="ma-2 mx-auto" type="submit" dark color="indigo">
                {{ $t("confirm") }}
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
        </v-dialog>
        <v-card-text>
          <p class="card-text">{{$t('emailVerificationText')}}</p>
          <b class="card-text"> {{user.email}} </b>
          <p class="card-text">{{$t('enterCodeText')}}</p>
           <v-form>
              <div style="direction: ltr;" class="digit-inputs">
                <v-text-field
                outlined
                :disabled="loading"
                @input="focusNextInput(1)"
                v-model="digits[0]"
                type="text"
                maxlength="1"
                class="input-text"
                ref="input1"
                ></v-text-field>
                <v-text-field
                outlined
                :disabled="loading"
                @input="focusNextInput(2)"
                v-model="digits[1]"
                type="text"
                maxlength="1"
                class="input-text"
                ref="input2"
                ></v-text-field>
                <v-text-field
                outlined
                :disabled="loading"
                @input="focusNextInput(3)"
                v-model="digits[2]"
                type="text"
                maxlength="1"
                class="input-text"
                ref="input3"
                ></v-text-field>
                <v-text-field
                outlined
                :disabled="loading"
                @input="focusNextInput(4)"
                v-model="digits[3]"
                type="text"
                maxlength="1"
                class="input-text"
                ref="input4"
                ></v-text-field>
                <v-text-field
                outlined
                :disabled="loading"
                @input="focusNextInput(5)"
                v-model="digits[4]"
                type="text"
                maxlength="1"
                class="input-text"
                ref="input5"
                ></v-text-field>
                <v-text-field
                outlined
                :disabled="loading"
                @input="focusNextInput(6)"
                v-model="digits[5]"
                type="text"
                maxlength="1"
                class="input-text"
                ref="input6"
                ></v-text-field>
              </div>

              <div v-if="loading" class="d-flex justify-center">
              <v-progress-circular
                :indeterminate="true"
                size="40"
                color="indigo"
              ></v-progress-circular>
            </div>

              <p v-if="!loading" class="footer">{{$t('noEmailSent')}}
                <button type="button" :disabled="timer != 0" @click="getConfirmationEmail">{{$t('resend')}} 
                  <button type="button" disabled v-if="timer != 0">({{timer}}{{$t('s')}})</button>
                </button>
              </p>
              <p v-if="!loading" class="footer">{{$t('incorrectEmail')}}
                <button type="button" :disabled="timer != 0" @click="dialog = !dialog">{{$t('editEmailText')}}</button>
              </p>
            </v-form>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-snackbar :color="color" v-model="snackbar">
              {{ message }}
    </v-snackbar>

  </v-layout>
</div>
</template>

<script>
import DashboardNavbar from "../components/DashboardNavbar";
import auth from "../helpers/auth";
import axios from 'axios'

const newAxios = axios.create();
newAxios.defaults.headers.common = {};
export default {
    data(){
        return{
            user: auth.user(),
            digits: ['','','','','',''],
            email: '',
            email2: '',
            emailRe: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            dialog: false,
            loading: false,
            firstEmail: false,
            timer: 0,
            message: '',
            snackbar: false,
            color: ''
        }
    },
    methods:{
      focusNextInput(index) {
        if (this.digits[index - 1].length === 1) {
          this.$refs[`input${index+1}`].focus();
        }
      },
      startTimer(){
          this.emailTimer = setInterval(() => {
              if(this.timer > 0)
                  this.timer--;
              else
                  clearInterval(this.emailTimer)
          }, 1000)
      },
      checkOTP(){
        this.loading = true
        newAxios.post('/api/users/confirm-otp', {
          otp: this.otp
        },
        {
          headers: {
              Authorization: `bearer ${auth.token()}`,
          },
        }).then(res => {
          console.log(res.data)
          if(!res.data.error){
            auth.updateToken((confirmed) => {
              if((confirmed))
                this.$router.push('/' + this.$i18n.locale + '/dashboard');
            })
          } else if(res.data.message == "Code expired"){
            this.color = 'error'
            this.message = this.$t('expiredCode')
            this.snackbar = true
            for(var i=0; i <=5; i++)
              this.digits[i] = ''
            this.loading = false;
          } else if(res.data.message == "Invalid code"){
            this.color = 'error'
            this.message = this.$t('invalidCode')
            this.snackbar = true
            for(i=0; i <=5; i++)
              this.digits[i] = ''
            this.loading = false;
          }
        }).catch(() => {
          this.color = 'error'
          this.message = this.$t('errorMessage')
          this.snackbar = true
          this.loading = false;
        })
      },
      getConfirmationEmail(){
        if(this.timer == 0){
          this.loading = true
          newAxios
          .get("/api/users/generate-confirmation", {
            headers: {
                Authorization: `bearer ${auth.token()}`,
            },
          })
          .then((res) => {
          if (!res.data.error) {
              this.message = this.$t('emailSent')
              this.color = 'success'
              this.snackbar = true
              this.firstEmail = true
              this.loading = false
              this.timer = 60
              this.startTimer()
          } else {
              if (res.data.message == "User Confirmed") {
                  this.message = this.$t("confirmedMessage")
                  this.color = 'success'
                  this.snackbar = true
              }
              if (res.data.message == "Limit Exceeded") {
                  this.message = this.$t("limitMessage")
                  this.color = 'error'
                  this.snackbar = true
              }
          }
          this.loading = false;
          })
          .catch((err) => {
          this.loading = false;
          });
        } else {
          this.color = 'error'
          this.message = this.$t('requestAgainIn') + this.timer + this.$t('s')
          this.snackbar = true
          this.loading = false
        }
      },
      handleUpdate(){
          if (!this.$refs.form.validate()) {
              return;
          }
          this.loading = true
          newAxios
              .put(
              '/api/users/updateemail',
              {
                  email: this.email
              },
              {
                  headers: {
                  Authorization: `bearer ${auth.token()}`,
                  },
              }
              ).then(res => {
                  if(!res.data.error){
                      localStorage.setItem('token', res.data.token);
                      this.dialog = false
                      this.user = auth.user()
                      this.message = this.$t('emailUpdated')
                      this.color = 'success'
                      this.snackbar = true
                      this.getConfirmationEmail()
                  } else {
                      this.message = this.$t(res.data.message)
                      this.color = 'error'
                      this.snackbar = true
                      this.loading = false
                  }
                  })
      }
    },
    computed: {
      otp(){
        let code = ''
        for(var i=0; i <=5; i++)
          code += this.digits[i]
        return code
      }
    },
    watch: {
      otp(){
        if(!this.loading && this.otp.length === 6)
          this.checkOTP()
      }
    },
    components: {
        DashboardNavbar
    },
    mounted(){
    if(this.$i18n.locale === 'ar'){
        this.$vuetify.rtl = true;
    }
    
    auth.updateToken((confirmed) => {
      if(confirmed)
        window.location.href = `/${this.$i18n.locale}/dashboard`
    })
  },
}
</script>

<style scoped>
.digit-inputs {
  display: flex;
  gap: 10px;
}
.card-text{
  font-size: 1.4rem;
  margin: 4% 0;
}
.input-text{
  font-size: 1.5rem;
}
.footer{
  margin: 2% 0 0;
  font-size: 1rem;
  font-style: italic;
}
.footer button{
  color: blue;
  font-style: normal;
}
.footer button:disabled{
  color: gray;
  font-style: normal;
}
</style>